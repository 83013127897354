import * as React from "react";
import { Container, Table, Row, Col, Button } from "react-bootstrap";

export default function PrintTable({ ...props }) {
  return (
    <>
    <div className="py-5 text-center text-lg "> 행사 신청서 프린트 예시 </div>
      <Container >
       
        <Row className="py-3 d-flex justify-content-center text-center border-slate-500  border-[1px] bg-white">
          배송신청서
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px] border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="1">
            신청번호
          </Col>
          <Col></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            신청일자
          </Col>
          <Col></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            회사명
          </Col>
          <Col></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            부서명
          </Col>
          <Col></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            참가제품
          </Col>
          <Col lg="4"></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            신청자
          </Col>
          <Col></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            전화번호
          </Col>
          <Col></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            행사장 주소
          </Col>
          <Col lg="4"></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            행사시간
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px] ">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            행사 일자
          </Col>
          <Col lg="4"></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            철거시간
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            행사명칭
          </Col>
          <Col lg="9"></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            행사 설치 시간
          </Col>
          <Col lg="4"></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            신청부스
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            방명록 필요 유무
          </Col>
          <Col lg="4"></Col>
          <Col className=" border-start border-end bg-[#63a1d6] py-2" lg="1">
            부스타입
          </Col>
          <Col lg="4"></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            물품픽업
          </Col>
          <Col lg="9"></Col>
        </Row>
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-2 bg-[#63a1d6]" lg="2">
            기타 요청 사항
          </Col>
          <Col lg="9"></Col>
        </Row>
        {/*  DTT 박스 크기 */}
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-4 bg-[#63a1d6] " lg="2">
            DTT 박스 크기
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[40px] h-full items-center justify-center flex border-r-[1px]">
              대
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[40px] h-full items-center justify-center flex border-r-[1px]">
              중
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[40px] h-full items-center justify-center flex border-r-[1px]">
              소
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between">
            {" "}
            <div className="w-full h-[40%] items-center justify-center flex border-r-[1px] py-1 border-b-[1px]">
              비고
            </div>
          </Col>
        </Row>
        {/*  택배사 박스 크기 */}
        <Row className="text-center border-slate-500  border-b-[1px]  border-x-[1px]">
          <Col className=" border-end py-4 bg-[#63a1d6] " lg="2">
            택배사 박스 크기
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[40px] h-full items-center justify-center flex border-r-[1px]">
              대
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[40px] h-full items-center justify-center flex border-r-[1px]">
              중
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[40px] h-full items-center justify-center flex border-r-[1px]">
              소
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between" lg="2">
            {" "}
            <div className="w-[60px] h-full items-center justify-center flex border-r-[1px]">
              극소
            </div>
            <span className="w-[80%] ml-3 h-[80%] block border-b-[1px]"></span>
            <div className=" w-[40px] h-full items-center justify-center flex border-r-[1px] pr-5">
              개
            </div>
          </Col>
          <Col className="px-0 flex justify-between">
            {" "}
            <div className="w-full h-[40%] items-center justify-center flex border-r-[1px] py-1 border-b-[1px]">
              비고
            </div>
          </Col>
        </Row>
        <Row>
          <table className="" >
            <thead className="text-center bg-[#63a1d6] border-slate-500 ">
              <tr  className="border-b-[1px] border-slate-500">
                <th className=" py-3 border-x-[1px] border-slate-500" >순번</th>
                <th className="w-[400px]">제품명</th>
                <th className=" py-3 border-x-[1px] border-slate-500">분류</th>
                <th>행사물품</th>
                <th className=" py-3 border-x-[1px] border-slate-500 w-[100px]">이미지</th>
                <th>주문수량</th>
                <th className=" py-3 border-x-[1px] border-slate-500">단위</th>
                <th>적재위치</th>
                <th className=" py-3 border-x-[1px] border-slate-500">비고</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr className="border-b-[1px] border-slate-500">
                <td className=" border-x-[1px] border-slate-500">1</td>
                <td>액솔</td>
                <td className="  border-x-[1px] border-slate-500">브로셔</td>
                <td>액솔 점안액(0.2%)</td>
                <td className="  border-x-[1px] border-slate-500"> <img src="http://via.placeholder.com/100x60" alt="" /> </td>
                <td>30</td>
                <td className="  border-x-[1px] border-slate-500">장</td>
                <td className="  border-x-[1px] border-slate-500">A3</td>
                <td className="  border-x-[1px] border-slate-500"></td>
              </tr>
              <tr className="border-b-[1px] border-slate-500">
                <td className=" border-x-[1px] border-slate-500">1</td>
                <td>액솔</td>
                <td className="  border-x-[1px] border-slate-500">브로셔</td>
                <td>액솔 점안액(0.2%)</td>
                <td className="  border-x-[1px] border-slate-500"> <img src="http://via.placeholder.com/100x60" alt="" /> </td>
                <td>30</td>
                <td className="  border-x-[1px] border-slate-500">장</td>
                <td className="  border-x-[1px] border-slate-500">A3</td>
                <td className="  border-x-[1px] border-slate-500"></td>
              </tr>
              <tr className="border-b-[1px] border-slate-500">
                <td className=" border-x-[1px] border-slate-500">1</td>
                <td>액솔</td>
                <td className="  border-x-[1px] border-slate-500">브로셔</td>
                <td>액솔 점안액(0.2%)</td>
                <td className="  border-x-[1px] border-slate-500"> <img src="http://via.placeholder.com/100x60" alt="" /> </td>
                <td>30</td>
                <td className="  border-x-[1px] border-slate-500">장</td>
                <td className="  border-x-[1px] border-slate-500">A3</td>
                <td className="  border-x-[1px] border-slate-500"></td>
              </tr>
              <tr className="border-b-[1px] border-slate-500">
                <td className=" border-x-[1px] border-slate-500">1</td>
                <td>액솔</td>
                <td className="  border-x-[1px] border-slate-500">브로셔</td>
                <td>액솔 점안액(0.2%)</td>
                <td className="  border-x-[1px] border-slate-500"> <img src="http://via.placeholder.com/100x60" alt="" /> </td>
                <td>30</td>
                <td className="  border-x-[1px] border-slate-500">장</td>
                <td className="  border-x-[1px] border-slate-500">A3</td>
                <td className="  border-x-[1px] border-slate-500"></td>
              </tr>
             
            </tbody>
          </table>
        </Row>
        
      </Container>

      <div style={{ padding: "200px 0" }}></div>
    </>
  );
}
