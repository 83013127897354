import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
    // 오늘의날짜 
const getStingDate = (date) =>{
  return date.toISOString().slice(0,10)
}
const HeardSearch = ({ searchForm }) => {
  // 기간부분 날짜
  const[date,setDate] = useState(getStingDate(new Date()))
  const[sectionOneData,setSectionOneData] = useState()
  const[sectionTwoData,setSectionTwoData] = useState()
  const[sectionThreeData,setSectionThreeData] = useState()
  const[searchData,setSearchData] = useState("")

    const changeDateHandle = (e) => {
      setDate(e.target.value)
    }

  return (
    <>
      <Row className="fs-1 fw-bold p-2 border-bottom mb-3">
        {searchForm.title}
      </Row>
      
      {searchForm.period?.exist ? (
        <Row className="py-3 align-items-center  ">
          <Col xs={2} className="text-center fs-6">
            {searchForm.period.name}
          </Col>

          <Col xs={2}>
            <InputGroup>
              <Form.Control
                type="date"
                // placeholder="yyyy-mm-dd"
                aria-label="date"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </Col>
        {searchForm?.period?.exposure !== 1 && <>{"~"}
          <Col xs={2}>
            <InputGroup>
              <Form.Control
                type="date"
                value={date}
                onChange={changeDateHandle}
                // placeholder="yyyy-mm-dd"
                aria-label="date"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
          </Col></> }
        </Row>
      ) : null}

      {searchForm?.eventPeriod && searchForm.eventPeriod.exist ? (
        <Row className="mb-3 align-items-center  ">
          <Col xs={2} className="text-center fs-6">
            {searchForm.eventPeriod.name}
          </Col>

          <Col xs={2}>
            <InputGroup>
              <Form.Control
                type="date"
                // placeholder="yyyy-mm-dd"
                aria-label="date"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </Col>
          {"~"}
          <Col xs={2}>
            <InputGroup>
              <Form.Control
                type="date"
                value={date}
                onChange={changeDateHandle}
                // placeholder="yyyy-mm-dd"
                aria-label="date"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
          </Col>
        </Row>
      ) : null}

      {/* 구분 */}
      {searchForm.section.exist ? (
        <Row className=" align-items-center  ">
          <Col xs={2} className="text-center fs-6">
            구분{" "}
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example">
              <option>거래처선택</option>
              <option value="1">거래처</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example">
              <option>부서선택</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example">
              <option>제품선택</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>
          {searchForm.section?.category  && <Col xs={2}>
            <Form.Select aria-label="Default select example">
              <option>종류</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Col>}
        </Row>
      ) : null}

      {/* 게시위치 */}
      {searchForm?.position?.exist ? (
        <Row className="pt-3 align-items-center ">
          <Col xs={2} className="text-center fs-6">
            {searchForm.position?.titleName}
          </Col>
          <Col xs={6}>
            <Form>
              {[searchForm.state.type].map((type) => (
                <div key={`inline-${type}`} className="align-items-center">
                  {searchForm.position?.name.map((formName, idx) => (
                    <Form.Check
                      inline
                      key={idx}
                      label={formName}
                      name="group1"
                      type={type}
                      id={idx}
                    />
                  ))}
                </div>
              ))}
            </Form>
          </Col>
        </Row>
      ) : null}

      {/* 상태 */}
      {searchForm.state.exist ? (
        <Row className="pt-3 align-items-center ">
          <Col xs={2} className="text-center fs-6">
            {searchForm.state.titleName}
          </Col>
          <Col xs={6}>
            <Form>
              {[searchForm.state.type].map((type) => (
                <div key={`inline-${type}`} className="align-items-center">
                  {searchForm.state.name.map((formName, idx) => (
                    <Form.Check
                      inline
                      key={idx}
                      label={formName}
                      name="group1"
                      type={type}
                      id={idx}
                    />
                  ))}
                </div>
              ))}
            </Form>
          </Col>
        </Row>
      ) : null}
      {searchForm.search.exist ? (
        <Row className="align-items-center mb-5 py-3">
          <Col xs={2} className="text-center fs-6">
            {searchForm.search?.title ? searchForm.search?.title :'검색'}
          </Col>
        
          {searchForm.search.type !== "drop" ? (
            <>
              <Col xs={6}>
              <InputGroup>
                <Form.Control
                  placeholder="검색어를 입력하세요"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
            <Col>
              {" "}
              <Button variant="primary" className="mx-3 px-4">
                검색
              </Button>{" "}
            </Col>
            </>
          ) : (
            <Col xs={6}>
              <InputGroup className="">
                <DropdownButton
                  variant="outline-secondary"
                  title="제목"
                  id="input-group-dropdown-1"
                  onChange={(e)=>console.log(e.target)}
                >
                  <Dropdown.Item href="#">제목</Dropdown.Item>
                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item href="#">내용</Dropdown.Item>
                </DropdownButton>
                <Form.Control aria-label="Text input with dropdown button" />
              </InputGroup>
            </Col>
          )}
        </Row>
      ) : null}
    </>
  );
};

HeardSearch.defaultProps = {
  searchForm: {
    title: "good",
    period: {
      exist: true,
    },
    section: { exist: true },
    state: {
      exist: true,
      type: "checkbox",
      name: ["접수", "신규", "발송", "취소"],
    },
    search: { exist: true },
  },
};
export default HeardSearch;
