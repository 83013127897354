import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useRef, useState } from "react";
import { tokens } from "../../theme";
import { Typography, useTheme } from "@mui/material";
import { getGoodsFunc, goodsUpdateFunc } from "../../api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ManagementEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const imgInputRef = useRef();
  const imgInputRef2 = useRef();
  const imgInputRef3 = useRef();
  /* preview image*/
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc2, setImgSrc2] = useState(null);
  const [imgSrc3, setImgSrc3] = useState(null);
  const [forms, setForms] = useState([]);

  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob, setImage) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImage(reader.result);
        resolve();
      };
    });
  };

  const handleDeleteImg = (e) => {
    if (e.target.name === "img1") {
      setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.name === "img2") {
      setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.name === "img3") {
      setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };

  const handleChangeImage = (e) => {

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      handleDeleteImg(e)
      return;
    }

    if (e.target.name === "img1") {
      encodeFileToBase64(e.target.files[0], setImgSrc);
    } else if (e.target.name === "img2") {
      encodeFileToBase64(e.target.files[0], setImgSrc2);
    } else if (e.target.name === "img3") {
      encodeFileToBase64(e.target.files[0], setImgSrc3);
    }
  };

  const handleDeleteImage = (e) => {
    if (e.target.id === "del_img1") {
      setImgSrc(null);
      setForms({
        ...forms,
        img1: null
      })
      imgInputRef.current.value = "";
    } else if (e.target.id === "del_img2") {
      setImgSrc2(null);
      setForms({
        ...forms,
        img2: null
      })
      imgInputRef2.current.value = "";
    } else if (e.target.id === "del_img3") {
      setImgSrc3(null);
      setForms({
        ...forms,
        img3: null
      })
      imgInputRef3.current.value = "";
    }
  };

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    formData.append("img1", forms.img1 ?? null);
    formData.append("img2", forms.img2 ?? null);
    formData.append("img3", forms.img3 ?? null);

    const config = {
      "Content-Type": "multipart/form-data",
    };
    console.log(Object.fromEntries(formData.entries()));
    const res = await goodsUpdateFunc(id, formData, config)
    if (res.status === 201 || res.status === 200) {
      navigate("/disManagement", { replace: true })
      toast.success("Successfully Register!");
      setForms({
        ...forms,
        goodsName: "",
        category: "",
        memo: "",
        loadingLocation: "",
      });
      setImgSrc(null);
      setImgSrc2(null);
      setImgSrc3(null);
      imgInputRef.current.value = "";
      imgInputRef2.current.value = "";
      imgInputRef3.current.value = "";
    } else {
      toast.error("거래처 등록에 실패했습니다.!");
    }
  };

  const getGood = async (id) => {
    const res = await getGoodsFunc(id)
    if (res.status === 200 || res.status === 201) {
      setForms({
        ...forms,
        goodsName: res.data.goodsName,
        category: res.data.category,
        memo: res.data.memo,
        loadingLocation: res.data.loadingLocation,
        img1: res.data.goodsImage[0],
        img2: res.data.goodsImage[1],
        img3: res.data.goodsImage[2],
      });
      setImgSrc(res.data.goodsImage[0]);
      setImgSrc2(res.data.goodsImage[1]);
      setImgSrc3(res.data.goodsImage[2]);
    } else {
      toast.error("정보를 얻을 수 없습니다!");
    }
  }
  useEffect(() => {
    getGood(id)
  }, [id]);
  console.log('forms', forms);

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>물품 등록</Row>
        <Form onSubmit={handleSubmit}>
          <Typography
            variant='h4'
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.물품정보
          </Typography>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              물품명
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                name='goodsName'
                type='text'
                size='sm'
                value={forms.goodsName}

                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              종류
            </Form.Label>
            <Col sm='9'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} onChange={onChange}>
                  <Form.Check
                    inline
                    label='판촉물'
                    name='category'
                    type={type}
                    value='판촉물'
                    checked={forms.category === '판촉물'}
                  />
                  <Form.Check
                    inline
                    label='브로슈어'
                    name='category'
                    type={type}
                    value='브로슈어'
                    checked={forms.category === '브로슈어'}
                  />
                  <Form.Check
                    inline
                    label='문헌'
                    name='category'
                    type={type}
                    value='문헌'
                    checked={forms.category === '문헌'}
                  />
                  <Form.Check
                    inline
                    // disabled
                    label='식품류'
                    name='category'
                    type={type}
                    value='식품류'
                    checked={forms.category === '식품류'}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              비고
            </Form.Label>
            <Col sm='6'>
              <Form.Control
                type='text'
                size='sm'
                name='memo'
                value={forms.memo}
                onChange={onChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              적재위치
            </Form.Label>
            <Col sm='3'>
              <Form.Control type='text' size='sm'
                name='loadingLocation'
                value={forms.loadingLocation}
                onChange={onChange}
              />
            </Col>
          </Form.Group>

          <Row>
            <Col sm='2'></Col>
            <Col sm='6'>
              <small className='mt-2 px-2 d-block text-danger'>
                * 최적 사이즈 (800픽셀 * 600픽셀)
              </small>
            </Col>
          </Row>
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          <br />

          {imgSrc && (
            <Form.Group as={Row} className='mb-3 px-3'>
              <Form.Label column sm='2'>
                {""}
              </Form.Label>
              <Col sm='10'>
                <img
                  src={imgSrc}
                  alt='img preview'
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              물품이미지 1
            </Form.Label>

            <Col sm='4' className='d-flex gap-2 align-items-center'>
              <Form.Group controlId='formFile' sm='3'>
                <Form.Control
                  ref={imgInputRef}
                  name='img1'
                  type='file'
                  size='sm'
                  onChange={handleChangeImage}
                  accept='image/*'
                  // value={forms.img1}
                />
              </Form.Group>
            </Col>
            <Col>
              {imgSrc && (
                <span
                  onClick={handleDeleteImage}
                  id='del_img1'
                  className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                >
                  삭제
                </span>
              )}
            </Col>
          </Form.Group>

          {imgSrc2 && (
            <Form.Group as={Row} className='mb-3 px-3'>
              <Form.Label column sm='2'>
                {""}
              </Form.Label>
              <Col sm='10'>
                <img
                  src={imgSrc2}
                  alt='img preview'
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              물품이미지 2
            </Form.Label>
            <Col sm='4' className='d-flex gap-2 align-items-center'>
              <Form.Group controlId='formFile' sm='3'>
                <Form.Control
                  ref={imgInputRef2}
                  type='file'
                  size='sm'
                  name='img2'
                  disabled={!imgSrc}
                  onChange={handleChangeImage}
                  accept='image/*'
                  // value={forms.img2}
                />
              </Form.Group>
            </Col>
            <Col>
              {imgSrc2 && (
                <span
                  onClick={handleDeleteImage}
                  id='del_img2'
                  className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                >
                  삭제
                </span>
              )}
            </Col>
          </Form.Group>

          {imgSrc3 && (
            <Form.Group as={Row} className='mb-3 px-3'>
              <Form.Label column sm='2'>
                {""}
              </Form.Label>
              <Col sm='10'>
                <img
                  src={imgSrc3}
                  alt='img preview'
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              물품이미지 3
            </Form.Label>
            <Col sm='4' className='d-flex gap-2 align-items-center'>
              <Form.Group controlId='formFile' sm='3'>
                <Form.Control
                  ref={imgInputRef3}
                  type='file'
                  size='sm'
                  name='img3'
                  disabled={!imgSrc2}
                  onChange={handleChangeImage}
                  accept='image/*'
                  // value={forms.img3}
                />
              </Form.Group>
            </Col>
            <Col>
              {imgSrc3 && (
                <span
                  onClick={handleDeleteImage}
                  id='del_img3'
                  className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                >
                  삭제
                </span>
              )}
            </Col>
          </Form.Group>

          <hr />
          <Row className='mt-5'>
            <Col className='text-end'>
              <Button
                variant='outline-secondary'
                type='button'
                className='px-4 mx-3'
                onClick={() => navigate("/disManagement")}
              >
                목 록
              </Button>
              <Button as='input' type='submit' value='저장' />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(ManagementEdit);
