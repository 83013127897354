import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { memo, useEffect, useState } from "react";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { getAllContentInfoFunc } from "../../api";
import ContentInfoHead from "./ContentInfoHead";
import Pagenation from "../../components/Pagenation";


const ContentInfo = () => {
  const navigate = useNavigate();
  const [data,setData]=useState([])
  console.log("🚀 ~ Receiving ~ data:", data)

  const [searchStartDay,setSearchStartDay] = useState("")
  const [searchEndDay,setSearchEndDay] = useState("")

  const [selectClient,setSelectClient] = useState("All")
  const [selectDepartment,setSelectDepartment] = useState("All")
  const [selectProduct,setSelectProduct] = useState("All")
  const [selectCatgoris,setSelectCatgoris] = useState("All")
  const [search,setSearch] = useState("")
  const [searchState,setSearchState] = useState({신규: false,
    접수: false,
    발송: false,
    취소: false,})
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });

  const [notice, setNotice] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL+"/event/hallinfo")
      .then((res) => {
        // console.log(res.data);
        // navigate("/partnerstable")
        setNotice(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const searchForm = {title:"행사장정보",
  period: {
    exist: true,name:"작성일",
  },
  section: { exist: false },
  state: { exist: false ,titleName:"상태",type:"checkbox",name:["접수","신규","발송","취소"]},
  search: { exist: true },
};
const searchData = {searchStartDay ,searchEndDay,search,page}

const allContentInfoGet = async (searchData) => {
  const response = await getAllContentInfoFunc(searchData);
  if (response.status === 200) {

    setData(()=>response?.data?.data
    );
    setPageCount(()=>
      response?.data?.Pagination?.pageCount
    );
    setTotalCount((totlaCount)=>({
      ...totlaCount,
      total: response?.data?.Pagination?.count,
      pageItem: response?.data?.Pagination?.pageItem,
    }));
  } else {
    toast.error("전체물품을 가져오는데 실패했습니다.");
  }
};

useEffect(() => {
  allContentInfoGet(searchData)
 
}, [page]);


const handleSearchFnc = async(e)=>{
  e.preventDefault();
  allContentInfoGet(searchData)
 
}
  return (
    <>
    
      <Container className="align-items-center justify-content-center p-5">
        <ContentInfoHead   
        handleSearchFnc ={handleSearchFnc}
        setSelectClient={setSelectClient} 
        selectClient={selectClient}
        setSelectDepartment={setSelectDepartment}
        selectDepartment={selectDepartment}
        setSelectProduct={setSelectProduct}
        selectProduct={selectProduct}
        selectCatgoris={selectCatgoris}
        setSelectCatgoris={setSelectCatgoris}
        setSearchStartDay={setSearchStartDay}
        searchStartDay={searchStartDay}
        setSearchEndDay={setSearchEndDay}
        searchEndDay={searchEndDay}
        setSearchState={setSearchState}
        searchState={searchState}
        setSearch={setSearch}
        search={search}/>
        <Table bordered striped hover>
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>제목</th>
              <th className='text-center'>작성일</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/contentinfoview/" + item._id)}
              >
                 <td>{totlaCount?.total -
                            (page - 1) * totlaCount?.pageItem -
                            idx}</td>
                <td>{item.title}</td>
                <td>{item.createdAt.slice(0,10)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagenation
              pageCount={pageCount}
              page={page}
              setPage={setPage}
            />
        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          <Col className='text-end '>
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/contentinforegister");
              }}
            >
              행사장정보 등록
            </Button>{" "}
          </Col>
        </Row>
      

      </Container>
    </>
  );
};

export default memo(ContentInfo);
