import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clientDepartmentUpdateFunc,
  getAllProductFunc,
  getAllStaffsFunc,
  getClientFunc,
  clientUpdateFunc,
  clientDepartmentUpdateSetFunc,
  deleteDepartment
} from "../../../api";
import { toast } from "react-toastify";
import DepartmentTable from "./DepartmentTable";
import ProductTable from "./ProductTable";
import ConfirmModal from "../../../components/ConfirmModal";

const DepartmentInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();
  const departmentRef = useRef();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [staffs, setStaffs] = useState([]);
  // const client = useSelector((state) => state.client);
  const [client, setClient] = useState({});
  const [products, setProducts] = useState([]);
  const [addPartment, setAddPartment] = useState("");
  // const { department } = client;
  const [part, setPart] = useState([]);
  const [selectPart, setSelectPart] = useState("all");
  const [listDepartment, setListDepartment] = useState([]);
  const [departmentEdit, setDepartmentEdit] = useState(selectPart);
  const [selectFilterStaff, setSelectFilterStaff] = useState(staffs);
  const [selectFilterProduct, setSelectFilterProduct] = useState(products);
  const [selectSection, setSelectSection] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [departmentDelete, setDepartmentDelete] = useState("");
  const [loadProducts, setLoadProduct] = useState(false);

  const handleShowModal = (departmentName) => (e) => {
    e.stopPropagation()
    setShowModal(true)
    setDepartmentDelete(departmentName)
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmAction = async () => {
    handleCloseModal();
    const data = {
      "department": departmentDelete
    }
    const response = await deleteDepartment(
      id,
      data
    );
    if (response.status === 200 || response.status === 201) {
      toast.success("Successfully Delete!");
      clientGet(id);
      setSelectPart("all")
    }else {
      toast.error("Error!");
    }
  };

  if (location.state) {
    setSelectSection(location.state.selectSection)
    location.state = null
  }

  const allStaffGet = async (id) => {
    const response = await getAllStaffsFunc(id);
    if (response.status === 200) {
      setStaffs(response.data);
    } else {
      toast.error("error for get staff data");
    }
  };

  const allProductGet = async (id) => {
    const response = await getAllProductFunc(id, false);
    if (response.status === 200) {
      setProducts(response.data);
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  // 회사정보
  const clientGet = async (id) => {
    const response = await getClientFunc(id);
    if (response.status === 200) {
      setClient(response.data);
      setPart(response.data.department);
      // setListDepartment(response.data.department)
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  //NOTE - 팀원의 정보중 client 검색
  useEffect(() => {
    clientGet(id);
  }, [id]);

  //NOTE - 팀원의 정보중 client 검색
  useEffect(() => {
    allStaffGet(id);
    allProductGet(id);
  }, [id]);

  useEffect(() => {
    allProductGet(id);
    setLoadProduct(false);
  }, [loadProducts]);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleOpenEditForm = () => {
    setOpenEdit(!openEdit)
  }

  //NOTE -  회사정보 업데이트 (부서추가)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // console.log(Object.fromEntries(formData.entries()));
    const config = {
      "Content-Type": "multipart/form-data",
    };
    const response = await clientDepartmentUpdateFunc(id, formData, config);
    if (response.status === 200 || response.status === 201) {
      // console.log(response.data)
      toast.success("Successfully Update!");
      setPart((prev) => prev.concat(addPartment));
      setAddPartment("");
      // departmentRef.current.value = "";
      handleClickOpen();
      // navigate("/partnerstable", { replace: true });
    } else {
      toast.error("부서정보 추가실패!!");
    }
  };

  const handleChangePartment = (e) => {
    // console.log(e.target.name);
    setSelectPart(e.target.name);
    setDepartmentEdit(e.target.name)
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    let newListDepartment = part.map((v, i) => {
      if (v == selectPart) {
        return departmentEdit
      }
      else {
        return v
      }
    })
    setPart(newListDepartment)
    const config = {
      "Content-Type": "multipart/form-data",
    };
    const response = await clientDepartmentUpdateSetFunc(id, { department: newListDepartment, new_department: departmentEdit, old_department: selectPart }, config);
    if (response.status === 200 || response.status === 201) {
      const responseStaff = await getAllStaffsFunc(id);
      if (responseStaff.status === 200) {
        setStaffs(responseStaff.data);
      }
      const responseProduct = await getAllProductFunc(id, false);
      if (responseProduct.status === 200) {
        setProducts(responseProduct.data);
      }
      toast.success("Successfully Update!");
      handleOpenEditForm();
      // navigate("/partnerstable", { replace: true });
    } else {
      toast.error("부서정보 추가실패!!");
    }
    setSelectPart(departmentEdit);

  }
  // 부서필터
  useEffect(() => {
    if (selectPart === "all") {
      setSelectFilterStaff(staffs);
      setSelectFilterProduct(products);
    } else {
      console.log("staffs", staffs);

      setSelectFilterStaff(
        staffs?.filter((staff) => staff.department === selectPart)
      );
      setSelectFilterProduct(
        products?.filter((product) => product.department === selectPart)
      );
    }
  }, [selectPart, staffs, products]);

  return (
    <>
      <Container className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          {client.companyName} 부서정보
        </Row>

        <Row className=' my-5'>
          <Col className='text-start '>
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/partnerView/" + id);
              }}
              className='px-4 mx-2'
            >
              거래처정보
            </Button>{" "}
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/partnerShippingInfo/" + id);
              }}
              className='px-4 mx-2'
            >
              배송지정보
            </Button>{" "}
            <Button variant='outline-secondary' active className='px-4 mx-2'>
              부서 / 제품 정보
            </Button>{" "}
          </Col>
        </Row>
        <Row className=' my-5 mx-1'>
          <Col className='text-satrt' sm='12'>
            <Button
              variant='light'
              name='all'
              active={selectPart === "all" ? true : false}
              className='px-4 mx-1 mb-3 border'
              onClick={handleChangePartment}
            >
              All
            </Button>
            {part?.map((it, idx) => (
              <Button
                key={idx}
                variant='light'
                name={it}
                active={selectPart === it ? true : false}
                className='px-4 mx-1 mb-3 border department-btn'
                onClick={handleChangePartment}
              >
                {it}
                <button type="button" class="btn-close department-btn-close" aria-label="Close" onClick={handleShowModal(it)}></button>
              </Button>
            ))}
          </Col>
        </Row>
        <Row className='m-3 flex items-center'>
          <Col className='text-start mx-0 '>
            <div className='inline-flex flex-col justify-start items-center'>
              <div className='fs-3 fw-bold mb-2'>
                {client && client.companyName}{" "}
                {selectPart === "all" ? "전체부서" : selectPart}
              </div>
              <div>
                <span
                  className='cursor-pointer rounded px-2 py-1 bg-blue-400 text-white font-semibold'
                  onClick={() => setSelectSection(true)}
                >
                  팀원정보
                </span>{" "}
                |{" "}
                <span
                  className='cursor-pointer rounded px-2 py-1 bg-red-400 text-white font-semibold'
                  onClick={() => setSelectSection(false)}
                >
                  {" "}
                  제품정보
                </span>
              </div>
            </div>
          </Col>
          <Col className='text-end '>
            <div className='relative inline-block'>
              {openEdit && selectPart !== "all" && (
                <div className='z-10 overflow-hidden shadow-md border absolute w-[250px] right-[-25px] top-[-120px] flex flex-col items-center rounded'>
                  <div className='bg-slate-200 w-full text-center font-bold text-slate-600 py-2'>
                    부서추가
                  </div>

                  <Form
                    onSubmit={handleEdit}
                    className='flex p-3 gap-2 w-full h-full bg-white justify-center'
                  >
                    <input
                      className='border-b p-1 focus:outline-none'
                      name='department'
                      ref={departmentRef}
                      value={departmentEdit}
                      placeholder='부서명'
                      onChange={(e) => setDepartmentEdit(e.target.value)}
                      required
                    />

                    <Button
                      variant='primary'
                      type='submit'
                      size='sm'
                      className=' '
                    >
                      저장
                    </Button>
                  </Form>
                </div>
              )}
              {/* <OverlayTrigger trigger='click' placement='top' overlay={popover}> */}
              {selectPart === "all" ? '' :
                <Button
                  variant='primary'
                  onClick={handleOpenEditForm}
                  className='px-4 mr-3'
                >
                  {open ? "부서명 변경 닫기" : "부서명 변경 열기"}
                </Button>
              }
              {/* </OverlayTrigger> */}
            </div>
            <div className='relative inline-block'>
              {open && (
                <div className='z-10 overflow-hidden shadow-md border absolute w-[250px] right-[-25px] top-[-120px] flex flex-col items-center rounded'>
                  <div className='bg-slate-200 w-full text-center font-bold text-slate-600 py-2'>
                    부서추가
                  </div>

                  <Form
                    onSubmit={handleSubmit}
                    className='flex p-3 gap-2 w-full h-full bg-white justify-center'
                  >
                    <input
                      className='border-b p-1 focus:outline-none'
                      name='department'
                      ref={departmentRef}
                      value={addPartment}
                      placeholder='부서명'
                      onChange={(e) => setAddPartment(e.target.value)}
                      required
                    />

                    <Button
                      variant='primary'
                      type='submit'
                      size='sm'
                      className=' '
                    >
                      저장
                    </Button>
                  </Form>
                </div>
              )}
              {/* <OverlayTrigger trigger='click' placement='top' overlay={popover}> */}
              <Button
                variant='primary'
                onClick={handleClickOpen}
                className='px-4 '
              >
                {open ? "부서추가 닫기" : "부서추가 열기"}
              </Button>
              {/* </OverlayTrigger> */}
            </div>
          </Col>
        </Row>

        {selectSection && (
          <DepartmentTable
            selectFilterStaff={selectFilterStaff}
            id={id}
            navigate={navigate}
          />
        )}
        {!selectSection && (
          <ProductTable
            selectFilterProduct={selectFilterProduct}
            id={id}
            navigate={navigate}
            setLoadProduct={setLoadProduct}
          />
        )}

        <ConfirmModal
          show={showModal}
          onHide={handleCloseModal}
          onConfirm={handleConfirmAction}
          className="confirm-modal text-center"
          btnCancel="취소"
          btnConfirm="확인"
          title="부서 삭제"
          message='팀원과 제품 정보를 먼저 삭제하신 후에 "부서삭제"가 가능합니다.'
          additionMessage = '팀원과 제품정보를 모두 삭제하고 진행하시겠습니까?'
        />
      </Container>
    </>
  );
};

export default memo(DepartmentInfo);
