
import {   Col ,Card } from 'react-bootstrap'
import React, { memo } from 'react'
import SvgIcon from "@mui/material/SvgIcon";

const DashboardCard = ({prop}) => {
  return (
   
    <Card className='dashboard-item'>
      <Card.Body className=' text-center'>
        <div className="avatar">
        <SvgIcon component={prop.img} inheritViewBox className='img' />
        </div>
        
        <Card.Subtitle className="mb-2 mt-3 text-muted">{prop.title}</Card.Subtitle>
        <Card.Text className='text-bold card__text'>
          {prop.number}건
        </Card.Text>
        
      </Card.Body>
    </Card>
   
  )
}

export default memo(DashboardCard)