import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState, useRef } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useNavigate, useParams } from "react-router-dom";
import { clientUpdateFunc, getClientFunc } from "../../../api";
import { toast } from "react-toastify";

// TODO: form 검증

const PartnerEdit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [forms, setForms] = useState([]);
  const [value, setValue] = useState("");
  const [zipcode, setZipcode] = useState("");
  /* preview image*/
  const [imgSrc, setImgSrc] = useState(null);

  const fileInputRef = useRef('');

  // FIXME: 우편번호

  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

  const open = useDaumPostcodePopup(scriptUrl);
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let zoneCode = data.zonecode;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setValue(fullAddress);
    setZipcode(zoneCode);
  };

  // 우편번호 찾기 트리거 해줄 함수
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  const handleChangeLogo = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the file input
        setImgSrc("");
      }
    } else {
      encodeFileToBase64(selectedFile);
    }
  };
  
  const clientGet = async (id) => {
    const response = await getClientFunc(id);
    if (response.status === 200) {
      setForms(response.data);
      setImgSrc(response.data?.logoImage);
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };
  useEffect(() => {
    clientGet(id);
  }, [id]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // console.log(Object.fromEntries(formData.entries()));
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await clientUpdateFunc(formData, id, config);
    if (response.status === 200) {
      // console.log(response.data)
      toast.success("Successfully Update!");
      navigate("/partnerstable", { replace: true });
    } else {
      toast.error("거래처정보 변경실패!!");
    }
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>거래처 수정</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업체명
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='companyName'
                value={forms.companyName || ""}
                onChange={onChange}
                size='sm'
                // disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              회사코드
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='companyCode'
                value={forms.companyCode || ""}
                onChange={onChange}
                size='sm'
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              대표ID
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='daepyoId'
                size='sm'
                value={forms.daepyoId || ""}
                onChange={onChange}
                disabled
              />
            </Col>
            {/* <Col sm='3'>
              <Button variant='primary' size='sm'>
                중복검사
              </Button>{" "}
            </Col> */}
          </Form.Group>
          {/*  */}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              대표자명
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='ceoName'
                size='sm'
                value={forms.ceoName || ""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              사업자번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='companyNumber'
                size='sm'
                value={forms.companyNumber || ""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              법인번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='corporationNumber'
                size='sm'
                value={forms.corporationNumber || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업종
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='sectors'
                size='sm'
                value={forms.sectors || ""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업태
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='business'
                size='sm'
                value={forms.business || ""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              대표전화
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='telNumber'
                size='sm'
                value={forms.telNumber || ""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              팩스번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='faxNumber'
                size='sm'
                value={forms.faxNumber || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              주소
            </Form.Label>
            <Col sm='6'>
              <Form.Control
                type='text'
                name='addr1'
                size='sm'
                value={value === "" ? forms.addr1 : value || ""}
              />
            </Col>
            <Col sm='3'>
              <Button
                variant='primary'
                type='button'
                size='sm'
                onClick={handleClick}
                className=''
              >
                우편번호찾기
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'></Form.Label>
            <Col sm='6'>
              <Form.Control
                type='text'
                name='addr2'
                size='sm'
                value={forms?.addr2 || ""}
                onChange={onChange}
              />
            </Col>
            <Col sm='3'>
              <Form.Control
                type='text'
                name='zipCode'
                value={zipcode === "" ? forms.zipCode : zipcode || ""}
                size='sm'
              />
            </Col>
          </Form.Group>
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='9'>
              <small className='mt-3 px-2 d-block text-danger text-start'>
              * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          {/* 로고 셀렉트  */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              로고
            </Form.Label>
            <Col sm='9'>
              <Form.Group>
                <Form.Control
                  type='file'
                  size='sm'
                  name='logoImage'
                  onChange={handleChangeLogo}
                  accept='image/*'
                  ref={fileInputRef}
                />
              </Form.Group>
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col sm='9'>
              {imgSrc && (
                <img
                  src={imgSrc}
                  alt='img preview'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              )}
            </Col>
          </Form.Group>

          {/* 홈페이지 필드 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              홈페이지
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='homePage'
                size='sm'
                value={forms.homePage || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Row>
            <Col className='text-center '>
              <Button
                type='button'
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/partnerView/" + id)}
              >
                취소
              </Button>
              <Button as='input' type='submit' value='저장' className='px-4' />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(PartnerEdit);
