import { memo, useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";

// const Item = ({ title, to, icon, selected, setSelected }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <MenuItem
//       active={selected === title}
//       style={{
//         color: colors.grey[100],
//       }}
//       onClick={() => setSelected(title)}
//       icon={icon}
//     >
//       <Typography>{title}</Typography>
//       <Link to={to} />
//     </MenuItem>
//   );
// };

const Sidebar = ({isSidebar}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed] = useState(false);
  // const [selected, setSelected] = useState("Dashboard");
  return (
    isSidebar && <Box
      sx={{
        "& .pro-sidebar-inner": {
          // background: `${colors.primary[400]} !important`,
          background: `#1F2A40 !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          color: `${colors.grey[900]} !important`,
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        style={{ height: "100vh", position: "fixed", overflow: "auto" }}
      >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <Box
            // onClick={() => setIsCollapsed(!isCollapsed)}
            // icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              cursor: "default",
              margin: "10px 0 20px 20px",
              // color: colors.grey[100],
              color: "#fff",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h5" color={colors.grey[900]}>
                  ADMINIS
                  {/* 관리자페이지 */}
                </Typography>
                {/* <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton> */}
              </Box>
            )}
          </Box>
          {!isCollapsed && (
            <Box mb="25px">
              <div className="flex justify-center">
                <img
               
                  alt="profile-user"
                  width="70%"
                  // height="100px"
                  src={`${process.env.PUBLIC_URL}/assets/designtt.svg`}
                  // style={{ cursor: "pointer", borderRadius: "50%" }}
                />
                <Link to="/" />
              </div>

              <Box textAlign="center">
                {/* <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  홍길동
                </Typography> */}
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                ></Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {/* <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <MenuItem
              icon={<HomeOutlinedIcon />}
              
            >
              Dashboard
              <Link to="/" />
            </MenuItem>

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              물류관리
            </Typography> */}

            <SubMenu
              icon={<PeopleOutlinedIcon />}
              title="물류관리(출고)"
              label="물류관리"
            >
              <MenuItem >
                물류접수현황
                <Link to="/disReceving" />
              </MenuItem>
              <MenuItem >
                물류발송내역
                <Link to="/disSendHistory" />
              </MenuItem>

              <MenuItem >
                입고신청관리
                <Link to="/incomingorderlist" />
              </MenuItem>
            </SubMenu>
            {/* 행사관리 */}
            <SubMenu
              icon={<ContactsOutlinedIcon />}
              title="행사관리"
              label="행사관리"
            >
              <MenuItem >
                행사접수현황
                <Link to="/eventform" />
              </MenuItem>
              <MenuItem >
                행사완료내역
                <Link to="/eventReport" />
              </MenuItem>
              <MenuItem >
                행사일정표
                <Link to="/eventSchedule" />
              </MenuItem>
            </SubMenu>

            <SubMenu
              icon={<ReceiptOutlinedIcon />}
              title="거래처관리"
              label="거래처관리"
            >
              <MenuItem >
                거래처
                <Link to="/partnerstable" />
              </MenuItem>
              {/* <MenuItem >
                거래처부서 제품정보
                <Link to="/productInfo" />
              </MenuItem> */}

              <MenuItem >
                부스관리/등록
                <Link to="/bmanager" />
              </MenuItem>
              <MenuItem >
                부스종류관리
                <Link to="/boothtype" />
              </MenuItem>
            </SubMenu>

            <MenuItem
              icon={<ReceiptOutlinedIcon />}
           
            >
              배너
              <Link to="/banner" />
            </MenuItem>
            <MenuItem
              icon={<ReceiptOutlinedIcon />}
           
            >
              팝업
              <Link to="/popup" />
            </MenuItem>

            <SubMenu
              icon={<ReceiptOutlinedIcon />}
              title="재고관리"
              to="/dtable"
              label="재고관리"
            >
              {/* <MenuItem >
                재고관리
                <Link to="/inventorylist" />
              </MenuItem> */}
              <MenuItem >
                물품관리
                <Link to="/disManagement" />
              </MenuItem>
              <MenuItem >
                재고변동현황
                <Link to="/log" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              icon={<ReceiptOutlinedIcon />}
              title="콘텐츠관리"
              to="/dtable"
              label="콘텐츠관리"
            >
              <MenuItem >
                공지사항관리
                <Link to="/notice" />
              </MenuItem>
              <MenuItem >
                부서별게시판
                <Link to="/board" />
              </MenuItem>
              <MenuItem >
                행사장갤러리관리
                <Link to="/gallery" />
              </MenuItem>
              <MenuItem >
                행사장정보관리
                <Link to="/contentinfo" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              icon={<BarChartOutlinedIcon />}
              title="통계"
              to="/dtable"
              label="통계"
            >
              <MenuItem >
                매출통계
                <Link to="/salesByCompany" />
              </MenuItem>
            </SubMenu>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default memo(Sidebar);
