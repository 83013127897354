import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar.jsx";
import Sidebar from "./scenes/global/Sidebar.jsx";
import Dashboard from "./scenes/dashboard/index.jsx";
import ClientLogin from './scenes/auth/ClientLogin.jsx';

// import Correspondent from "./scenes/distribution";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme.js";
// 거래처
import PartnersTable from "./scenes/partners/client/PartnersTable.jsx";
import PartnerRegister from "./scenes/partners/client/PartnerRegister.jsx";
import PartnerEdit from "./scenes/partners/client/PartnerEdit.jsx";
import PartnerShippingInfo from "./scenes/partners/shipping/PartnerShippingInfo.jsx";
import ShippingInfomodify from "./scenes/partners/shipping/ShippingInfomodify.jsx";
import DepartmentInfo from "./scenes/partners/staff/DepartmentInfo.jsx";
import MemberRegister from "./scenes/partners/staff/MemberRegister.jsx";
import TeamMemberInfo from "./scenes/partners/TeamMemberInfo.jsx";
import MemberView from "./scenes/partners/staff/MemberView.jsx";
import ProductInfo from "./scenes/partners/product/ProductInfo.jsx";
import ProductView from "./scenes/partners/product/ProductView.jsx";
import ProductRegister from "./scenes/partners/product/ProductRegister.jsx";
import PartnerView from "./scenes/partners/client/PartnerView.jsx";
import BoothManager from "./scenes/partners/boothManagement/ManageMent.jsx";
import BoothRegister from "./scenes/partners/boothManagement/BoothRegister.jsx";
import BoothView from "./scenes/partners/boothManagement/BoothView.jsx";
import BoothType from "./scenes/partners/boothType/BoothType.jsx";
import BoothTypeRegister from "./scenes/partners/boothType/BoothTypeRegister.jsx";
import BoothTypeEdit from "./scenes/partners/boothType/BoothTypeEdit.jsx";

// 물류관리
import Receiving from "./scenes/distribution/Receiving.jsx";
import ReceivingForm from "./scenes/distribution/ReceivingForm.jsx";
import ManageMentForm from "./scenes/distribution/ManageMentForm.jsx";
import SendHistory from "./scenes/distribution/SendHistory.jsx";
import ManageMentDetail from "./scenes/distribution/ManageMentDetail.jsx";
import IncomingOrderList from "./scenes/distribution/IncomingOrderList.jsx";
import IncomingOrderView from "./scenes/distribution/IncomingOrderView.jsx";
// 행사관리
import EventForm from "./scenes/eventmanagement/EventForm.jsx";
import EventFormDetail from "./scenes/eventmanagement/EventFormDetail.jsx";
import EventCompleteDetail from "./scenes/eventmanagement/EventCompleteDetail.jsx";
import EventFormReport from "./scenes/eventmanagement/EventFormReport.jsx";
import EventReport from "./scenes/eventmanagement/EventReport.jsx";
import EventSchedule from "./scenes/eventmanagement/EventSchedule.jsx";
// 배너관리
import Banner from "./scenes/banner/Banner.jsx";
import BannerRegister from "./scenes/banner/BannerRegister.jsx";
import BannerView from "./scenes/banner/BannerView.jsx";
// 재고관리
import InventoryList from "./scenes/inventorymanagement/InventoryList.jsx";
import LoManagement from "./scenes/distribution/ManageMent.jsx";
import InventoryManagementDetail from "./scenes/inventorymanagement/InventoryManagementDetail.jsx";
import InventoryManagementRegister from "./scenes/inventorymanagement/InventoryManagementRegister.jsx";
import ChangeLog from "./scenes/inventorymanagement/ChangeLog.jsx";
// 콘텐츠관리
import Nocitce from "./scenes/contentmanagement/Notice.jsx";
import NoticeRegister from "./scenes/contentmanagement/NoticeRegister.jsx";
import NoticeView from "./scenes/contentmanagement/NoticeView.jsx";
import DepartmentsBoard from "./scenes/contentmanagement/DepartmentsBoard.jsx";
import DepartmentsBoardRegister from "./scenes/contentmanagement/DepartmentsBoardRegister.jsx";
import DepartmentsBoardView from "./scenes/contentmanagement/DepartmentsBoardView.jsx";

import ContentGalley from "./scenes/contentmanagement/ContentGallery.jsx";
import GalleryRegister from "./scenes/contentmanagement/GalleryRegister.jsx";
import GalleryView from "./scenes/contentmanagement/GalleryView.jsx";
import ContentInfo from "./scenes/contentmanagement/ContentInfo.jsx";
import ContentInfoRegister from "./scenes/contentmanagement/ContentInfoRegister.jsx";
import ContentInfoView from "./scenes/contentmanagement/ContentInfoView.jsx";

// 통계 statistics
import SalesByCompany from "./scenes/statistics/SalesByCompany.jsx";

import PrintTable from "./page/PrintTable.jsx";

import "./style.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import PartnerEditPassWord from "./scenes/partners/client/PartnerEditPassWord.jsx";
import ManagementEdit from "./scenes/distribution/ManagementEdit.jsx";
import PopupRegister from "./scenes/popup/PopupRegister.jsx";
import Popup from "./scenes/popup/Popup.jsx";
import PopupView from "./scenes/popup/PopupView.jsx";



function App() {
  const [theme, colorMode] = useMode();
  //ẩn hiện Sidebar th
  const [isSidebar, setIsSidebar] = useState(true);
  const user = useSelector(state => state.user.user);
  function checkIfUserIsAuthenticated() {
    const token = user?._id
    return token; // Trả về true nếu có token, ngược lại trả về false
  }
  const isAuthenticated = checkIfUserIsAuthenticated();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='app'>
          <Sidebar isSidebar={isSidebar} />
          <main
            className='content'
            style={{ marginLeft: "270px", padding: "10px 0 20px" }}
          >
            <ToastContainer
              position='top-center'
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme='light'
            />

            {/* <ProductInfo/> */}
            <Topbar setIsSidebar={setIsSidebar} isSidebar={isSidebar}/>
            <Routes>
              {/* Kiểm tra trạng thái đăng nhập */}
              {isAuthenticated ? (
                <>
                  <Route path='/' element={<Dashboard />} />
                 
    
                  {/* 거래처 */}
                  <Route path='/partnerstable' element={<PartnersTable />} />
                  <Route path='/partnerRegister' element={<PartnerRegister />} />
                  <Route path='/partneredit/:id' element={<PartnerEdit />} />
                  <Route path='/partnereditpassword/:id' element={<PartnerEditPassWord />} />
                  <Route path='/partnerView/:id' element={<PartnerView />} />
                  <Route
                    path='/partnerShippingInfo/:id'
                    element={<PartnerShippingInfo />}
                  />
                  <Route path='/departmentInfo/:id' element={<DepartmentInfo />} />
                  <Route path='/teamMemberInfo' element={<TeamMemberInfo />} />
                  <Route path='/memberRegister/:did' element={<MemberRegister />} />
                  <Route path='/memberView/:staffId' element={<MemberView />} />
                  <Route path='/productInfo/:did' element={<ProductInfo />} />
                  <Route path='/productview/:did/:id' element={<ProductView />} />
                  <Route
                    path='/productRegister/:did'
                    element={<ProductRegister />}
                  />
                  <Route
                    path='/shippingInfomodify/:id'
                    element={<ShippingInfomodify />}
                  />
                  <Route path='/bmanager' element={<BoothManager />} />
                  <Route path='/boothRegister' element={<BoothRegister />} />
                  <Route path='/boothview/:id' element={<BoothView />} />
                  <Route path='/boothtype' element={<BoothType />} />
                  <Route
                    path='/boothtyperegister'
                    element={<BoothTypeRegister />}
                  />
                  <Route path='/boothtype-edit/:id' element={<BoothTypeEdit />} />
                  {/* 물류관리 */}
                  <Route path='/disReceving' element={<Receiving />} />
                  <Route path='/manageMentForm' element={<ManageMentForm />} />
                  <Route path='/disrecevingform/:id' element={<ReceivingForm />} />
                  <Route path='/disSendHistory' element={<SendHistory />} />
                  <Route path='/disManagement' element={<LoManagement />} />
                  <Route path='/management-edit/:id' element={<ManagementEdit />} />
                  <Route path='/managementdetail/:id' element={<ManageMentDetail />} />
                  <Route path='/incomingorderlist' element={<IncomingOrderList />} />
                  <Route path='/incomingorderview/:id' element={<IncomingOrderView />} />
                  {/* 행사관리 */}
                  <Route path='/eventform' element={<EventForm />} />
                  <Route path='/eventformdetail/:id' element={<EventFormDetail />} />
                  {/* eventrequest */}
                  <Route path='/eventFormReport' element={<EventFormReport />} />
                  <Route path='/eventReport' element={<EventReport />} />
                  <Route path='/eventcompletedetail/:id' element={<EventCompleteDetail />} />
                  <Route path='/eventSchedule' element={<EventSchedule />} />
                  {/* 배너관리 */}
                  <Route path='/banner' element={<Banner />} />
                  <Route path='/bannerRegister' element={<BannerRegister />} />
                  <Route path='/bannerview/:id' element={<BannerView />} />
                   {/* Popup */}
                  <Route path='/popup' element={<Popup />} />
                  <Route path='/popupRegister' element={<PopupRegister />} />
                  <Route path='/popupview/:id' element={<PopupView />} />
                  {/*재고관리 */}
                  <Route path='/inventorylist' element={<InventoryList />} />
                  <Route
                    path='/inventoryManagementDetail'
                    element={<InventoryManagementDetail />}
                  />
                  <Route
                    path='/inventoryManagementRegister'
                    element={<InventoryManagementRegister />}
                  />
                  <Route path='/log' element={<ChangeLog />} />
                  {/* 콘텐츠관리 */}
                  <Route path='/notice' element={<Nocitce />} />
                  <Route path='/noticeview/:id' element={<NoticeView />} />
                  <Route path='/noticeRegister' element={<NoticeRegister />} />
                  <Route path='/board' element={<DepartmentsBoard />} />
                  <Route
                    path='/boardregister'
                    element={<DepartmentsBoardRegister />}
                  />
                  <Route path='/boardview/:id' element={<DepartmentsBoardView />} />
                  <Route path='/gallery' element={<ContentGalley />} />
                  <Route path='/galleryregister' element={<GalleryRegister />} />
                  <Route path='/galleryview/:id' element={<GalleryView />} />
                  <Route path='/contentinfo' element={<ContentInfo />} />
                  <Route
                    path='/contentinforegister'
                    element={<ContentInfoRegister />}
                  />
                  <Route
                    path='/contentinfoview/:id'
                    element={<ContentInfoView />}
                  />
                  {/* 통계 */}
                  <Route path='/salesByCompany' element={<SalesByCompany />} />
                  {/* print */}
                  <Route path='/printTable' element={<PrintTable />} />
                </>
              ) : (
                // Nếu chưa đăng nhập, điều hướng đến trang đăng nhập
                <Route path="*" element={<Navigate to="/login" />} />
              )}
              <Route path="/login" element={<ClientLogin setIsSidebar={setIsSidebar} isSidebar={isSidebar} />} />

            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;