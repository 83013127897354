import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getClient, getStaffs } from "../../../redux/clientSlice";
import {
  getAllClientsFunc,
  getAllStaffsFunc,
  getClientFunc,
  getAllClientsExcelFunc
} from "../../../api";
import { toast } from "react-toastify";
import PartnersTableHead from "./PartnersTableHead";
import Pagenation from "../../../components/Pagenation";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import fs from 'fs';
import {BASE_URL} from "../../../api/helper";
const DistribTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [search,setSearch] = useState("")
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const changePageHandle = () => {
    navigate("/partnerRegister");
  };

  const headColumn = [
    "번호",
    "거래처명",
    "대표ID",
    "대표자명",
    "대표전화",
    "업종",
  ];

  // 전체회사 리스트

  const allClientsGet = async (search,page) => {
    const response = await getAllClientsFunc(search,page);
    if (response.status === 200) {
      setData(()=>response?.data?.data);
      setPageCount(()=>
        response?.data?.Pagination?.pageCount
      );
      setTotalCount((totlaCount)=>({
        ...totlaCount,
        total: response?.data?.Pagination?.count,
        pageItem: response?.data?.Pagination?.pageItem,
      }));
    } else {
      toast.error("error for get Clients data");
    }
  };

  // 회사정보

  const clientGet = async (id) => {
    const response = await getClientFunc(id);
    if (response.status === 200) {
      dispatch(getClient(response.data));
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  // 직원정보

  const staffsGet = async (id) => {
    const response = await getAllStaffsFunc(id);
    if (response.status === 200) {
      dispatch(getStaffs(response.data));
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  // 회사리스트 가져오기
  useEffect(() => {
    allClientsGet(search,page);
  }, [page]);

  const handleChangeViewPage = (id) => {
    // 회사정보
    clientGet(id);
    // 소속직원
    staffsGet(id);
    navigate("/partnerView/" + id);
  };


  const handleSearchFnc = async(e)=> {
    e.preventDefault();
    allClientsGet(search,page)
    setPage(1)
  }

  const exportToExcel = async () => {
      const url = `${BASE_URL}/client/excel?search=${encodeURIComponent(search)}`;
      const outputFilename = '거래처.xlsx';
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  };

  const myButton = [
    { variant: "outline-secondary", name: "엑셀다운로드", onClick: exportToExcel },
    { variant: "primary", name: "거래처등록", onClick: changePageHandle },
  ];

  return (
    <>
      <Container className='align-items-center justify-content-center p-5'>
        <PartnersTableHead search={search} setSearch={setSearch} handleSearchFnc={handleSearchFnc}/>

        <Table striped bordered hover>
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center align-middle'
                style={{ cursor: "pointer" }}
                onClick={(e) => handleChangeViewPage(it._id)}
              >
                <td>{totlaCount?.total -
                            (page - 1) * totlaCount?.pageItem -
                            idx}</td>
                {it?.companyName ? <td>{it.companyName}</td> : null}
                {it?.daepyoId ? <td>{it.daepyoId}</td> : null}
                {it?.ceoName ? <td>{it.ceoName}</td> : null}
                {it?.telNumber ? <td>{it.telNumber}</td> : null}
                {it?.sectors ? <td>{it.sectors}</td> : null}
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagenation
              pageCount={pageCount}
              page={page}
              setPage={setPage}
/>
    
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            {myButton.map((it, idx) => (
              <Button
                key={idx}
                variant={it.variant}
                onClick={it.onClick ? it.onClick : null}
                className='px-4 mx-2'
              >
                {it.name}
              </Button>
            ))}
          </Col>
        </Row>
      
      </Container>
    </>
  );
};

export default memo(DistribTable);
