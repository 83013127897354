import { memo, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
// 오늘의날짜 

const PartnersTableHead = ({ setSearch, search, handleSearchFnc }) => {

  // 기간부분 날짜

  return (
    <>
      <Row className="fs-1 fw-bold p-2 border-bottom mb-3">
        거래처
      </Row>
      <Form onSubmit={handleSearchFnc}>
        <Row className="align-items-center mb-5 py-3">
          <Col xs={2} className="text-center fs-6" >
            검색
          </Col>

          <>
            <Col xs={6}>
              <InputGroup>
                <Form.Control
                  placeholder="검색어를 입력하세요"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col>

              <Button variant="primary" type="submit" className="mx-3 px-4">
                검색
              </Button>{" "}
            </Col>
          </>

        </Row>
      </Form>
    </>
  );
};


export default memo(PartnersTableHead);
