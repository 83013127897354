import React, { memo } from 'react'
import { Button } from 'react-bootstrap';
import { useDaumPostcodePopup } from "react-daum-postcode";

const PostButton = ({forms,setForms}) => {
    

    // FIXME: 우편번호
  
    const scriptUrl =
      "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  
    const open = useDaumPostcodePopup(scriptUrl);
    
    const handleComplete = (data) => {
      let fullAddress = data.address;
      let extraAddress = "";
      let zoneCode = data.zonecode;
  
      if (data.addressType === "R") {
        if (data.bname !== "") {
          extraAddress += data.bname;
        }
        if (data.buildingName !== "") {
          extraAddress +=
            extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
      }
      setForms({...forms,addr1:fullAddress,zipCode:zoneCode});
    };

     // 우편번호 찾기 트리거 해줄 함수
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  return (
    <Button
    variant='primary'
    size='sm'
    type='button'
    onClick={handleClick}
    className=''
  >
    우편번호찾기
  </Button>
  )
}

export default memo(PostButton)