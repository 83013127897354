import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  getAllClientsFunc,
  getStatistics
} from "../../api";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Container from "@mui/material/Container";
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const SalesByCompany = () => {
  const [baseYear, setBaseYear] = useState("");
  const [partner, SetPartner] = useState("");
  const [listPartner, setListPartner] = useState();
  const [statistics, setStatistics] = useState();
  const nowYear = new Date().getFullYear();

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const DATA_COUNT = 12;
  const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
  const data = {
    labels: labels,
    datasets: [
      { label: statistics?.totalEventsLastYear?.label ?? `행사(${nowYear - 1})`, data: statistics?.totalEventsLastYear?.data ?? [], backgroundColor: 'rgb(255, 99, 132)', stack: "Stack 0" },
      { label: statistics?.totalGoodsLastYear?.label ?? `물류(${nowYear - 1})`, data: statistics?.totalGoodsLastYear?.data ?? [], backgroundColor: 'rgb(53, 162, 235)', stack: "Stack 0" },
      { label: statistics?.totalEventsNowYear?.label ?? `행사(${nowYear})`, data: statistics?.totalEventsNowYear?.data ?? [], backgroundColor: 'rgb(255, 99, 132)', stack: "Stack 1" },
      { label: statistics?.totalGoodsNowYear?.label ?? `물류(${nowYear})`, data: statistics?.totalGoodsNowYear?.data ?? [], backgroundColor: 'rgb(53, 162, 235)', stack: "Stack 1" },
    ],
  };

  const allClientsGet = async () => {
    const response = await getAllClientsFunc();
    if (response.status === 200) {
      setListPartner(() => response?.data?.data);
    } else {
      console.log("error for get Clients data");
    }
  };

  useEffect(() => {
    allClientsGet();
    setBaseYear(nowYear);
    SetPartner(listPartner?.[0].partner);
  }, [])

  const getTotalPrice = async () => {
    const response = await getStatistics({
      client_id: partner ?? listPartner?.[0]?._id,
      year: baseYear
    });
    if (response.status === 200) {
      setStatistics(() => response?.data);
    } else {
      console.log("error for get Clients data");
    }
  }

  useEffect(() => {
    (partner || listPartner?.[0]?._id) && getTotalPrice();
  }, [partner, baseYear, listPartner])

  const handleChange = (e) => {
    if (e.target.name == "baseYear") {
      setBaseYear(e.target.value)
    }
    if (e.target.name == "partner") {
      SetPartner(e.target.value)
    }
  }

  return (
    <Container className='py-3'>
      <Form>
        <Row>
          <Col sm="6" >
            <Form.Group as={Row} className="mb-3 px-3">
              <Col sm="2" className="text-center fs-6 col-2 d-flex align-items-center">
                Base Year
              </Col>
              <Col sm="9">
                <Form.Select aria-label="Base Year" name="baseYear" onChange={handleChange}>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col sm="6" >
            <Form.Group as={Row} className="mb-3 px-3">
              <Col sm="2" className="text-center fs-6 col-2 d-flex align-items-center">
                Partner
              </Col>
              <Col sm="9">
                <Form.Select aria-label="Partner" name="partner" onChange={handleChange}>
                  {listPartner && listPartner.map((partnerItem, index) => (
                    <option key={index} value={partnerItem._id}>{partnerItem.companyName}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Bar options={options} data={data} />
    </Container>
  );
};

export default SalesByCompany;
