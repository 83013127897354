import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { deleteBannerItemFunc, deletePopupItemFunc, getbannerItemFunc, getPopupItemFunc, updateBannerItemFunc, updatePopupItemFunc } from "../../api";
import { toast } from "react-toastify";

const PopupView = () => {
  const fileInput = useRef();
  const fileInput2 = useRef();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc2, setImgSrc2] = useState(null);
  const [forms, setForms] = useState([]);
  const [edit, setEdit] = useState(false);
  const { id } = useParams();

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  const encodeFileToBase64MB = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc2(reader.result);
        resolve();
      };
    });
  };

  const handleDelete = async () => {
    const res = await deletePopupItemFunc(id);

    if (res.status === 200) {
      setTimeout(() => {
        toast.success("Successfully Delete!");
        navigate("/popup", { replace: true });
      }, 1000);
    } else {
      toast.error("배너 삭제실패!");
    }
  };

  const handleChangeImage = (e) => {

    const selectedFile = e.target.files[0];
    const maxSize = 10 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('10MB 이하의 파일을 업로드해주세요.');
      if (fileInput.current) {
        fileInput.current.value = ''; // Reset the file input
        setImgSrc("");
      }
    } else {
      encodeFileToBase64(selectedFile);
    }
  };

  const handleChangeImage2 = (e) => {

    const selectedFile = e.target.files[0];
    const maxSize = 10 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('10MB 이하의 파일을 업로드해주세요.');
      if (fileInput.current) {
        fileInput.current.value = ''; // Reset the file input
        setImgSrc2("");
      }
    } else {
      encodeFileToBase64MB(selectedFile);
    }
  };

  useEffect(() => {
    const popupItemGet = async (id) => {
      const res = await getPopupItemFunc(id);
      if (res.status === 200) {
        setForms({
          ...forms,
          title: res.data.title,
          content: res.data.content,
          imagePC: res.data.imagePC,
          imageMB: res.data.imageMB,
          startDay: res.data.startDay,
          expireDay: res.data.expireDay,
          link: res.data.link,
          state: res.data.state,
          type: res.data.type,
        });
        setImgSrc(res.data.imagePC);
        setImgSrc2(res.data.imageMB);
      } else {
        toast.error("배너정보를 가져오지 못했습니다.");
      }
    };
    popupItemGet(id);
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!edit) {
      setEdit(true);
      return;
    }
    const formData = new FormData(e.target);
    console.log(Object.fromEntries(formData.entries()));
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const res = await updatePopupItemFunc(id, formData, config);
    if (res.status === 200) {
      navigate("/popup", { replace: true });
    } else {
      if (res?.response?.data?.error) {
        toast.error(res?.response?.data?.error);

      } else {
        toast.error("배너삭제를 하지 못했습니다.");
      }
    }
  };
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>팝업등록</Row>
        <Form onSubmit={handleSubmit}>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              유형
            </Form.Label>
            <Col sm="9" className="align-items-center">
              {["radio"].map((type) => (
                <div key={`inline-${type}`} value={forms.type} onChange={onChange}>
                  <Form.Check
                    inline
                    label="이미지"
                    name="type"
                    value={'1'}
                    type={type}
                    id={`use-${type}-1`}
                    disabled={!edit}
                    checked={forms.type == 1}
                  />
                  <Form.Check
                    inline
                    label="제목 + 내용"
                    name="type"
                    value={'2'}
                    type={type}
                    id={`use-${type}-2`}
                    disabled={!edit}
                    checked={forms.type == 2}
                  />
                  <Form.Check
                    inline
                    label="이미지 + 제목"
                    name="type"
                    value={'3'}
                    type={type}
                    id={`use-${type}-3`}
                    disabled={!edit}
                    checked={forms.type == 3}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          {forms?.type !== '1' &&
            <>
              <Form.Group as={Row} className="mb-3 px-3">
                <Form.Label column sm="2">
                  제목
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="title"
                    onChange={onChange}
                    value={forms.title || ""}
                    type="text"
                    size="sm"
                    disabled={!edit}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3 px-3">
                <Form.Label column sm="2">
                  내용
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="content"
                    onChange={onChange}
                    value={forms.content || ""}
                    type="text"
                    size="sm"
                    disabled={!edit}
                    as="textarea"
                    rows={4}
                  />
                </Col>
              </Form.Group>
            </>
          }

          {edit && forms?.type !== '2' &&
            <Row>
              <Form.Label column sm='2'>
                {""}
              </Form.Label>
              <Col lg='6'>
                <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                  * 10MB를 초과하는 파일은 등록할 수 없습니다.
                </small>
              </Col>
            </Row>}

          {/* 게시위치 end */}
          {forms?.type !== '2' && <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className=' d-flex flex-column'>
              이미지 PC
            </Form.Label>
            <Col sm='6'>
              <Form.Group controlId='formFile'>
                <Form.Control
                  type='file'
                  size='sm'
                  name='imagePC'
                  disabled={!edit}
                  ref={fileInput}
                  onChange={handleChangeImage}
                  accept='image/*'
                />
              </Form.Group>
              <small className="mt-2 d-block text-danger text-end">* 최적 사이즈 ({forms?.type === '1' ? 400 : 360} X 400)</small>
            </Col>
          </Form.Group>}
          {/* 이미지 프리뷰 */}
          {imgSrc &&
            forms?.type !== '2' && (
              <Form.Group as={Row} className='mb-3 px-3'>
                <Form.Label column sm='2'></Form.Label>
                <Col sm='9'>
                  <img
                    src={imgSrc}
                    alt='banner_image'
                    style={{ maxWidth: "200px", height: "auto" }}
                  />
                </Col>
              </Form.Group>
            )}

          {edit && forms?.type !== '2' &&
            <Row>
              <Form.Label column sm='2'>
                {""}
              </Form.Label>
              <Col lg='6'>
                <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                  * 10MB를 초과하는 파일은 등록할 수 없습니다.
                </small>
              </Col>
            </Row>}

          {/* 게시위치 end */}
          {forms?.type !== '2' && <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className=' d-flex flex-column'>
              이미지 모바일
            </Form.Label>
            <Col sm='6'>
              <Form.Group controlId='formFile'>
                <Form.Control
                  type='file'
                  size='sm'
                  name='imageMB'
                  disabled={!edit}
                  ref={fileInput2}
                  onChange={handleChangeImage2}
                  accept='image/*'
                />
              </Form.Group>
              {/* <small className='mt-2 d-block text-danger text-end'>
                * 최적 사이즈 (375 X 375)
              </small> */}
            </Col>
          </Form.Group>}
          {/* 이미지 프리뷰 */}
          {imgSrc2 && forms?.type !== '2' && (
            <Form.Group as={Row} className='mb-3 px-3'>
              <Form.Label column sm='2'></Form.Label>
              <Col sm='9'>
                <img
                  src={imgSrc2}
                  alt='banner_image'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              노출기간
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='date'
                disabled={!edit}
                size='sm'
                name='startDay'
                onChange={onChange}
                value={forms.startDay || ""}
                required
              />
            </Col>
            <Col sm='3'>
              <Form.Control
                type='date'
                size='sm'
                disabled={!edit}
                name='expireDay'
                onChange={onChange}
                value={forms.expireDay || ""}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              링크
            </Form.Label>
            <Col sm='6'>
              <Form.Control
                name='link'
                disabled={!edit}
                onChange={onChange}
                value={forms.link || ""}
                type='text'
                size='sm'
              />
            </Col>
          </Form.Group>

          {/* 게시상태 start */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              상태
            </Form.Label>
            <Col sm='9' className='align-items-center'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} onChange={onChange}>
                  <Form.Check
                    inline
                    label='활성'
                    name='state'
                    disabled={!edit}
                    value={"true"}
                    defaultChecked={forms.state}
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label='비활성'
                    name='state'
                    disabled={!edit}
                    value={"false"}
                    type={type}
                    defaultChecked={!forms.state}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          <Row>

            <Col className='text-start m-5 '>
              <Button
                variant='outline-danger'
                className='px-4 mx-3'
                onClick={handleDelete}
              >
                삭제
              </Button>{" "}
            </Col>

            <Col className='text-end m-5 '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/popup", { replace: true })}
              >
                {edit ? "취소" : "목 록"}
              </Button>{" "}
              <Button
                as='input'
                type='submit'
                value={edit ? "저장" : "수정"}
                className='px-4'
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(PopupView);
