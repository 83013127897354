import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

const useStoreLocal = (key, initialValue) => {

  const location = useLocation();
  const [backPage, setBackPage] = useState(false);

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item && location?.state?.backPage ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading from localStorage', error);
      return initialValue;
    }
  });

  if (location.state) {
    if (location.state?.backPage) {
      setBackPage(true);
    }
    location.state = null;
  }

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  };

  const setValueBackPage = (value) => setBackPage(value);

  useEffect(() => {
    const handlePageLoad = () => {
      window.localStorage.removeItem(key);
      setStoredValue({});
      if (location.state) {
        location.state = null;
      }
    };

    window.addEventListener('beforeunload', handlePageLoad);
    return () => {
      window.removeEventListener('beforeunload', handlePageLoad);
    };
  }, []);

  return [storedValue, setValue, backPage, setValueBackPage];
};

export default useStoreLocal;
