import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {useDispatch} from 'react-redux'
import { login } from '../../redux/authSlice';
import {BASE_URL} from '../../api/helper'
import { toast } from "react-toastify";

const ClientLogin = ({setIsSidebar,isSidebar} ) => {
    const disPatch = useDispatch()    
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState("");


  console.log("🚀 ~ ClientLogin ~ isSidebar:", isSidebar)
  
  const onLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/client/login`, {
        username: username,
        password: password,
        addmin: 'addmin'
      });
      console.log("🚀 ~ onLogin ~ response:", response)
      if (response.data?.success === true) {
        disPatch(login(response.data?.transformedClient));
        window.location.href = "/";
      } else {
        toast.error(response.data?.error);
        setErrorMessage(response.data?.error);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response?.data?.error);
      } else if (error.request) {
        setErrorMessage("No response from server");
      } else {
        setErrorMessage("An error occurred");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setIsSidebar(false); 
    return () => setIsSidebar(true); 
  }, [setIsSidebar]);

  
  return (
    <>
    <div className="fixed left-0 top-0 z-10  dark:bg-black dark:bg-opacity-20  w-screen h-screen flex justify-center items-center ">
      <div className="bg-white w-[30%] rounded shadow-md ">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-bold text-2xl text-graydark dark:text-black text-center ">
            Admin Login
          </h3>
        </div>
        <form onSubmit={onLogin}>
          <div className="p-6">
            <div className="mb-4">
              <label className="mb-3 block text-black dark:text-white">
                ID
              </label>
              <input
                type="text"
                name="userId"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="아이디를 입력하세요."
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="mb-4">
              <label className="mb-3 block text-black dark:text-white">
                Password
              </label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="비밀번호를 입력하세요."
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
        
            <button
              type="submit"
              className="flex w-full justify-center rounded bg-primary p-3 font-bold text-lg text-gray dark:text-white "
            >
              로그인
            </button>
          </div>
        </form>
      </div>
    </div>
  </>
  );
};

export default ClientLogin;