import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { memo, useState } from "react";

const TeamMemberInfo = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    companyName: "",
    companyCode: "",
    daepyoID: "",
    passwor: "",
    ceoNam: "",
    companyNumbe: "",
    corporationNumbe: "",
    Sector: "",
    busines: "",
    phoneNumbe: "",
    faxNumbe: "",
    zipCod: "",
    addres: "",
    addressDetai: "",
    homePage: "",
  });

  const handleChangeState = (e) => {
    console.log(`${e.target.name}:${e.target.value}`);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {};
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">거래처</Row>

        <Row className=" my-5">
          <Col className="text-start ">
            <Button
              variant="outline-secondary"
              onClick={() => {
                navigate("/partnerView");
              }}
              className="px-4 mx-2"
            >
              거래처정보
            </Button>{" "}
            <Button variant="outline-secondary" active className="px-4 mx-2">
              배송지정보
            </Button>{" "}
            <Button
              variant="outline-secondary"
              onClick={() => {
                navigate("/departmentInfo");
              }}
              className="px-4 mx-2"
            >
              부서정보
            </Button>{" "}
          </Col>
        </Row>

        <Row className=" my-5 mx-1">
          <Col className="text-satrt " sm="9">
            <Button variant="light"  onClick={()=>{navigate("/memberView")}}  className="px-4 ">
              마케팅1팀
            </Button>{" "}
            <Button variant="light" className="px-4 mx-2">
              마케팅2팀
            </Button>{" "}
            <Button variant="light" className="px-4 mx-2">
              마케팅3팀
            </Button>{" "}
            <Button variant="light" className="px-4 mx-2">
              영업팀
            </Button>{" "}
          </Col>
          <Col className="text-end ">
            <Button variant="primary" className="px-4 ">
              부서추가
            </Button>
          </Col>
        </Row>

        <Row className=" m-3">
          <Col className="text-start mx-0">
            <Row className="fs-6 fw-bold">(주)아반소프트</Row>
            <Row>팀원정보 | 제품정보</Row>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="text-center">번호</th>
              <th className="text-center">주소</th>
              <th className="text-center">주소코드</th>
              <th className="text-center">관리</th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:  */}
            {[1, 2, 3, 4, 5, 6, 7].map((it, idx) => (
              <tr
                key={idx}
                className="text-center "
                style={{ verticalAlign: "middle" }}
              >
                <td>{idx}</td>
                <td>[11111] 서울특별시 강남구 테헤란로11 11층</td>
                <td>12345</td>
                <td>
                  {" "}
                  <Button
                    variant="primary"
                    className="px-3 mx-3"
                    onClick={() => {
                      navigate("/ShippingInfomodify");
                    }}
                    size="sm"
                  >
                    수정
                  </Button>{" "}
                  <Button variant="secondary" className="px-3" size="sm">
                    삭제
                  </Button>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Row className=" mt-5">
          
          <Col className="text-end ">
            <Button variant="outline-secondary" className="px-4 mx-3">
              목 록
            </Button>{" "}
            <Button variant="primary" onClick={()=>{navigate("/memberRegister")}} className="px-4 ">
              팀원추가
            </Button>{" "}
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(TeamMemberInfo) ;
