import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { getAllPopupFunc, updatePopupOrderBy } from "../../api";
import { toast } from "react-toastify";
import Pagenation from "../../components/Pagenation";

const TypePopup = {
  1: '이미지',
  2: '제목 + 내용',
  3: '이미지 + 제목'
};

const Popup = () => {
  const navigate = useNavigate();

  const [popup, setPopup] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [popups, setPopups] = useState({});

  const handleInputChange = (id, value) => {
    setPopups((prev) => ({ ...prev, [id]: value }));
  }

  useEffect(() => {
    // 전체회사 리스트
    const allPopupGet = async () => {
      const response = await getAllPopupFunc({ page });
      if (response.status === 200) {
        setPopup(response?.data?.data);
        setPageCount(() =>
          response?.data?.Pagination.pageCount
        );
        setTotalCount((totlaCount) => ({
          ...totlaCount,
          total: response?.data?.Pagination.count,
          pageItem: response?.data?.Pagination.pageItem,
        }));
      } else {
        toast.error("부스종류를 가져오는데 실패했습니다.");
      }
    };
    allPopupGet();
  }, [page, popups]);

  const searchForm = {
    title: "팝업등록",
    period: {
      exist: false,
      exposure: 1,
      name: "노출 날짜 검색",
    },
    section: { exist: false },
    state: {
      exist: false,
      titleName: "게시상태",
      type: "radio",
      name: ["사용", "중지"],
    },
    search: { exist: false, title: '메모검색' },
  };

  const handleChangeNumberBy = async (popupId, numberBy) => {
    if (numberBy < 1) {
      toast.error("Enter number!");
      return;
    }

    const response = await updatePopupOrderBy(popupId, { numberBy });
    if (response.status === 200) {
      setPopups({});
      setTimeout(() => {
        // navigate("/departmentInfo/" + id);
      }, 500);
      toast.success("Successfully Update!");
    } else {
      toast.error("거래처 등록에 실패했습니다.!");
    }
  }

  return (
    <>
      <Container className="align-items-center justify-content-center p-5">
        <HeardSearch searchForm={searchForm} />
        <Table bordered hover>
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>제목</th> {/*title*/}
              <th className='text-center'>이미지 PC</th> {/*img PC*/}
              <th className='text-center'>이미지 모바일</th> {/*img MB*/}
              <th className='text-center'>링크</th> {/*link*/}
              <th className='text-center'>노출기간</th> {/*date*/}
              <th className='text-center'>유형</th> {/*type*/}
              <th className='text-center'>상태</th> {/*state*/}
              <th className='text-center'>우선 사항</th> {/*order by*/}
            </tr>
          </thead>
          <tbody>
            {popup.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle", cursor: "pointer" }}

              >
                <td onClick={() => navigate("/popupview/" + item._id)}>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td onClick={() => navigate("/popupview/" + item._id)}>{item.title}</td>
                <td
                  className='flex justify-center'
                  style={{ backgroundColor: "#f1efef" }}
                >
                  <img src={item?.imagePC} width={"100px"} alt='' />{" "}
                </td>
                <td

                  style={{ backgroundColor: "#f1efef" }}
                >
                  <div className='flex justify-center'>
                    <img src={item?.imageMB} width={"100px"} alt='' />{" "}
                  </div>
                </td>
                <td onClick={() => navigate("/popupview/" + item._id)}>{item.link}</td>
                <td onClick={() => navigate("/popupview/" + item._id)}>{item.startDay} ~ {item.expireDay}</td>
                <td onClick={() => navigate("/popupview/" + item._id)}>{item.type ? TypePopup[item.type] : ''}</td>
                <td onClick={() => navigate("/popupview/" + item._id)}><span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-green-800 whitespace-nowrap ${item.state ? 'bg-blue-300' : 'bg-gray-300'}`}>
                  {item.state ? "활성" : "비활성"}</span>
                </td>
                <td >
                  <div className="d-flex">
                    <input
                      type='number'
                      className="order-by-product h-[42px]"
                      value={popups[item._id] !== undefined ? popups[item._id] : (item?.numberBy || "")}
                      min={1}
                      onChange={(e) => {
                        const regex = /^\d+$/;
                        if (!regex.test(e.target.value) && e.target.value !== "") return;
                        handleInputChange(
                          item._id,
                          e.target.value.replace(/^0+/, "")
                        )
                      }
                      }
                      placeholder=''
                    />
                    <button
                      type='button'
                      className='bg-[#0a3cff] px-3 py-2 rounded-md text-white ml-3 whitespace-nowrap'
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleChangeNumberBy(
                          item._id,
                          popups[item._id] !== undefined ? popups[item._id] : (item.numberBy || 0)
                        )
                      }
                    >
                      수정
                    </button>
                  </div>
                </td>
              </tr>
            ))}

          </tbody>
        </Table>

        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          <Col className='text-end '>

            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/popupRegister");
              }}
            >
              팝업등록
            </Button>{" "}
          </Col>
        </Row>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />

      </Container>
    </>
  );
};

export default memo(Popup);
