import "bootstrap/dist/css/bootstrap.min.css";
import CompanyTable from "../../components/CompanyTable";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

const dummyData = [
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"120",
    twelve:"100",
   },
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"100",
    twelve:"20",
   },
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"0",
    twelve:"0",
   },
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"120",
    twelve:"100",
   },
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"80",
    twelve:"60",
   },
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"40",
    twelve:"40",
   },
  {
    one: "",
    two: "(주)아반소프트",
    three: "마케팅1팀",
    four: "11123",
    five: "제품명",
    six: "판촉물",
    seven:"물품명",
    eight:"입고",
    nine:"10",
    ten:"2023.01.01",
    eleven:"20",
    twelve:"20",
   },

 
];


const InventoryList = () => {
  const navigate = useNavigate();
  const handleChangePage = () =>{
    navigate("/inventoryManagementDetail")
  }
  const handleChangeRegPage = () =>{
    navigate("/inventoryManagementRegister")
  }
  const headColumn = [
    "번호",
    "거래처명",
    "부서명",
    "물품코드",
    "제품명",
    "구분",
    "물품명",
    "상태",
    "입고/출고수량",
    "입고/출고날짜",
    "보유재고",
    "출고가능",
  ];
  const myButton = [
    { variant: "outline-danger", name: "상세보기 임시버튼 삭제예정",onClick:handleChangePage },
    { variant: "outline-secondary", name: "엑셀다운로드" },
    { variant: "primary", name: "등록" ,onClick:handleChangeRegPage},
    
  ];

  const searchForm = {
    title: "재고관리",
    period: {
      exist: true,
      name: "기간",
    },
    section: { exist: true },
    state: {
      exist: false,
      titleName: "상태",
      type: "checkbox",
      name: ["접수", "신규", "발송", "취소"],
    },
    search: { exist: true },
  };

  return (
    <>
      <Container className="align-items-center justify-content-center p-5">
        <HeardSearch searchForm={searchForm} />
        <CompanyTable headColumn={headColumn} dummyData={dummyData}  myButton={myButton} />
      </Container>
    </>
  );
};

export default memo(InventoryList);
