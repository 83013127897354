import { memo } from "react";

const Pagenation = ({
    pageCount,
    page,
    setPage,
  }) => {
    // Function to determine which page numbers to show
    const paginationRange = (current, total) => {
      const delta = 2; // Number of pages before and after the current page
      const range = [];
  
      for (
        let i = Math.max(2, current - delta);
        i <= Math.min(total - 1, current + delta);
        i++
      ) {
        range.push(i);
      }
  
      if (current - delta > 2) {
        range.unshift('...');
      }
  
      if (current + delta < total - 1) {
        range.push('...');
      }
  
      range.unshift(1);
      if (total !== 1) range.push(total);
  
      return range;
    };
  
    const range = paginationRange(page, pageCount);

    const handlePrevious = () => {
      setPage(() => {
        if (page === 1) return page;
        return page - 1;
      });
    };
  
    // handle next btn
    const handleNext = () => {
      setPage(() => {
        if (page === pageCount) return page;
        return page + 1;
      });
    };
  
  
    return (
      <div className="mt-4 mb-4 text-center">
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm border border-stroke"
          aria-label="Pagination"
        >
          {pageCount > 0 && (
            <>
              <button
                type="button"
                onClick={() => handlePrevious()}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={page === 1}
              >
                Prev
                {/* Previous Button SVG */}
              </button>
  
              {range.map((pageNum, index) => {
                if (pageNum === '...') {
                  return (
                    <span
                      key={index}
                      className="relative inline-flex items-center px-4 py-2 text-sm text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      ...
                    </span>
                  );
                }
  
                const isActive = page === pageNum;
                return (
                  <button
                    key={index}
                    aria-current={isActive ? 'page' : undefined}
                    onClick={() => setPage(pageNum)}
                    type="button"
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
                      ${isActive ? 'bg-primary text-white' : ''}`}
                  >
                    {pageNum}
                  </button>
                );
              })}
  
              <button
                type="button"
                onClick={() => handleNext()}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={page === pageCount}
              >
                next
                {/* Next Button SVG */}
              </button>
            </>
          )}
        </nav>
      </div>
    );
  };
  
  export default memo(Pagenation);