import * as React from "react";

import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";

export default function CompanyTable({ headColumn, myButton, dummyData ,...props}) {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            {headColumn.map((it, idx) => (
              <th className="text-center" key={idx}>
                {it}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dummyData.map((it,idx) => (
            <tr key={idx} className="text-center align-middle" style={{cursor:'pointer'}} onClick={props?.detailClick?.onClick}>
              <td>{ dummyData.length - idx}</td>
              {it.two ? <td>{it.two}</td>:null}
             
              {it.three ? it.three?.img ? <td><img src={it.three.url} alt=""/></td>:<td>{it.three}</td>:null}
              {it.four ? <td>{it.four}</td>:null}
              {it.five ? <td>{it.five}</td>:null}
              {it.six ? <td>{it.six}</td>:null}
              {it.seven ? <td>{it.seven}</td>:null}
              {it.eight ? it.eight?.img ? <td><img src={it.eight.url} alt=""/></td>:<td>{it.eight}</td>:null}
              {it.nine ? <td>{it.nine}</td>:null}
              {it.ten ? it.ten?.content ? <td style={it.ten.styleColor && {color:`${it.ten.styleColor}`}}>{it.ten.content}</td>:<td>{it.ten}</td>:null}
              {it.eleven ? <td>{it.eleven}</td>:null}
              {it.twelve ? <td>{it.twelve}</td>:null}
              {it.thirteen ? <td>{it.thirteen}</td>:null}
              {it.fourteen ? <td>{it.fourteen}</td>:null}
             
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className="mt-3 mb-3 text-end">
        <Col>
          {myButton.map((it,idx) => (
            <Button variant={it.variant} key={idx} onClick={it.onClick ? it.onClick : null} className="px-4 mx-2">
              {it.name}
            </Button>
          ))}
        </Col>
      </Row>

      <Pagination className="justify-content-center">
        <Pagination.First />
        <Pagination.Prev />
        <Pagination.Item>{1}</Pagination.Item>
        <Pagination.Ellipsis />

        <Pagination.Item>{10}</Pagination.Item>
        <Pagination.Item>{11}</Pagination.Item>
        <Pagination.Item active>{12}</Pagination.Item>
        <Pagination.Item>{13}</Pagination.Item>
        <Pagination.Item disabled>{14}</Pagination.Item>

        <Pagination.Ellipsis />
        <Pagination.Item>{20}</Pagination.Item>
        <Pagination.Next />
        <Pagination.Last />
      </Pagination>
    </>
  );
}
CompanyTable.defaultProps = {
  headColumn: [],
  dummyData:[]
};
