import { Box, IconButton, Menu, Typography, useTheme } from "@mui/material";
import { memo, useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import Avatar from "@mui/material/Avatar";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/authSlice";
import { MenuItem } from "react-pro-sidebar";



const Topbar = ({isSidebar}) => {
  // const theme = useTheme();
  const disPatch = useDispatch()
  // const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  const user = useSelector(state => state.user.user);
const [logoutMenuAnchor, setLogoutMenuAnchor] = useState(null);

const handleOpenLogoutMenu = (event) => {
  setLogoutMenuAnchor(event.currentTarget);
};

const handleCloseLogoutMenu = () => {
  localStorage.removeItem("user");
  disPatch(logout())
  
  setLogoutMenuAnchor(null);
};
  return (
    isSidebar && <Box display="flex" justifyContent="end" alignItems={"center"}  p={2}>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}

      {/* ICONS */}
      {user && (
       <div className="font-bold"> { user?.client?.companyName}</div>
        )}
     <Box display="flex" px={3}>
         {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
            ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>  */}
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <Avatar>
          <IconButton onClick={handleOpenLogoutMenu}>
            <PersonOutlinedIcon />
          </IconButton>
        </Avatar>
        <Menu
          anchorEl={logoutMenuAnchor}
          open={Boolean(logoutMenuAnchor)}
          onClose={()=>{setLogoutMenuAnchor(null)}}
        >
          <MenuItem style={{padding:"0px 10px" }} onClick={handleCloseLogoutMenu}>로그아웃</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default memo(Topbar);
