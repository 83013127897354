import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { getStaffFunc, staffUpdateFunc, staffDeleteFunc, getClientFunc } from "../../../api";
import { toast } from "react-toastify";

const MemberView = () => {
  const { staffId } = useParams()
  const [clientId, setclientId] = useState()
  const navigate = useNavigate();
  const [staff, setStaff] = useState({})
  const [user, setUser] = useState(false)
  const [edit, setEdit] = useState(false);
  const [forms, setForms] = useState([]);
  // 직원정보

  const staffsGet = async (id) => {
    const response = await getStaffFunc(id);
    if (response.status === 200) {
      setStaff(response.data)
      setclientId(response.data.client._id);
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };
  useEffect(() => {
    staffsGet(staffId);
  }, [staffId]);

  const [client, setClient] = useState([]);

  const clientGet = async (did) => {
    const response = await getClientFunc(did);
    if (response.status === 200) {
      setClient(response.data);
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };
  useEffect(() => {
    if (typeof clientId != "undefined") {
      clientGet(clientId);
    }
  }, [clientId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit === false) {
      setEdit(true);
      return;
    }

    const response = await staffUpdateFunc({ ...staff, isWork: staff.isWork ? '근무' : '퇴사' }, staffId);
    if (response.status === 200) {
      toast.success("Successfully Staff Register!");
      navigate("/departmentInfo/" + clientId, { replace: true });
    } else {
      toast.error("ERROR Staff Register!");
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "phone") {
      let value = e.target.value;
      value = value.replace(/\D/g, '');
  
      if(value.length <= 3) {
        value = `${value.slice(0, 3)}`
      } else if (value.length >= 3 && value.length <= 7) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      } else if (value.length >= 8) {
        value = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`;
      }
      setStaff({ ...staff, [name]: value });
    }
    else if (name == 'isWork') {
      // console.log(name,e.target.value,(e.target.value == '근무') );
      setStaff({ ...staff, [e.target.name]: (e.target.value == '근무') });

    }
    else {
      setStaff({ ...staff, [e.target.name]: e.target.value });
    }
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      const response = await staffDeleteFunc(staffId);
      if (response.status === 200) {
        toast.success("Successfully Staff delete!");
        navigate("/departmentInfo/" + clientId, { replace: true });
      } else {
        toast.error("ERROR Staff delete!");
      }
    } else {
      return;
    }
  }
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-2'>{staff?.client?.companyName}</Row>



        <Row className=' mx-1 mb-2 text-xl text-slate-600 font-bold'>1.팀원정보 </Row>

        <Form onSubmit={handleSubmit}>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              부서정보
            </Form.Label>
            {edit
              ? <Col sm='3'>
                <Form.Select
                  aria-label='Default select'
                  name='department'
                  size='sm'
                  onChange={onChange}
                  value={staff.department}
                >
                  {client.department &&
                    client.department.map((it, idx) => (
                      <option key={idx}>
                        {it}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              : <Col sm='6' className=''>
                <Form.Label className='fs-6 fw-bold'>{staff.department}</Form.Label>
              </Col>
            }

          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3 '>
            <Form.Label column sm='2' className="font-bold">
              구분
            </Form.Label>
            {edit ?
              <Col sm='9' className='align-items-center'>
                {["부서장", "팀원", "영업"].map((item, idx) => (
                  <Form.Check
                    key={idx}
                    inline
                    name="position"
                    label={item}
                    value={item}
                    checked={staff?.position === item}
                    onChange={onChange}
                    type="radio"
                  />
                ))}
              </Col> :
              <Col sm='9' className='align-items-center'>

                <Form.Label column sm='2'>
                  {staff?.position}
                </Form.Label>

              </Col>}

          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              담당자명/직급
            </Form.Label>

            {edit ?
              <>
                <Col sm='6'>
                  <Form.Control
                    type='text'
                    name='staffName'
                    value={staff?.staffName}
                    onChange={onChange}
                    placeholder='담당자명'
                    size='sm'
                    required
                  />
                </Col>
                <Col sm='3'>
                  <Form.Control
                    type='text'
                    name='officerPosition'
                    placeholder='직급'
                    value={staff?.officerPosition}
                    onChange={onChange}
                    size='sm'
                    required
                  />
                </Col>
              </>
              :
              <Col sm='6'>
                <Form.Label column sm='6'>
                  {staff.staffName} ({staff.officerPosition})
                </Form.Label>
              </Col>
            }


          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              휴대전화
            </Form.Label>
            {edit ?
              <Col sm='6'>
                <Form.Control
                  id='phone'
                  type='text'
                  name='phone'
                  placeholder='010-0000-0000'
                  value={staff?.phone}
                  onChange={onChange}
                  size='sm'
                  required
                />
              </Col>
              :
              <Col sm='6'>{staff.phone}</Col>}

          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              영업지역
            </Form.Label>
            {edit ?
              <Col sm='9'>
                <Form.Control
                  type='text'
                  name='salesArea'
                  onChange={onChange}
                  placeholder='서울'
                  value={staff?.salesArea}
                  size='sm'
                  required
                />
              </Col>
              : <Col sm='9'>
                <Form.Label column sm='2'>
                  {staff.salesArea}
                </Form.Label>
              </Col>
            }

          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className="font-bold">
              사용여부
            </Form.Label>
            {edit ? <Col sm='9' className='align-items-center'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`}>
                  <Form.Check
                    inline
                    label='근무'
                    value='근무'
                    name='isWork'
                    checked={staff?.isWork || staff?.isWork == '근무'}
                    type={type}
                    id={`inline-${type}-1`}
                    onChange={onChange}
                  />
                  <Form.Check
                    inline
                    label='퇴사'
                    value='퇴사'
                    name='isWork'
                    checked={staff?.isWork == false || (staff?.isWork != '근무' && staff?.isWork != true)}
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={onChange}
                  />
                </div>
              ))}
            </Col> : <Col sm='9' className='align-items-center'>
              <Form.Label column sm='2'>
                {staff.isWork ? "근무" : "퇴직"}
              </Form.Label>
            </Col>}

          </Form.Group>


          <hr />
          {user &&
            <>
              <Row className=' mx-1 mb-2 text-xl text-slate-600 font-bold'>2.물품정보</Row>
              <Row className=' mx-1 mb-2 text-lg text-slate-600'>DM(LC)</Row>
              <Table hover>
                <thead>
                  <tr className="bg-gray-600">
                    <th className='text-center'>번호</th>
                    <th className='text-center'>물품명</th>
                    <th className='text-center'>카테고리</th>
                    <th className='text-center'>물품코드</th>
                    <th className='text-center'>단위</th>
                    <th className='text-center'>비고</th>
                    <th className='text-center w-[200px]'>제한수량</th>
                  </tr>
                </thead>
                <tbody>
                  {[4, 3, 2, 1].map((it) => (
                    <tr className='text-center' style={{ verticalAlign: "middle" }}>
                      <td>{it}</td>
                      <td>뷰센양치키트{it - 1}</td>
                      <td>문헌</td>
                      <td>12345</td>
                      <td>BOX</td>
                      <td className="">물품에 대한 간단한 설명이 노출됩니다.</td>
                      <td className="flex-shrink  gap-3 px-2 justify-end">
                        <input type='text' className="w-[100px] px-2 border-b focus:outline-none mr-2" /><button type="submit" className="border px-2 py-1 rounded bg-red-400 text-white">수량제한</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          }

          <Row className=' mt-5'>
            {/* <Col className='text-start '>
              {
                <Button
                  variant='danger'
                  onClick={handleDelete}
                  className='px-4 '
                >
                  삭제
                </Button>
              }
            </Col> */}
            <Col className='text-end '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => {
                  navigate("/departmentInfo/" + staff.client._id);
                }}
              >
                목 록
              </Button>{" "}
              {edit ? (
                <Button variant='primary' type='submit' className='px-4'>
                  저장
                </Button>
              ) : (
                <Button variant='primary' type='submit' className='px-4'>
                  수정
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(MemberView);
