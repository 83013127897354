import "bootstrap/dist/css/bootstrap.min.css";
import CompanyTable from "../../components/CompanyTable";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { Col, Pagination, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAllIncomingOrderFunc } from "../../api";
import IncomingOrderHead from "./IncomingOrderHead";
import Pagenation from "../../components/Pagenation";
import * as XLSX from 'xlsx';
import {BASE_URL} from "../../api/helper";

const IncomingOrderList = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([])

  // const [orderDate,setOrderDate] = useState({start:getStingDate(new Date()),end:''})
  const [searchStartDay, setSearchStartDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    입고: false,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });


  let trueStates = Object.keys(searchState).filter(key => searchState[key]);
  const searchData = { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, trueStates, sendStartDay, sendEndDay, page }


  const allIncomingOrderGet = async (searchData) => {
    const response = await getAllIncomingOrderFunc(searchData);
    if (response.status === 200) {

      setData(() => response?.data?.data);
      setPageCount(() =>
        response?.data?.Pagination?.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination?.count,
        pageItem: response?.data?.Pagination?.pageItem,
      }));
    } else {
      toast.error("입고신청내역을 가져오는데 실패했습니다.");
    }
  };
  useEffect(() => {
    // 전체 물품 리스트

    allIncomingOrderGet(searchData)
  }, [page]);

  const handleSearchFnc = async (e) => {
    e.preventDefault();
    allIncomingOrderGet(searchData)
    setPage(1);
  }

  const exportToExcel = () => {
    const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,page} = searchData
    const url = `${BASE_URL}/incomingorder/excel?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`;
    const outputFilename = '입고신청관리.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // const data_export = data.map((it, idx) => {
    //   return {
    //     '번호': totlaCount?.total - (page - 1) * totlaCount?.pageItem - idx,
    //     '접수일자': it?.createdAt ? it.createdAt.slice(0, 10) : null,
    //     '거래처명': it?.companyName ? it.companyName : null,
    //     '부서': it?.department ? it.department : null,
    //     '물품명': it?.goodsType ? it.goodsType : null,
    //     '물품수량': it?.goodsQuantity ? it.goodsQuantity : null,
    //     '신청인': it?.staffName ?it.staffName: null,
    //     '입고방식': it?.incomingMethod ? it.incomingMethod: null,
    //     '입고예정일': it?.scheduledDate ? it.scheduledDate : null,
    //     '입고예정시간': it?.scheduledTime ? it.scheduledTime : null,
    //     '상태': it?.state ? it.state : null
    //   };
    // })
    //
    // // 데이터를 워크시트로 변환
    // const worksheet = XLSX.utils.json_to_sheet(data_export);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //
    // // 파일 이름 설정
    // let fileExtension = '.xlsx';
    //
    // // Excel 파일 생성 및 저장
    // XLSX.writeFile(workbook,   '입고신청관리' + fileExtension);
  };

  return (
    <>
      <Container className="align-items-center justify-content-center p-5 w-100 mw-100">

        <IncomingOrderHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}

          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
          search={search} />


        <Table bordered striped hover>
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>접수일자</th>
              <th className='text-center'>거래처명</th>
              <th className='text-center'>부서</th>
              <th className='text-center'>물품명</th>
              <th className='text-center'>물품수량</th>
              <th className='text-center'>신청인</th>
              <th className='text-center'>입고방식</th>
              <th className='text-center'>입고예정일</th>
              <th className='text-center'>입고예정시간</th>
              <th className='text-center'>상태</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/incomingorderview/" + item._id)}
              >
                <td>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td>{item.createdAt.slice(0, 10)}</td>
                <td>{item.companyName}</td>
                <td>{item.department}</td>
                <td>{item.goodsType}</td>
                <td>{item.goodsQuantity}</td>
                <td>{item.staffName}</td>
                <td>{item.incomingMethod}</td>
                <td>{item.scheduledDate}  </td>
                <td>{item.scheduledTime}</td>

                <td>{item.state}</td>

              </tr>
            ))}
          </tbody>
        </Table>


        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          {/* <Col className='text-end '>
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/noticeRegister");
              }}
            >
              공지사항 등록
            </Button>{" "}
          </Col> */}
        </Row>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />

        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <button className='px-4 mx-2 btn btn-outline-secondary' onClick={exportToExcel}>엑셀다운로드</button>
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(IncomingOrderList);
