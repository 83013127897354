import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useState } from "react";
import { tokens } from "../../theme";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";

import { Box, IconButton, Typography, useTheme } from "@mui/material";

const InventoryManagementDetail = () => {
  // state 취득
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState({
    companyName: "",
    companyCode: "",
    daepyoID: "",
    passwor: "",
    ceoNam: "",
    companyNumbe: "",
    corporationNumbe: "",
    Sector: "",
    busines: "",
    phoneNumbe: "",
    faxNumbe: "",
    zipCod: "",
    addres: "",
    addressDetai: "",
    homePage: "",
  });

  const handleChangeState = (e) => {
    console.log(`${e.target.name}:${e.target.value}`);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {};
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">재고관리 상세</Row>
        <Form>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.재고관리
          </Typography>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              거래처명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="2">
                {"(주)아반소프트"}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부서명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"마케팅 1팀"}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              담당자/직급
            </Form.Label>

            <Col sm="7">
              <Form.Label column sm="7" style={{ fontSize: 16 }}>
                {"홍길동 / 대리"}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              제품명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"DM(LC)"}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              구분
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="7" style={{ fontSize: 16 }}>
                {"판촉물"}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              물품명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="7" style={{ fontSize: 16 }}>
                {"뷰센 양치키트4"}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              물품코드
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className=" px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9">
              {["radio"].map((type) => (
                <div key={`inline-${type}`}>
                  <Form.Check
                    inline
                    label="입고"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="출고"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              입고/출고수량
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              입고/출고날짜
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              보유재고
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              출고가능
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              비고
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          {/* 특이사항 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              메모
            </Form.Label>
            <Col sm="9">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Col>
          </Form.Group>
       
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            2.입출고내역
          </Typography>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">입고/출고수량</th>
                <th className="text-center">상태</th>
                <th className="text-center">보유재고</th>
                <th className="text-center">비고</th>
                <th className="text-center">날짜</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td>3</td>
                <td>200</td>
                <td>입고</td>
                <td>200</td>
                <td>디자인티티입고</td>
                <td>2023.01.01</td>
              </tr>
              <tr className="text-center">
                <td>2</td>
                <td>200</td>
                <td>입고</td>
                <td>0</td>
                <td>디자인티티입고</td>
                <td>2023.01.01</td>
              </tr>
              <tr className="text-center">
                <td>1</td>
                <td>200</td>
                <td>입고</td>
                <td>100</td>
                <td>디자인티티입고</td>
                <td>2023.01.01</td>
              </tr>
             
            </tbody>
          </Table>
       
         
          <Row className="mt-5">
            <Col className="text-start ">
             
              <Button variant="outline-secondary" className="px-4 mx-3">
                삭제
              </Button>{" "}
            </Col>
            <Col className="text-end ">
              <Button variant="outline-secondary" className="px-4 mx-3">
                목 록
              </Button>{" "}
              <Button as="input" type="submit" value="저장" className="px-4" />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

InventoryManagementDetail.defaultProps = {
  title: "",
};

export default memo(InventoryManagementDetail);
