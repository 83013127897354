import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { deleteBannerItemFunc, getbannerItemFunc, updateBannerItemFunc } from "../../api";
import { toast } from "react-toastify";

const BannerView = () => {
  const fileInput = useRef();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(null);
  const [forms, setForms] = useState([]);
  const [edit, setEdit] = useState(false);
  const { id } = useParams();

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  const handleDelete = async () => {
    const res = await deleteBannerItemFunc(id);

    if (res.status === 200) {
      setTimeout(() => {
        toast.success("Successfully Delete!");
        navigate("/banner", { replace: true });
      }, 1000);
    } else {
      toast.error("배너 삭제실패!");
    }

    // await axios
    //   .post(process.env.REACT_APP_API_BASE_URL+"/banner/delete/" + id)
    //   .then((res) => navigate("/banner"))
    //   .catch((err) => console.log("error:" + err));
  };


  const handleChangeImage = (e) => {

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      if (fileInput.current) {
        fileInput.current.value = ''; // Reset the file input
        setImgSrc("");
      }
    } else {
      encodeFileToBase64(selectedFile);
    }
  };

  useEffect(() => {
    const BannerItemGet = async (id) => {
      const res = await getbannerItemFunc(id);
      if (res.status === 200) {
        setForms({
          ...forms,
          position: res.data.position,
          memo: res.data.memo,
          startDay: res.data.startDay,
          expireDay: res.data.expireDay,
          link: res.data.link,
          state: res.data.state,
        });
        setImgSrc(res.data.bannerImage);
      } else {
        toast.error("배너정보를 가져오지 못했습니다.");
      }
    };
    BannerItemGet(id);

    // axios
    //   .get(process.env.REACT_APP_API_BASE_URL+"/banner/" + id)
    //   .then((res) => {
    //     setForms({
    //       ...forms,
    //       position: res.data.position,
    //       memo: res.data.memo,
    //       startDay: res.data.startDay,
    //       expireDay: res.data.expireDay,
    //       link: res.data.link,
    //       state: res.data.state,
    //     });
    //     setImgSrc(res.data.bannerImage);
    //     // fileInput.current.value = "";
    //   })
    //   .catch((err) => console.log(err));
  }, [id]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!edit) {
      setEdit(true);
      return;
    }
    const formData = new FormData(e.target);
    // formData.append("staff", checkedInputs);
    // formData.append("client", client._id);
    // formData.append("department", department[part - 1]);

    console.log(Object.fromEntries(formData.entries()));
    // const newFormData = Object.fromEntries(formData.entries());
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const res = await updateBannerItemFunc(id, formData, config);
    if (res.status === 200) {
      navigate("/banner", { replace: true });
    } else {
      toast.error("배너삭제를 하지 못했습니다.");
    }

    // await axios
    //   .put(process.env.REACT_APP_API_BASE_URL+"/banner/" + id, formData)
    //   .then((res) => {
    //     navigate("/banner", { replace: true });

    //   })
    //   .catch((err) => console.log(err));
  };
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>배너뷰</Row>
        <Form onSubmit={handleSubmit}>
          {/* 게시위치 start */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              게시위치
            </Form.Label>
            <Col sm='9' className='align-items-center'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} onChange={onChange}>
                  <Form.Check
                    inline
                    label='상단'
                    defaultChecked={forms.position}
                    name='position'
                    type={type}
                    value={"true"}
                    disabled={!edit}
                  />
                  <Form.Check
                    inline
                    label='하단'
                    defaultChecked={!forms.position}
                    name='position'
                    value={"false"}
                    disabled={!edit}
                    type={type}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          {edit &&
            <Row>
              <Form.Label column sm='2'>
                {""}
              </Form.Label>
              <Col lg='6'>
                <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                  * 5MB를 초과하는 파일은 등록할 수 없습니다.
                </small>
              </Col>
            </Row>}

          {/* 게시위치 end */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2' className=' d-flex flex-column'>
              배너 이미지
            </Form.Label>
            <Col sm='6'>
              <Form.Group controlId='formFile'>
                <Form.Control
                  type='file'
                  size='sm'
                  name='bannerImage'
                  disabled={!edit}
                  ref={fileInput}
                  onChange={handleChangeImage}
                  // value={forms.bannerImage}
                  accept='image/*'
                />
              </Form.Group>
              <small className='mt-2 d-block text-danger text-end'>
                * 최적 사이즈 (375 X 375)
              </small>
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}
          {imgSrc && (
            <Form.Group as={Row} className='mb-3 px-3'>
              <Form.Label column sm='2'></Form.Label>
              <Col sm='9'>
                <img
                  src={imgSrc}
                  alt='banner_image'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              배너메모
            </Form.Label>
            <Col sm='6'>
              <Form.Control
                name='memo'
                disabled={!edit}
                onChange={onChange}
                value={forms.memo || ""}
                type='text'
                size='sm'
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              노출기간
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                type='date'
                disabled={!edit}
                size='sm'
                name='startDay'
                onChange={onChange}
                value={forms.startDay || ""}
                required
              />
            </Col>
            <Col sm='3'>
              <Form.Control
                type='date'
                size='sm'
                disabled={!edit}
                name='expireDay'
                onChange={onChange}
                value={forms.expireDay || ""}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              링크
            </Form.Label>
            <Col sm='6'>
              <Form.Control
                name='link'
                disabled={!edit}
                onChange={onChange}
                value={forms.link || ""}
                type='text'
                size='sm'
                required
              />
            </Col>
          </Form.Group>

          {/* 게시상태 start */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              게시상태
            </Form.Label>
            <Col sm='9' className='align-items-center'>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} onChange={onChange}>
                  <Form.Check
                    inline
                    label='사용'
                    name='state'
                    disabled={!edit}
                    value={"true"}
                    defaultChecked={forms.state}
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label='중지'
                    name='state'
                    disabled={!edit}
                    value={"false"}
                    type={type}
                    defaultChecked={!forms.position}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          {/* 게시상태 end */}

          <Row>

            <Col className='text-start m-5 '>
              <Button
                variant='outline-danger'
                className='px-4 mx-3'
                onClick={handleDelete}
              >
                삭제
              </Button>{" "}
            </Col>

            <Col className='text-end m-5 '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/banner", { replace: true })}
              >
                {edit ? "취소" : "목 록"}
              </Button>{" "}
              <Button
                as='input'
                type='submit'
                value={edit ? "저장" : "수정"}
                className='px-4'
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(BannerView);
