import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState, useRef } from "react";
import { tokens } from "../../theme";
import Table from "react-bootstrap/Table";
import { Typography, useTheme } from "@mui/material";
import { getEventRequestItemFunc, updateEventRequestItemFunc } from "../../api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrintEvent from "../../components/PrintEvent";
import { useReactToPrint } from 'react-to-print';

const EventFormDetail = () => {
  const { id } = useParams()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const componentRef = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState({})
  const [state, setState] = useState("")
  const [oldState, setOldState] = useState("")
  const [preview, setPreview] = useState("")
  const [imgSrc, setImgSrc] = useState("");
  const [installPreview, setInstallPreview] = useState("");
  const [booths, setBooths] = useState([]);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const [imgSrc2, setImgSrc2] = useState("");
  const [installPreview2, setInstallPreview2] = useState("");
  const location = useLocation();
  const isFromEvent = location?.state && location?.state?.isEvent
  const [isImage, setIsImage] = useState(false);
  const [isImageAttachFile, setIsImageAttachFile] = useState(false);
  const [attachFile, setAttachFile] = useState("")
  const [fileName, setFileName] = useState("");

  const [forms, setForms] = useState({
    eventCode: "",
    productName: "",
    eventName: "",
    eventCity: "",
    disassembly: "",
    eventStart: "",
    eventEnd: "",
    eventTime: "",
    eventImage: "",
    guestBook: "",
    installer: "",
    manager: "",
    totalPrice: 0,
    pickupDay: "",
    pickupTime: "",
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleChangeImage = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      setImgSrc(null);
      fileInput.current.value = "";
      return;
    }

    setImgSrc(e.target.files[0]);
  };

  const handleChangeImageTwo = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      setImgSrc2(null);
      fileInput2.current.value = "";
      return;
    }

    setImgSrc2(e.target.files[0]);
  };



  // 이미지 뷰어
  useEffect(() => {
    if (imgSrc) {
      setInstallPreview(URL.createObjectURL(imgSrc));
    }
  }, [imgSrc]);

  useEffect(() => {
    if (imgSrc) {
      setInstallPreview2(URL.createObjectURL(imgSrc2));
    }
  }, [imgSrc2]);


  const handleChangeState = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    setForms({
      ...forms,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // 전체 물품 리스트
    const EventRequestItemGet = async (id) => {
      const response = await getEventRequestItemFunc(id);
      if (response.status === 200) {
        console.log("response.data?.eventEnd", response.data?.eventEnd)
        setData(response.data)

        setForms({
          ...forms, eventCode: response.data.eventCode || "",
          productName: response.data.productId?.productName,
          eventName: response.data?.eventName,
          eventLocation: response.data?.eventLocation,
          eventCity: response.data?.eventCity,
          disassembly: response.data?.disassembly,
          eventStart: response.data?.eventStart ? response.data?.eventStart.split("T")[0] : "",
          eventEnd: response.data?.eventEnd ? response.data?.eventEnd.split("T")[0] : "",
          eventTime: response.data?.eventTime,
          eventImage: "",
          guestBook: response.data?.guestBook,
          installer: response.data?.installer || "",
          manager: response.data?.manager || "",
          totalPrice: response.data?.totalPrice || 0,
          pickupDay: response.data?.pickupDay ? response.data?.pickupDay.split("T")[0] : "",
          pickupTime: response.data?.pickupTime,
          directions: response.data?.directions,
          memo: result?.memo,
          InstallationPhoto: response.data?.InstallationPhoto,
          InstallationPhoto2: response.data?.InstallationPhoto2,
          attachFile: ""
        })
        setBooths(response.data?.booths)
        setPreview(response.data?.eventImage)
        setAttachFile(response.data?.attachFile)
        setState(response.data?.eventState)
        setOldState(response.data?.eventState)
        setInstallPreview(response.data?.InstallationPhoto)
        setInstallPreview2(response.data?.InstallationPhoto2);

        const attachFile = response?.data?.attachFile
        let attachFileName = "";
        if (attachFile) {
          attachFileName = decodeURI(
            attachFile.split("/")[attachFile?.split("/").length - 1]
          );
          attachFileName = attachFileName.split("-").pop();
          setFileName(attachFileName);
        }

      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }
    };
    const result = EventRequestItemGet(id)


  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await updateEventRequestItemFunc(id, formData, config);
    if (response.status === 200) {
      toast.success("Successfully Register!");
      if (isFromEvent) {
        setTimeout(() => {
          navigate("/eventform", { replace: true, state: { backPage: true } });
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/eventReport", { replace: true, state: { backPage: true } });
        }, 1000);
      }
    } else {
      toast.error("행사요청 업데이트에 실패했습니다.!");
    }
  };

  useEffect(() => {
    if (attachFile) {
      checkFileType(attachFile, 'attachFile');
    }
  }, [attachFile]);

  useEffect(() => {
    if (preview) {
      checkFileType(preview, 'eventImage');
    }
  }, [preview]);

  const checkFileType = (url, type) => {
    const extension = url.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    if(type === "attachFile") {
      setIsImageAttachFile(imageExtensions.includes(extension))
    }
    if(type === "eventImage") {
      setIsImage(imageExtensions.includes(extension));
    }
  };

  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">
          행사 접수 신청서
        </Row>
        <Form onSubmit={handleSubmit}>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.부스 및 행사정보
          </Typography>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              코드
            </Form.Label>
            <Col sm="3">
              <Form.Control
                name="eventCode"
                value={forms.eventCode}
                onChange={handleChangeState}
                type="text"
                size="sm"
                required
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {forms.productName}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사명칭
            </Form.Label>

            <Col sm="3">
              <Form.Control
                type="text"
                name="eventName"
                onChange={handleChangeState}
                value={forms.eventName}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부스
            </Form.Label>
            <Col sm="9">
              {
                !!booths?.length && booths?.map((item, idx) => (
                  <Form.Label key={idx} column sm="9" style={{ fontSize: 16 }}>
                    {`${item?.boothId?.boothName} ${item?.count}개 / ${item.acronyms}/${item.boothTypeName}`}
                  </Form.Label>
                ))
              }
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사지역
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="eventCity"
                onChange={handleChangeState}
                value={forms?.eventCity}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사장소
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="eventLocation"
                onChange={handleChangeState}
                value={forms?.eventLocation}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              설치/해제시간
            </Form.Label>
            <Col sm="4">
              <Form.Control
                type="text"
                name="disassembly"
                onChange={handleChangeState}
                value={forms.disassembly}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3 flex items-center">
            <Form.Label column sm="2">
              행사일자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                onChange={handleChangeState}
                name='eventStart'
                value={forms.eventStart}
                size="sm"
              />
            </Col>
            ~
            <Col sm="3">
              <Form.Control
                type="date"
                name="eventEnd"
                onChange={handleChangeState}
                value={forms.eventEnd}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사시간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="eventTime"
                onChange={handleChangeState}
                value={forms.eventTime}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부스배치도
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile" >
                {preview ? (
                  <div className="flex items-center">
                    {isImage ? <img src={preview} width={"300px"} alt="" /> :
                      <a href={preview} download target='_blank' style={{textDecoration: "none"}}>
                        {forms.eventName} 배치도
                      </a>}
                    <span onClick={() => setPreview("")} className="cursor-pointer ml-3 inline-flex items-center rounded-md bg-red-50 px-4 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">삭제</span>
                  </div>) : (
                  <Form.Control type="file" size="sm" name="eventImage" />)}
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              첨부파일
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile" >
                {attachFile ? (
                  <div className="flex items-center">
                    {isImageAttachFile ? <img src={attachFile} width={"300px"} alt="" /> :
                      <a href={attachFile} download target='_blank' style={{textDecoration: "none"}}>
                        {fileName}
                      </a>}
                    <span onClick={() => setAttachFile("")} className="cursor-pointer ml-3 inline-flex items-center rounded-md bg-red-50 px-4 py-2 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">삭제</span>
                  </div>) : (
                  <Form.Control type="file" size="sm" name="attachFile" />)}
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9">

              {["신규", "접수", "수정", "완료", "취소", "취소완료"].map((item, idx) => (
                <Form.Check
                  key={`${item}-${idx}`}
                  inline
                  label={item}
                  value={item}
                  onChange={(e) => setState(e.target.value)}
                  name="eventState"
                  type="radio"
                  checked={state == item}
                  disabled={["취소", "취소완료"].includes(oldState) && !["취소", "취소완료"].includes(item)}
                />
              ))}

            </Col>
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            2.추가신청정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3 flex items-center">
            <Form.Label column sm="2">
              방명록
            </Form.Label>

            <Col sm="2">
              <Form.Control
                type="text"
                className="text-center"
                name="guestBook"
                value={forms.guestBook}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
            <Col>개</Col>
          </Form.Group>
          {/* 전화번호 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="3">
                {data ? data?.staff?.phone : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 배송방식 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="10">
              <Form.Label column sm="3" style={{ width: "100%", display: "block", wordBreak: "break-all", whiteSpace: "break-spaces" }}>
                {data ? data.memo : "특이사항 없음"}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 주소코드 */}
          {/*<Form.Group as={Row} className="mb-3 px-3">*/}
          {/*  <Form.Label column sm="2">*/}
          {/*    물품 픽업 일시*/}
          {/*  </Form.Label>*/}
          {/*  <Col sm="3">*/}
          {/*    <Form.Control*/}
          {/*      type="date"*/}
          {/*      name="pickupDay"*/}
          {/*      onChange={handleChangeState}*/}
          {/*      value={forms.pickupDay}*/}
          {/*      size="sm"*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*  <Col sm="3">*/}
          {/*    <Form.Control*/}
          {/*      type="text"*/}
          {/*      name="pickupTime"*/}
          {/*      onChange={handleChangeState}*/}
          {/*      value={forms.pickupTime}*/}
          {/*      size="sm"*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Form.Group>*/}


          {/* 특이사항 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              지시사항
            </Form.Label>
            <Col sm="10">
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" name="directions" value={forms.directions} onChange={handleChangeState} rows={3} />
              </Form.Group>

            </Col>
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            3.행사신청품목
          </Typography>

          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">물품명</th>
                <th className="text-center">품목</th>
                <th className="text-center">구분</th>
                <th className="text-center">주문수량</th>
              </tr>
            </thead>
            <tbody>
              {data && data?.goods?.map((item, idx) => {
                return (item?.amount > 0 && <tr key={item._id} className="text-center">
                  <td>{data?.goods?.length - idx}</td>
                  <td>{item?.goodsId?.goodsName}</td>
                  <td>{item?.goodsId?.productName}</td>
                  <td>{item?.goodsId?.category}</td>
                  <td>{item?.amount} ({item?.goodsId?.unit})</td>
                </tr>)})}

            </tbody>
          </Table>

          {/* 주문자정보 */}
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            4.주문자정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              거래처명
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.client?.companyName : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {forms?.productName}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주문부서
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.department : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              신청인
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.staffName : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="3">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data ? data?.staff?.phone : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="10">
              <Form.Label column sm="10" style={{ fontSize: 16, width: "100%", display: "block", wordBreak: "break-all", whiteSpace: "break-spaces" }}>
                {data ? data?.memo : ""}
              </Form.Label>
            </Col>
          </Form.Group>

          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            5.관리자정보
          </Typography>
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="pt-0">
              행사설치사진 1
            </Form.Label>
            <Col sm="9" >
              {/* 이미지 프리뷰 */}
              {installPreview && (
                <Form.Group as={Row} className='mb-3 px-0'>
                  <img
                    src={installPreview}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Form.Group>
              )}
              <Form.Group as={Row} className='px-0'>
                <Col sm="4">
                  <Form.Control ref={fileInput} type="file" size="sm" name="InstallationPhoto" onChange={handleChangeImage} />
                </Col>
              </Form.Group>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="pt-0">
              행사설치사진 2
            </Form.Label>
            <Col sm="9" >
              {/* 이미지 프리뷰 */}
              {installPreview2 && (
                <Form.Group as={Row} className='mb-3 px-0'>
                  <img
                    src={installPreview2}
                    alt='img preview'
                    style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                  />
                </Form.Group>
              )}
              <Form.Group as={Row} className='px-0'>
                <Col sm="4">
                  <Form.Control ref={fileInput2} type="file" size="sm" name="InstallationPhoto2" onChange={handleChangeImageTwo} />
                </Col>
              </Form.Group>
            </Col>
          </Form.Group>

          {/* 박스 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              설치자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="installer"
                // placeholder="대"
                value={forms.installer || ""}
                onChange={handleChangeState}
                size="sm"
              />

            </Col>
          </Form.Group>
          {/* 택배 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              관리자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="manager"
                // placeholder="대"
                value={forms.manager || ""}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              총금액
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="number"
                name="totalPrice"
                // placeholder="대"
                value={forms.totalPrice || ""}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          <hr />
          <Row className="mt-5">
            <Col className="text-start ">
              {/* <Button variant="primary" className="px-4 mx-3">
               엑셀다운로드
              </Button>{" "} */}
              {/* <Button variant="outline-danger" type="button" className="px-4 mx-3">
                삭제
              </Button>{" "} */}

            </Col>
            <Col className="text-end ">
              <div style={{ display: "none" }} >
                <PrintEvent ref={componentRef} />
              </div>
              <Button variant="outline-secondary" type="button" className="px-4 mx-3" onClick={() => {
                if (isFromEvent) {
                  navigate("/eventform", { state: { backPage: true } })
                  // location.state = null;
                } else {
                  navigate("/eventReport", { state: { backPage: true } })
                  // location.state = null;
                }
              }}>
                목 록
              </Button>{" "}
              <Button className="px-4 mx-3" onClick={handlePrint}>인쇄</Button>
              {/* {data.eventState !== "취소" &&  */}
              <Button as="input" type="submit" value="저장" className="px-4 " />
              {/* } */}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};



export default memo(EventFormDetail);
