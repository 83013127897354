import React, { memo, useState } from "react";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import defaultImage from "../../../assets/default_image.png"
import { toast } from "react-toastify";
import { updateProductOrderBy } from "../../../api";

const ProductTable = ({ selectFilterProduct, navigate, id, setLoadProduct }) => {
  const [products, setProducts] = useState({})

  const handleInputChange = (id, value) => {

    setProducts((prev) => ({ ...prev, [id]: value }));
  }

  const handleChangeOrderBy = async(productId, orderBy) => {
    if(orderBy < 1) {
      toast.error("Enter number!");
      return;
    }

    const response = await updateProductOrderBy(productId, { orderBy });
    if (response.status === 200) {
      setLoadProduct(true);
      setProducts({});
      setTimeout(() => {
        navigate("/departmentInfo/" + id);
      }, 500);
      toast.success("Successfully Update!");
    } else {
      toast.error("거래처 등록에 실패했습니다.!");
    }
  }

  const handleShowDetail = (itemId) => {
    navigate("/productview/" + id + "/" + itemId)
  }

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th className='text-center'>번호</th>
            <th className='text-center'>제품명</th>
            <th className='text-center'>제품코드</th>
            <th className='text-center'>제품이미지</th>
            <th className='text-center'>담당자명/직급</th>
            <th className='text-center'>우선 사항</th>
          </tr>
        </thead>
        <tbody>
          {selectFilterProduct.map((item, idx) => (
            <tr
              key={item._id}
              className='text-center'
              style={{ verticalAlign: "middle", cursor: "pointer" }}
            >
              <td onClick={() => handleShowDetail(item._id)}>{selectFilterProduct.length - idx}</td>
              <td onClick={() => handleShowDetail(item._id)}>{item.productName}</td>
              <td onClick={() => handleShowDetail(item._id)}>{item.productCode}</td>
              <td
                className='flex justify-center'
                style={{ backgroundColor: "#f1efef" }}
                onClick={() => handleShowDetail(item._id)}
              >
                {console.log('item?.productImage', item?.productImage, typeof item?.productImage)}
                {(typeof item?.productImage != "undefined")
                  ? <img src={item?.productImage} width={"100px"} alt='' />
                  : <div style={{ height: '57px' }}></div>
                }
              </td>
              <td onClick={() => handleShowDetail(item._id)}>
                {(item.staff?.isWork) ?
                  <>
                    {item?.staff?.staffName}{" "}
                    <small>({item?.staff?.position})</small>
                  </> : <>{""}</>}
              </td>
              <td>
                <input
                  type='number'
                  className="order-by-product h-[42px]"
                  value={products[item._id] !== undefined ? products[item._id] : (item?.orderBy || "")}
                  min={1}
                  onChange={(e) => {
                    const regex = /^\d+$/;
                    if (!regex.test(e.target.value) && e.target.value !== "") return;
                    handleInputChange(
                      item._id,
                      e.target.value.replace(/^0+/, "")
                    )
                  }
                  }
                placeholder=''
                />
                <button
                  type='button'
                  className='bg-[#0a3cff] px-3 py-2 rounded-md text-white ml-3'
                  style={{cursor: "pointer"}}
                  onClick={() =>
                    handleChangeOrderBy(
                      item._id,
                      products[item._id] !== undefined ? products[item._id] :  (item.orderBy || 0)
                    )
                  }
                >
                  수정
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className=' m-3'>
        <Col className='text-start '>
          {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
        </Col>
        <Col className='text-end '>
          <Button
            variant='outline-secondary'
            className='px-4 mx-3'
            type='button'
            onClick={() => {
              navigate("/partnerstable/");
            }}
          >
            목 록
          </Button>
          <Button
            variant='primary'
            type='button'
            className='px-4 '
            onClick={() => {
              navigate("/productRegister/" + id);
            }}
          >
            제품추가
          </Button>
        </Col>
      </Row>

    </>
  );
};

export default memo(ProductTable)
