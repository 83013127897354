import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { getAllEventRequestFuncByMonth } from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { useState, useRef, useEffect } from "react";
import ModalBasic from "../../components/ModalBasic";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import Clipboard from 'clipboard';
import {BASE_URL} from "../../api/helper";

const DistribTable = () => {
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [dummyData, setDummyData] = useState([]);
  console.log("🚀 ~ DistribTable ~ dummyData:", dummyData)
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(null);
  const [firstCheckedIndex, setFirstCheckedIndex] = useState(null);
  const [lastCheckedIndex, setLastCheckedIndex] = useState(null);

  // 현재 달의 첫째 날과 마지막 날을 계산하는 함수
  const getFirstAndLastDay = (date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { firstDay, lastDay };
  };
  // API 호출을 통해 이벤트 데이터 가져오기
  const allEventRequestGet = async () => {
    const { firstDay, lastDay } = getFirstAndLastDay(currentMonth);
    const response = await getAllEventRequestFuncByMonth({
      year: currentMonth.getFullYear(),
      month: currentMonth.getMonth() + 1,
    });
    if (response.status === 200) {
      setDummyData(response.data);
    } else {
      toast.error('이벤트 정보를 가져오는 데 실패했습니다.');
    }
  };

  useEffect(() => {
    allEventRequestGet();
  }, [currentMonth]);

  // 이전 달, 다음 달로 이동
  const changeMonth = (increment) => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + increment, 1));
  };

  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

  // 달력 날짜 생성
  const buildCalendarDays = () => {
    let days = [];
    const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day));
    }
    return days;
  };

  const calendarDays = buildCalendarDays();

  const [checkAll, setCheckAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    if (checkboxes.length === 0) {
      setCheckboxes(calendarDays.map(() => false));
    }
  }, [checkboxes, calendarDays]);

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setCheckAll(isChecked);
    setCheckboxes(checkboxes.map(() => isChecked));
    setFirstCheckedIndex(null);
    setLastCheckedIndex(null);
  };

  const handleCheckboxChange = (index, event) => {
    const isChecked = event.target.checked;
    setCheckboxes(prevState => {
      const updatedCheckboxes = [...prevState];
      
      if (isChecked) {
        if (firstCheckedIndex === null) {
          setFirstCheckedIndex(index);
          setLastCheckedIndex(index);
        } else if (index < firstCheckedIndex) {
          setFirstCheckedIndex(index);
        } else if (index > firstCheckedIndex) {
          setLastCheckedIndex(index);
        }
      } else {
        if (firstCheckedIndex !== lastCheckedIndex) {
          if (index === firstCheckedIndex && lastCheckedIndex !== null) {
            setFirstCheckedIndex(index + 1);
            updatedCheckboxes[index] = false;
          }
          if (index === lastCheckedIndex && firstCheckedIndex !== null) {
            setLastCheckedIndex(index - 1);
            updatedCheckboxes[index] = false;
          }

        } else {
          setFirstCheckedIndex(null);
          setLastCheckedIndex(null);
          updatedCheckboxes[index] = false;
        }
      }

      setCheckAll(updatedCheckboxes.every(checkbox => checkbox));

      return updatedCheckboxes;
    });
  };


  useEffect(() => {
    if (firstCheckedIndex !== null && lastCheckedIndex !== null) {
      setCheckboxes(prevState => {
        const updatedCheckboxes = [...prevState];
        const start = Math.min(firstCheckedIndex, lastCheckedIndex);
        const end = Math.max(firstCheckedIndex, lastCheckedIndex);

        for (let i = start; i <= end; i++) {
          updatedCheckboxes[i] = true;
        }
        
        setCheckAll(updatedCheckboxes.every(checkbox => checkbox));

        return updatedCheckboxes;
      });
    }
  }, [firstCheckedIndex, lastCheckedIndex]);

  const exportToExcel = () => {
    const year = currentMonth.getFullYear().toString();
    const month = (currentMonth.getMonth() + 1).toString();
    const startDate = firstCheckedIndex ? firstCheckedIndex + 1 : '';
    const endDate = lastCheckedIndex ? lastCheckedIndex + 1 : '';

    const url = `${BASE_URL}/event/eventrequest/excel3?month=${encodeURIComponent(month)}&year=${encodeURIComponent(year)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
    const outputFilename = '행사일정표.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // const data_export = calendarDays.map((day, index) => {
    //   const dayEvents = dummyData.filter(event => {
    //     const eventDay = new Date(day);
    //     const eventDayWithoutTime = new Date(eventDay.getFullYear(), eventDay.getMonth(), eventDay.getDate()).getTime();
    //     const eventStartDate = new Date(event.eventStart);
    //     const eventStartDateWithoutTime = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate()).getTime();
    //     const eventEndDate = new Date(event.eventEnd);
    //     const eventEndDateWithoutTime = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate()).getTime();

    //     return eventDayWithoutTime >= eventStartDateWithoutTime && eventDayWithoutTime <= eventEndDateWithoutTime
    //   });
    //   return {
    //     '일자': day.getDate(),
    //     '요일': daysOfWeek[day.getDay()],
    //     '내용' : dayEvents.map((event, eventIndex) => {
    //       return event.eventName +'( '+ new Date(event.eventStart).toLocaleDateString() +') '
    //     }).join(' - ')
    //   };
    // })

    // const keys = checkboxes.map((value, index) => {
    //   if (value) {
    //     return index;
    //   }
    // }).filter(value => value !== undefined);

    // let filter_data_export = data_export.filter((obj, index) => keys.includes(index));

    // // 데이터를 워크시트로 변환
    // const worksheet = XLSX.utils.json_to_sheet(filter_data_export);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // // 파일 이름 설정
    // let fileExtension = '.xlsx';

    // // Excel 파일 생성 및 저장
    // XLSX.writeFile(workbook,   '행사일정표' + fileExtension);
  };

  useEffect(() => {
    const clipboard = new Clipboard('.copy-btn');

    clipboard.on('success', handleCopySuccess);

    return () => {
      clipboard.destroy();
    };
  }, []);

  const handleCopySuccess = () => {
    alert('Copy content success!');
  };

  const handleClickEvent = () => {
    console.log('test')
   console.log( document.getElementsByClassName("event-detail-popup"))
  }

  return (
    <>
      <Container className="align-items-center justify-content-center p-5 w-100 mw-100">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-3">행사일정표</Row>
        <Row className="py-3 justify-content-center">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', alignItems: 'center' }} className="fs-5 fw-bold">
            <Button size="sm" onClick={() => changeMonth(-1)}>
              <ArrowBackIosOutlinedIcon />
            </Button>
            <div>
              {currentMonth.getFullYear()}년 {currentMonth.getMonth() + 1}월
            </div>
            <Button size="sm" onClick={() => changeMonth(1)}>
              <ArrowForwardIosOutlinedIcon />
            </Button>
          </div>
        </Row>
        <Row>
          <Col lg="12">
            <Table striped bordered hover className="event-schedule" >
              <thead>
                <tr>
                  <th className="text-center  w-[60px]">
                    선택<br />
                    <Form.Check
                      type="checkbox"
                      checked={checkAll}
                      onChange={handleCheckAllChange}
                    />
                  </th>
                  <th className="text-center w-[60px]">일자</th>
                  <th className="text-center w-[60px]">요일</th>
                  <th className="text-center">내용</th>
                </tr>
              </thead>
              <tbody>
                {calendarDays.map((day, index) => {
                  const dayEvents = dummyData.filter(event => {
                    const eventDay = new Date(day);
                    const eventDayWithoutTime = new Date(eventDay.getFullYear(), eventDay.getMonth(), eventDay.getDate()).getTime();
                    const eventStartDate = new Date(event.eventStart);
                    const eventStartDateWithoutTime = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate()).getTime();
                    const eventEndDate = new Date(event.eventEnd);
                    const eventEndDateWithoutTime = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate()).getTime();

                    // return eventDayWithoutTime === eventStartDateWithoutTime
                    return eventDayWithoutTime >= eventStartDateWithoutTime && eventDayWithoutTime <= eventEndDateWithoutTime
                  });
                  return (
                    <tr key={index} className={(daysOfWeek[day.getDay()] == "토") ? "saturday" : daysOfWeek[day.getDay()] == "일" ? 'sunday' : ''}>
                      <td className="text-center">
                        <Form.Check
                          type="checkbox"
                          checked={checkboxes[index]}
                          onChange={(event) => handleCheckboxChange(index, event)}
                        />
                      </td>
                      <td className="text-center">{day.getDate()}</td>
                      <td className="text-center">{daysOfWeek[day.getDay()]}</td>

                      <td className="text-center">
                        {dayEvents.map((event, eventIndex) => (
                          // <OverlayTrigger
                          //   key={eventIndex}
                          //   placement="bottom"
                          //   trigger='click'
                          //   //trigger={['click', 'hover']}
                          //   overlay={
                          //     // <Tooltip id={`tooltip-${eventIndex}`} >
                          //     //   <strong>{event.eventName}</strong>
                          //     //   <div >
                          //     //     <p><span>거래처: </span>{event.companyName}</p>
                          //     //     <p><span>행사일: </span>{event.eventStart.split("T")[0]}~{event.eventEnd.split("T")[0]}</p>
                          //     //     <p><span>설치/해제: </span>{event.disassembly}</p>
                          //     //     <p><span>픽업: </span>{event.pickupDay.split("T")[0]} / {event.eventTime} </p>
                          //     //     <p><span>거래처: </span>{event.companyName}</p>

                          //     //   </div>
                          //     // </Tooltip>
                          //     <div className="event-detail-popup" onClick={handleClickEvent}>
                          //       <h6>{event.eventName}</h6>
                          //       <button class="btn btn-outline-secondary copy-btn" data-clipboard-target=".event-detail-popup">복사</button>
                          //     <table className="table table-bordered">
                          //       <tbody>
                          //         <tr>
                          //           <th>행사시간</th>
                          //           <td>{event.eventStart.split("T")[0]}~{event.eventEnd.split("T")[0]}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>행사장소</th>
                          //           <td>{event.eventCity}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>신청인</th>
                          //           <td>{event.staffName}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>연락처</th>
                          //           <td>{event.staffPhone}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>참가제품</th>
                          //           <td>{event.productName}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>설치/해제 시간</th>
                          //           <td>{event.disassembly}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>신청부스</th>
                          //           <td>{event.boothId.boothName}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>지시사항</th>
                          //           <td>{event.directions}</td>
                          //         </tr>
                          //         <tr>
                          //           <th>픽업일시</th>
                          //           <td>{event.pickupDay.split("T")[0]} / {event.eventTime}</td>
                          //         </tr>
                          //       </tbody>
                          //     </table>
                          //     </div>
                          //   }
                          // >
                          //   <div style={{ cursor: 'pointer', paddingBottom: '5px' }}>
                          //     {event.eventName} ({new Date(event.eventStart).toLocaleDateString()})
                          //   </div>
                          // </OverlayTrigger>
                          <div style={{ cursor: 'pointer', paddingBottom: '5px' }} className="event-item" key={eventIndex}>
                            <div>{event.eventName} ({new Date(event.eventStart).toLocaleDateString()})</div>
                            <div className={`event-detail-popup event-detail-popup-${index}-${eventIndex}`}>
                              <h6>{event.eventName}</h6>
                              <button className="btn btn-outline-secondary copy-btn" data-clipboard-target={`.event-detail-popup-${index}-${eventIndex}`}>복사</button>
                              <table className="table table-bordered">
                                <tbody>
                                  <tr>
                                    <th>행사시간</th>
                                    <td>{event?.eventStart.split("T")[0]}~{event?.eventEnd.split("T")[0]}</td>
                                  </tr>
                                  <tr>
                                    <th>행사장소</th>
                                    <td>{event?.eventCity}</td>
                                  </tr>
                                  <tr>
                                    <th>신청인</th>
                                    <td>{event?.staffName}</td>
                                  </tr>
                                  <tr>
                                    <th>연락처</th>
                                    <td>{event?.staffPhone}</td>
                                  </tr>
                                  <tr>
                                    <th>참가제품</th>
                                    <td>{event.productName}</td>
                                  </tr>
                                  <tr>
                                    <th>설치/해제 시간</th>
                                    <td>{event?.disassembly}</td>
                                  </tr>
                                  <tr>
                                    <th>신청부스</th>
                                    <td>
                                      {
                                        event?.booths?.map((it, idx) => (
                                          <>
                                            <span key={idx}>{`${it?.boothId?.boothName} ${it.count}개`}</span>
                                            <br />
                                          </>
                                        ))
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>지시사항</th>
                                    <td>{event?.directions}</td>
                                  </tr>
                                  <tr>
                                    <th>픽업일시</th>
                                    <td>{event?.pickupDay != undefined ? event?.pickupDay.split("T")[0] : ''} / {event?.eventTime}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}

                      </td>

                    </tr>
                  );
                })}
              </tbody>
              {/* <div className='mt-3 mb-3 text-end'>
                <button className='px-4 mx-2 btn btn-outline-secondary download-excel' onClick={exportToExcel}>엑셀다운로드</button>
              </div>  */}
            </Table>
          </Col>
        </Row>
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <button className='px-4 mx-2 btn btn-outline-secondary' onClick={exportToExcel}>엑셀다운로드</button>
          </Col>
        </Row>
      </Container>
    </>
  );
};


export default DistribTable;