import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";

import { useDaumPostcodePopup } from "react-daum-postcode";
import { useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { getAddressAccordingId, updateAddressAccordingClient } from "../../../api";
import { toast } from "react-toastify";

const ShippingInfomodify = () => {
  const navigate = useNavigate()
  const { id } = useParams();

  const [state, setState] = useState({
    // `companyName: "",
    // companyCode: "",
    // daepyoID: "",
    // passwor: "",
    // ceoNam: "",
    // companyNumbe: "",
    // corporationNumbe: "",
    // Sector: "",
    // busines: "",
    // phoneNumbe: "",
    // faxNumbe: "",`
    addr1: "",
    addr2: "",
    zipCode: "",
    addrName: "",
  });
  const [client, setClient] = useState(null);
  const [address, setAddress] = useState({});

  const addressAccordingId = async (id) => {
    const response = await getAddressAccordingId(id);
    if (response.status === 200) {
      setAddress(response.data);
      setState({
        ...state,
        addr1: response.data.addr1,
        addr2: response.data.addr2,
        zipCode: response.data.zipCode,
        addrName: response.data.addrName,
      })
      setClient(response.data.client)
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  useEffect(() => {
    addressAccordingId(id);
  }, [id]);

  const handleChangeState = (e) => {
    console.log(`${e.target.name}:${e.target.value}`);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

  const open = useDaumPostcodePopup(scriptUrl);
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let zoneCode = data.zonecode;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setState({
      ...state,
      addr1: fullAddress,
      zipCode: zoneCode
    })
  };

  // 우편번호 찾기 트리거 해줄 함수
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // formData.append("client", address.client);
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await updateAddressAccordingClient(id, formData, config);
    if (response.status === 200) {
      setState({});
      setTimeout(() => {
        navigate("/partnerShippingInfo/" + client);
      }, 500);
      toast.success("Successfully Update!");
    } else {
      toast.error("거래처 등록에 실패했습니다.!");
    }
  };

  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">거래처</Row>

        <Row className=" my-5">
          <Col className="text-start ">
            <Button variant="outline-secondary" onClick={() => { navigate("/partnerView/" + client) }} className="px-4 mx-2">
              거래처정보
            </Button>{" "}
            <Button variant="outline-secondary" active className="px-4 mx-2">
              배송지정보
            </Button>{" "}
            <Button variant="outline-secondary" onClick={() => { navigate("/departmentInfo/" + client) }} className="px-4 mx-2">
              부서 / 제품 정보
            </Button>{" "}
          </Col>
        </Row>

        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주소
            </Form.Label>
            <Col sm="6">
              <Form.Control type="text" name="addr1" value={state.addr1} required size="sm" readOnly placeholder='주소' />
            </Col>
            <Col sm="3">
              <Button variant="primary" size="sm" className="" onClick={handleClick}>우편번호찾기</Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2"></Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="addr2"
                value={state.addr2}
                onChange={handleChangeState}
                size="sm"
                required
                placeholder='상세주소'
              />
            </Col>
            <Col sm="3">
              <Form.Control type="text" name="zipCode" value={state.zipCode} required size="sm" readOnly placeholder='우편번호' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              배송지명
            </Form.Label>
            <Col sm="9">
              <Form.Control type="text" name="addrName" value={state.addrName} required onChange={handleChangeState} size="sm" />
            </Col>
          </Form.Group>
          <Row className=" mt-5">
            <Col className="text-end ">
              <Button
                variant="outline-secondary"
                className="px-4 mx-3"
                onClick={() => {
                  navigate("/partnerShippingInfo/" + client);
                }}
              >
                목 록
              </Button>
              <Button as="input" type="submit" value="저장" className="px-4" />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(ShippingInfomodify);
