import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useState, useRef, useEffect, memo } from "react";
import JoditEditor from "jodit-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {isContentEmpty} from "../../utils"

const DepartmentsBoardRegister = () => {
  // state 취득
  const navigate = useNavigate()
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [forms, setForms] = useState([]);

  const [allClients, setAllClients] = useState([]);
  const [selectClient, setSelectClient] = useState("")
  const [department, setDepartment] = useState([]);

  const fileInput = useRef();

  // 등록업체 가져오기
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/client/")
      .then((res) => {
        setAllClients(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChangeClient = (e) => {
    const clientName = e.target.value;
    setForms({ ...forms, [e.target.name]: e.target.value });

    if (clientName === "default") {
      setDepartment([]);
      return;
    }

    const selectCompany = allClients.find(
      (client) => client.companyName === clientName
    );

    const data = async (id) => {
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "/client/get-client/" + id)
        .then((res) => {
          if (res.data?.department) {
            setDepartment(res.data.department);
          } else {
            setDepartment([]);
          }
        });
    };

    if (selectCompany) {
      data(selectCompany._id);
      setSelectClient(selectCompany._id)
    } else {
      setDepartment([]);
    }
  };

  const onChange = (e) => {

    if (e.target.name === "attachFile") {
      const selectedFile = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (selectedFile?.size > maxSize) {
        toast.error('5MB 이하의 파일을 업로드해주세요.');
        setForms({ ...forms, [e.target.name]: "" });
        return;
      }
    }
  
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isContentEmpty(content)) {
      toast.error("내용 비워두지 마세요.!");
      return;
    }

    if (content === '' || forms.title === '' || forms.clientName === 'default' || forms.department === 'default') {
      return
    }

    const formData = new FormData(e.target);
    formData.append("content", content);
    formData.append("clientId", selectClient);

    await axios
      .post(process.env.REACT_APP_API_BASE_URL + "/dnotice/register", formData)
      .then((res) => {
        setContent("");
        setForms({ ...forms, title: "", attachFile: "", clientName: "", department: "" })
        toast.success("Successfully Register!");
        navigate("/board", { replace: true });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          부서별 게시판 등록
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              거래처명
            </Form.Label>
            <Col sm='3'>
              <Form.Select
                aria-label='Client select '
                onChange={handleChangeClient}
                name='clientName'
                value={forms.clientName}
                size='sm'
                required
              >
                <option value=''>거래처명</option>
                {allClients &&
                  allClients.map((it) => (
                    <option key={it._id} id={it._id} value={it.companyName}>
                      {it.companyName}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부서명
            </Form.Label>
            <Col sm='3'>
              <Form.Select
                aria-label='Department Select'
                name='department'
                onChange={onChange}
                value={forms.department}
                size='sm'
                required
              >
                <option value=''>부서명</option>
                {department &&
                  department.map((it, idx) => (
                    <option key={idx} value={it}>
                      {it}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제목
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='text'
                name='title'
                onChange={onChange}
                value={forms.title || ""}
                size='sm'
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              내용
            </Form.Label>
            <Col sm='12'>
              <JoditEditor
                ref={editor}
                value={content}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}
              />
            </Col>
          </Form.Group>

          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              첨부파일
            </Form.Label>
            <Col sm='10'>
              <Form.Group controlId='formFile'>
                <Form.Control ref={fileInput} type='file' size='sm' name='attachFile' onChange={onChange} value={forms.attachFile || ""} />
              </Form.Group>
            </Col>
          </Form.Group>

          <Row className='mt-5'>
            <Col className='text-end'>
              <Button variant='outline-secondary' className='px-4 mx-3' onClick={() => navigate("/board")}>
                목 록
              </Button>{" "}
              <Button as='input' type='submit' value={'저장'} disabled={forms.title === '' || forms.clientName === 'default' || forms.department === 'default'} className='px-4' />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

DepartmentsBoardRegister.defaultProps = {
  title: "",
};

export default memo(DepartmentsBoardRegister);
