import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAlldeliveryGoodsOrderFunc } from "../../api";
import Pagenation from "../../components/Pagenation";
import ReceivingHead from "./ReceivingHead";
import * as XLSX from 'xlsx';
import { formatDateToYYYYMMDDHHMM } from "../../utils";
import {BASE_URL} from "../../api/helper";
import { KEY_ORDERLIST, ORDER_STATUS } from "../../utils/constant";
import useStoreLocal from "../../hook/useStoreLocal.hook";

const headColumn = [
  "번호",
  "배송",
  "거래처명",
  "부서",
  "제품명",
  "신청인",
  "수취인",
  "수취주소",
  "주문일",
  "상태",
];

// 오늘의날짜
const getStingDate = (date) => {
  return date.toISOString().slice(0, 10);
};


const Receiving = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  // const [orderDate,setOrderDate] = useState({start:getStingDate(new Date()),end:''})
  const [searchStartDay, setSearchStartDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    발송: false,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [searchQuery, setSearchQuery, backPage, setValueBackPage] = useStoreLocal(KEY_ORDERLIST, {});

  let trueStates = Object.keys(searchState).filter(key => searchState[key]);

  const searchData = { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, trueStates, sendStartDay, sendEndDay, page }

  const alldeliveryGoodsOrderGet = async (searchData) => {
    const response = await getAlldeliveryGoodsOrderFunc(searchData);
    if (response.status === 200) {
      setData(() => response?.data?.order);
      setPageCount(() =>
        response?.data?.Pagination?.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  };
  useEffect(() => {
    if (!backPage) {
      alldeliveryGoodsOrderGet(searchData)
    }
    setSearchQuery({ ...searchData, ...searchQuery, page: page })
  }, [page]);

  useEffect(() => {
    if (backPage) {
      const {
        searchStartDay,
        searchEndDay,
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        trueStates,
        sendStartDay,
        sendEndDay,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      searchStartDay && setSearchStartDay(searchStartDay);
      searchEndDay && setSearchEndDay(searchEndDay);
      search && setSearch(search);
      sendStartDay && setSendStartDay(sendStartDay);
      sendEndDay && setSendEndDay(sendEndDay);
      page && setPage(page);

      if (trueStates?.length) {
        const newStates = trueStates.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})

        setSearchState(checkedState => ({ ...checkedState, ...newStates }));
      }

      alldeliveryGoodsOrderGet({ ...searchData, ...searchQuery });
      setValueBackPage(false);
    }
  }, [searchQuery, backPage]);

  const handleSearchFnc = async (e) => {
    e.preventDefault();
    handleSearchChange();
    alldeliveryGoodsOrderGet(searchData)
    setPage(1);
  }

  const handleSearchChange = () => {
    setSearchQuery(searchData);
  };

  const exportToExcel = () => {

    const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,page} = searchData
    const url = `${BASE_URL}/deliveryorder/excel?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`;
    const outputFilename = '물류접수현황.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  let currentColor = "bg-blue-200";

  return (
    <>
      <Container className="align-items-center justify-content-center p-5 m-0 w-100 mw-100">
        <ReceivingHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}

          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
          search={search} />



        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto border w-100 table-fixed">
          <thead className="bg-gray-50">
            <tr>
              {/* {headColumn.map((it, idx) => (<th key={idx} scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center " >{it}</th>))} */}
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/24" >{'상태'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/12" >{'주문일'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-3/24" >{'거래처명'}</th>

              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/12" >{'부서'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/12" >{'제품명'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/24" >{'신청인'}</th>

              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/12" >{'수취인'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-4/12" >{'수취주소'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/24 w-[60px]" >{'번호'}</th>
              <th scope="col" className="py-3 text-sm font-medium text-gray-800 uppercase tracking-wider text-center w-1/24" >{'배송'}</th>

            </tr>
          </thead>
          <tbody className="bg-white  divide-y  divide-gray-200" >
            {data.map((order, index) => {
              if (index > 0 && order.setGroup !== data[index - 1].setGroup) {
                currentColor = currentColor === "bg-blue-200" ? "bg-danger-subtle" : "bg-blue-200";
              }
              return (<tr
                key={order._id}
                className={`${currentColor} text-center cursor-pointer`}
                onClick={() => navigate("/disrecevingform/" + order._id, {state: {isOrder: true}})}
              >
                <td className="mx-6 py-4 whitespace-nowrap">
                  {order?.state && order?.state === "신규" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-green-800">
                    {order.state}
                  </span>) : order?.state === "접수" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {order.state}
                  </span>) : order?.state === "발송" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                    {order.state}
                  </span>) : order?.state === ORDER_STATUS.CANCEL ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-300 text-green-800">
                    {order.state}
                  </span>) : (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-slate-600">
                    {'취완'}
                  </span>)}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.createdAt ? formatDateToYYYYMMDDHHMM(order.createdAt) : ""}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.companyName}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.department}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.productName}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.staffName}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.recipientName}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.addr1} {order?.addr2}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {totlaCount?.total - (page - 1) * totlaCount?.pageItem - index}
                </td>
                <td className="mx-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {order?.combined ? "합배송" : "개별배송"}
                </td>
              </tr>
              )
            })
            }
          </tbody>
        </table>


        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          {/* <Col className='text-end '>
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/noticeRegister");
              }}
            >
              공지사항 등록
            </Button>{" "}
          </Col> */}
        </Row>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <button className='px-4 mx-2 btn btn-outline-secondary' onClick={exportToExcel}>엑셀다운로드</button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Receiving);
