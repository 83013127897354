import { memo, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import { getAllClientsFunc, getAllProductFunc } from "../../api";
import { Button } from "react-bootstrap";
    // 오늘의날짜 
const getStingDate = (date) =>{
  return date.toISOString().slice(0,10)
}
const ContentGalleryHead = ({setSelectClient,selectClient,setSelectDepartment,selectDepartment,setSelectProduct,selectProduct,setSearchStartDay,searchStartDay,setSearchEndDay,searchEndDay,setSearchState,searchState,setSearch,search,handleSearchFnc,selectCatgoris,setSelectCatgoris}) => {
 
  
  // 기간부분 날짜
  const[date,setDate] = useState(getStingDate(new Date()))

  const [getClients,setGetClients]=useState([])
  const[getDepartments,setGetDepartments]=useState([])
  const[getProducts,setGetProducts]=useState([])

useEffect(() => {
  const allClientsGet = async () => {
    const response = await getAllClientsFunc();
    if (response.status === 200) {
      setGetClients(()=>response?.data?.data);
    } else {
      toast.error("거래처 정보를 가져오기 실패");
    }
  };
  allClientsGet()
}, []);

useEffect(() => {
  if(selectClient === "All"){
    setSelectDepartment("All")
    setSelectProduct("All")
  }
}, [selectClient]);

useEffect(() => {
 const mathClient =  getClients.find(client=>client?.companyName === selectClient)

 setGetDepartments(()=>mathClient?.department)
}, [selectClient,getClients]);


useEffect(() => {

  const mathClient =  getClients.find(client=>client?.companyName === selectClient)

  const id = mathClient?._id
  console.log("🚀 ~ useEffect ~ id:", id)
  const productData = async (id) => {
   
    const res = await getAllProductFunc(id);
    if (res.status === 200) {
      setGetProducts(res.data);
    } else {
      toast.error("제품정보 가져오기실패!!");
    }
  };
  
  if(id && selectClient!=="All" && selectDepartment !=="All"){productData(id)}
  if(selectClient==="All"){
    setGetProducts([])
  }
}, [selectDepartment,selectClient]);
   
const handleInputChange = (e) => {
  setSearchState(checkedState=>({...checkedState, [e.target.name]: e.target.checked}));
};
  return (
    <>
      <Row className="fs-1 fw-bold p-2 border-bottom mb-3">
       행사장 갤러리 관리
      </Row>
      
      <Form onSubmit={handleSearchFnc}>
        <Row className="py-3 align-items-center  ">
          <Col xs={2} className="text-center fs-6">
           작성일
          </Col>

          <Col xs={2}>
            <InputGroup>
              <Form.Control
                type="date"
                aria-label="date"
                value={searchStartDay}
                onChange={(e)=>setSearchStartDay(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </Col>
       {"~"}
          <Col xs={2}>
            <InputGroup>
              <Form.Control
                type="date"
                value={searchEndDay}
                onChange={(e)=>setSearchEndDay(e.target.value)}
                // placeholder="yyyy-mm-dd"
                aria-label="date"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
          </Col>
        </Row>
     

      {/* 구분 */}
{/*       
        <Row className=" align-items-center  ">
          <Col xs={2} className="text-center fs-6">
            구분{" "}
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example" value={selectClient} onChange={(e)=>setSelectClient(e.target.value)}>
              <option value="All">거래처선택</option>
              {getClients && getClients.map((client)=>(
                <option key={client._id} value={client.companyName}>{client.companyName}</option>
              ))}
             
            </Form.Select>
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example" value={selectDepartment} onChange={(e)=>setSelectDepartment(e.target.value)}>
              <option value="All">부서선택</option>
              {getDepartments && getDepartments.map((Department,i)=>(
                <option key={i} value={Department}>{Department}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example" value={selectProduct} onChange={(e)=>setSelectProduct(e.target.value)}>
              <option value="All">제품선택</option>
              getProducts
              {getProducts && getProducts.map((product,idx)=>(
                <option key={product._id} value={product.productName}>{product.productName}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={2}>
            <Form.Select aria-label="Default select example" value={selectCatgoris} onChange={(e)=>setSelectCatgoris(e.target.value)}>
              <option value="All">종류</option>
              getProducts
              {["판촉물","브로슈어","문헌","식품류"].map((product,idx)=>(
                <option key={idx} value={product}>{product}</option>
              ))}
            </Form.Select>
          </Col>
       
        </Row> */}
    
      {/* 상태 */}
    
        {/* <Row className="pt-3 align-items-center ">
          <Col xs={2} className="text-center fs-6">
            상태
          </Col>
          <Col xs={6}>
          
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="align-items-center">
                  {["유통기한임박"].map((formName, idx) => (
                    <Form.Check
                      inline
                      key={idx}
                      label={formName}
                      name={formName}
                      type="checkbox"
                      id={`inline-checkbox-${idx}`}
                      checked={searchState[formName]}
                      onChange={handleInputChange}
                    />
                  ))}
                </div>
              ))}
           
          </Col>
        </Row> */}
  
     
        <Row className="align-items-center mb-5 py-3">
          <Col xs={2} className="text-center fs-6" >
           검색
          </Col>
        
            <>
              <Col xs={6}>
              <InputGroup>
                <Form.Control
                  placeholder="검색어를 입력하세요"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={(e)=>setSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col>
             
              <Button variant="primary" type="submit" className="mx-3 px-4">
                검색
              </Button>{" "}
            </Col>
            </>
         
        </Row>
      </Form>
    </>
  );
};


export default memo(ContentGalleryHead);
