import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container,Row ,Col } from "react-bootstrap";
import DashboardList from './DashboardList'


const Dashboard = () => {
  const theme = useTheme();

  
  return (<>
    <Container>  
      <Row >
    <Typography
            variant="h2"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
           대쉬보드
          </Typography>
          <Typography
            variant="h6"
            fontWeight="400"
            sx={{ padding: "5px 0px 0 30px",color:'green'}}
          >
           Welcome to your dashboard
          </Typography>
    </Row>
    <Row>
      <DashboardList/>
    </Row>
    {/* <PrintTable/> */}
    </Container>
   

      
    </>
  );
};

export default Dashboard;
