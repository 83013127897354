import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Form from "react-bootstrap/Form";

import { useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import {
	getClientFunc,
	staffRegisterFunc,
} from "../../../api";
import { toast } from "react-toastify";

const MemberRegister = () => {
	const navigate = useNavigate();
	const { did } = useParams();
	const [client, setClient] = useState([]);
	const [forms, setForms] = useState([]);

	const clientGet = async (id) => {
		const response = await getClientFunc(id);
		if (response.status === 200) {
			setClient(response.data);
		} else {
			toast.error("Client 정보를 가져오지 못했습니다.");
		}
	};
	useEffect(() => {
		clientGet(did);
	}, [did]);

	const onChange = (e) => {
		const { name, value } = e.target;
		/* Format phone number to 000-0000-0000 */
		if (name == "phone") {
			let value = e.target.value;
      value = value.replace(/\D/g, '');
  
      if(value.length <= 3) {
        value = `${value.slice(0, 3)}`
      } else if (value.length >= 3 && value.length <= 7) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      } else if (value.length >= 8) {
        value = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`;
      }
			setForms({ ...forms, [name]: value });
		}else {
			setForms({ ...forms, [name]: value });
		}
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		formData.append("companyName", client?.companyName);
		formData.append("logoImage", client?.logoImage);
		const newFormData = Object.fromEntries(formData.entries());
		// const config = {
		// "Content-Type": "multipart/form-data",
		//   "Content-Type":"application/json",
		// };
		const response = await staffRegisterFunc(did, newFormData);
		if (response.status === 200) {
			toast.success("Successfully Staff Register!");
			// setTimeout(() => {
			//   navigate("/partnerstable", { replace: true });
			// }, 1000);
			setForms({
				...forms,
				staffName: "",
				officerPosition: "",
				phone: "",
				salesArea: "",
			});
			navigate("/departmentInfo/"+ client._id, { replace: true });
		} else {
			if(response?.response?.data?.error_status == 800){
				toast.error(response?.response?.data?.error);
				document.getElementById("phone").focus();
			}else{
				toast.error("직원 등록에 실패했습니다.!");
			}
		}

	};

	return (
		<>
			<Container maxWidth='md' className='py-3'>
				<Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
					거래처 팀원추가
				</Row>

				<Row className=' m-3'>
					<Col className='text-start mx-0'>
						<Row className='fs-6 fw-bold mb-3'>{client.companyName}</Row>
						{/* <Row>팀원정보 | 제품정보</Row> */}
					</Col>
				</Row>

				<Form onSubmit={handleSubmit}>
					<Form.Group as={Row} className='mb-3 px-3'>
						<Form.Label column sm='2'>
							부서정보
						</Form.Label>
						<Col sm='3'>
							<Form.Select
								aria-label='Default select'
								name='department'
								size='sm'
							>
								{client.department &&
									client.department.map((it, idx) => (
										<option key={idx} onChange={onChange}>
											{it}
										</option>
									))}
							</Form.Select>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className='mb-3 px-3'>
						<Form.Label column sm='2'>
							구분
						</Form.Label>
						<Col sm='9' className='align-items-center'>
							{["radio"].map((type) => (
								<div key={`inline-${type}`}>
									<Form.Check
										inline
										label='부서장'
										value='부서장'
										name='position'
										defaultChecked
										type={type}
									/>
									<Form.Check
										inline
										label='팀원'
										value='팀원'
										name='position'
										type={type}
									/>
									<Form.Check
										inline
										label='영업'
										name='position'
										value='영업'
										type={type}
									/>
								</div>
							))}
						</Col>
					</Form.Group>

					<Form.Group as={Row} className='mb-3 px-3'>
						<Form.Label column sm='2'>
							담당자명/직급
						</Form.Label>
						<Col sm='6'>
							<Form.Control
								type='text'
								name='staffName'
								value={forms?.staffName}
								onChange={onChange}
								placeholder='담당자명'
								size='sm'
								required
							/>
						</Col>
						<Col sm='3'>
							<Form.Control
								type='text'
								name='officerPosition'
								placeholder='직급'
								value={forms?.officerPosition}
								onChange={onChange}
								size='sm'
								required
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className='mb-3 px-3'>
						<Form.Label column sm='2'>
							휴대전화
						</Form.Label>
						<Col sm='6'>
							<Form.Control
								id='phone'
								type='text'
								name='phone'
								placeholder='010-0000-0000'
								value={forms?.phone}
								onChange={onChange}
								size='sm'
								required
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className='mb-3 px-3'>
						<Form.Label column sm='2'>
							영업지역
						</Form.Label>
						<Col sm='6'>
							<Form.Control
								type='text'
								name='salesArea'
								onChange={onChange}
								placeholder='서울'
								value={forms?.salesArea}
								size='sm'
								required
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className='mb-3 px-3'>
						<Form.Label column sm='2'>
							사용여부
						</Form.Label>
						<Col sm='9' className='align-items-center'>
							{["radio"].map((type) => (
								<div key={`inline-${type}`}>
									<Form.Check
										inline
										label='근무'
										value='근무'
										name='isWork'
										defaultChecked
										type={type}
										id={`inline-${type}-1`}
									/>
									<Form.Check
										inline
										label='퇴사'
										value='퇴사'
										name='isWork'
										type={type}
										id={`inline-${type}-2`}
									/>
								</div>
							))}
						</Col>
					</Form.Group>

					<Row className=' mt-5'>
						<Col className='text-end '>
							<Button
								variant='outline-secondary'
								className='px-4 mx-3'
								onClick={() => {
									navigate("/departmentInfo/" + did);
								}}
							>
								목 록
							</Button>{" "}
							<Button variant='primary' type='submit' className='px-4 '>
								저장
							</Button>{" "}
						</Col>
					</Row>
				</Form>
			</Container>
		</>
	);
};

export default memo(MemberRegister);
