import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { getAllGoodsFunc } from "../../api";
import { toast } from "react-toastify";
import ManageMentHead from "./ManageMentHead";
import Pagenation from "../../components/Pagenation";
import * as XLSX from 'xlsx';
// stylesheet
import "@easonlin0716/js-modal/dist/js-modal.min.css";
// ES module
import modal from "@easonlin0716/js-modal";
import {BASE_URL} from "../../api/helper";

const ManageMent = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  console.log("🚀 ~ Receiving ~ data:", data)

  // const [orderDate,setOrderDate] = useState({start:getStingDate(new Date()),end:''})
  const [searchStartDay, setSearchStartDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [selectCatgoris, setSelectCatgoris] = useState("All")
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    발송: false,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState(null);
  const [backPage, setBackPage] = useState(false);

  if (location.state) {
    const savedResults = localStorage.getItem('searchQueryManagement');
    if(location.state?.backPage && savedResults ) {
      setSearchQuery(JSON.parse(savedResults));
      setBackPage(true);
    }
    location.state = null
  }

  const changePageHandle = () => {
    navigate("/manageMentForm");
  };

  const headColumn = [
    "번호",
    "등록일자",
    "거래처명",
    "부서",
    "담당자",
    "제품명",
    "종류",
    "물품사진",
    "물품명",
    "유통기한",
    "보유재고",

  ];
  const exportToExcel = () => {
    const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,selectCatgoris,page} = searchData
    const url = `${BASE_URL}/goods/excel?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&catgoris=${encodeURIComponent(selectCatgoris)}`;
    const outputFilename = '입고신청관리.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };
  const myButton = [
    { variant: "outline-secondary", name: "엑셀다운로드", onClick: exportToExcel },
    { variant: "primary", name: "물품등록", onClick: changePageHandle },
  ];

  function expirationDate(params) {
    // 유통기한 날짜를 변수에 할당 (예시로 현재 날짜로 설정)
    const expirationDate = new Date(params);
    const sixMonthsLater = new Date();
    sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

    if (expirationDate > sixMonthsLater) {
      return true;
    } else {
      return false;
    }
  }

  let trueStates = Object.keys(searchState).filter(key => searchState[key]);

  const searchData = { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, trueStates, sendStartDay, sendEndDay, selectCatgoris, page }

  // 전체 물품 리스트
  const allGoodsGet = async (searchData) => {
    const response = await getAllGoodsFunc(searchData);
    if (response.status === 200) {

      setData(() => response?.data?.goodsWithLogs
      );
      setPageCount(() =>
        response?.data?.Pagination.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    if(backPage) {
      const { 
        searchStartDay,
        searchEndDay,
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        trueStates,
        sendStartDay,
        sendEndDay,
        selectCatgoris,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      searchStartDay && setSearchStartDay(searchStartDay);
      searchEndDay && setSearchEndDay(searchEndDay);
      search && setSearch(search);
      selectCatgoris && setSelectCatgoris(selectCatgoris);
      trueStates?.length && setSearchState(checkedState=>({...checkedState, [trueStates?.[0]]: true}));
      page && setPage(page);

      allGoodsGet(searchQuery);
      setBackPage(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if(!backPage) {
      allGoodsGet(searchData);
      setBackPage(false);
    }
    localStorage.setItem('searchQueryManagement', JSON.stringify({...searchData, ...searchQuery, page: page}));
  }, [page]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setSearchQuery(null);
      localStorage.removeItem('searchQueryManagement');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSearchFnc = async (e) => {
    e.preventDefault();
    // 버튼이 눌리면 주문기간,구분,상태,검색을 api에 던져서 나온값을 data에 넣어준다.

    const response = await getAllGoodsFunc(searchData);
    handleSearchChange();
    setPage(1);
    if (response.status === 200) {
      setData(() => response?.data?.goodsWithLogs
      );
      setPageCount(() =>
        response?.data?.Pagination.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  }

  const openImg = (idx) => {
    const modalE = '#img-modal-' + idx;
    const myModal = document.querySelector(modalE);
    modal.open(myModal, { allowDrag: true, showClose: true, clickClose: true })
  }

  const handleShowDetail = (id) => {
    navigate("/managementdetail/" + id)
  }

  const handleSearchChange = () => {
    setSearchQuery(searchData);
    localStorage.setItem('searchQueryManagement', JSON.stringify(searchData));
  };

  return (
    <>
      <Container className='align-items-center justify-content-center p-5'>
        <ManageMentHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          selectCatgoris={selectCatgoris}
          setSelectCatgoris={setSelectCatgoris}
          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}
          setSearch={setSearch}
          search={search} />
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            {myButton.map((it, idx) => (
                <Button
                    key={idx}
                    variant={it.variant}
                    onClick={it.onClick ? it.onClick : null}
                    className='px-4 mx-2'
                >
                  {it.name}
                </Button>
            ))}
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              {headColumn.map((it, idx) => (
                <th className='text-center' key={idx}>
                  {it}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* 물품정보  물푸id 전달 => detail */}
            {data.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center align-middle'
                style={{ cursor: "pointer" }}
              >
                <td onClick={(e) => handleShowDetail(it.goodsID)}> {totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                {it?.goods_created_at ? <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.goods_created_at.slice(0, 10)}</td> : <td></td>}
                {it?.clientName ?
                    <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.clientName}</td> : <td></td>}
                {it?.department ?
                    <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.department}</td> : <td></td>}
                {it?.staffName ? <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.staffName}</td> :
                    <td></td>}
                {it?.productName ?
                    <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.productName}</td> : <td></td>}
                {it?.category ? <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.category}</td> :
                    <td></td>}
                {it?.goodsImage ? (
                    <td
                        style={{backgroundColor: "#f1efef"}}
                        className='flex justify-center items-center'
                    >
                      <img 
                          style={{minWidth: "100px", width: "100px"}}
                          onClick={() => openImg(idx)}
                           src={it.goodsImage[0]}
                           alt=''
                      ></img>
                      <div id={`img-modal-${idx}`} className="modal" style={{height: "fit-content"}}>
                        {/* <h1>test</h1> */}
                        <img
                            width={'100%'}
                            src={it.goodsImage[0]}
                            alt=''
                        ></img>
                      </div>
                    </td>
                ) : <td></td>}
                {it?.goodsName ? <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.goodsName}</td> :
                    <td></td>}
                {it?.expirationData ? (
                    <td onClick={(e) => handleShowDetail(it.goodsID)}>
                      {expirationDate(it.expirationData) ? (
                          <span
                              className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-semibold text-green-700 ring-1 ring-inset ring-green-600/10'>
                        {it.expirationData}
                      </span>
                      ) : (
                          <span
                              className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs  text-red-700 ring-1 ring-inset ring-red-600/20 font-semibold'>
                        {it.expirationData}
                      </span>
                      )}
                    </td>
                ) : <td></td>}
                {it?.currentAmount ?
                    <td onClick={(e) => handleShowDetail(it.goodsID)}>{it.currentAmount}</td> :
                    <td>{it.currentAmount}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />
        <Row className='mt-3 mb-3 text-end'>
          <Col>
            {myButton.map((it, idx) => (
              <Button
                key={idx}
                variant={it.variant}
                onClick={it.onClick ? it.onClick : null}
                className='px-4 mx-2'
              >
                {it.name}
              </Button>
            ))}
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(ManageMent);
