import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Container from "@mui/material/Container";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { boothRegisterFunc, getAllClientsFunc, getAllProductFunc, getAllboothtypeFunc } from "../../../api";
import { toast } from "react-toastify";

//TODO -  2. 등록된 부스종류 목록을 가져온다

const BoothRegister = () => {
  const navigate = useNavigate();
  const imgInputRef = useRef();
  const imgInputRef2 = useRef();
  const imgInputRef3 = useRef();
  const [forms, setForms] = useState([]);

  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState("")
  const [boothType, setBoothType] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectProduct, setselectProduct] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc2, setImgSrc2] = useState(null);
  const [imgSrc3, setImgSrc3] = useState(null);

  // 전체회사 리스트

  const allClientsGet = async () => {
    const response = await getAllClientsFunc();
    if (response.status === 200) {
      setClients(response.data?.data);
    } else {
      toast.error("거래처 정보를 가져오기 실패");
    }
  };
  // 전체회사 리스트
  const allBoothtypeGet = async () => {
    const response = await getAllboothtypeFunc();
    if (response.status === 200) {
      setBoothType(response?.data);
    } else {
      toast.error("부스종류를 가져오기 실패.");
    }
  };

  useEffect(() => {
    allClientsGet()
    allBoothtypeGet();

  }, []);

  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob, setImage) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImage(reader.result);
        resolve();
      };
    });
  };

  const handleDeleteImg = (e) => {
    if (e.target.name === "img1") {
      setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.name === "img2") {
      setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.name === "img3") {
      setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };


  const handleChangeImage = (e) => {
    // console.log(e.target.name);

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      handleDeleteImg(e)
      return;
    }

    if (e.target.name === "img1") {
      encodeFileToBase64(e.target.files[0], setImgSrc);
    } else if (e.target.name === "img2") {
      encodeFileToBase64(e.target.files[0], setImgSrc2);
    } else if (e.target.name === "img3") {
      encodeFileToBase64(e.target.files[0], setImgSrc3);
    }
  };

  const handleDeleteImage = (e) => {
    if (e.target.id === "del_img1") {
      setImgSrc(null);
      imgInputRef.current.value = "";
    } else if (e.target.id === "del_img2") {
      setImgSrc2(null);
      imgInputRef2.current.value = "";
    } else if (e.target.id === "del_img3") {
      setImgSrc3(null);
      imgInputRef3.current.value = "";
    }
  };

  const data = async (id) => {
    const res = await getAllProductFunc(id, false)
    if (res.status === 200) {
      setselectProduct(res.data);
    }
  };

  const handleChangeClient = (e) => {
    const { value } = e.target
    console.log("🚀 ~ file: BoothRegister.jsx:101 ~ handleChangeClient ~ value:", value)

    // const clientName = value;
    const selectCompany = clients.find(
      (client) => client._id === value
    );

    if (selectCompany && selectCompany.department) {
      setDepartment(selectCompany.department);
      setClientName(selectCompany.companyName)
      data(selectCompany._id);
    } else {
      setDepartment([]);
    }
  };
  const handleChangeDepartment = (e) => {
    const department = e.target.value;
    const filterProducts = selectProduct.filter(
      (product) => product.department === department
    );
    if (filterProducts) {
      setFilterList(filterProducts);
    } else {
      setFilterList([]);
    }
  };

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const buothTypeId = formData.get('boothTypeId');
    const boothTypeFilter = boothType?.data?.find((item) => item._id === buothTypeId);

    formData.append('clientName', clientName)
    formData.append('boothType', boothTypeFilter.boothType)
    const config = {
      "Content-Type": "multipart/form-data",
    };
    // console.log(Object.fromEntries(formData.entries()));
    const res = await boothRegisterFunc(formData, config)
    if (res.status === 201 || res.status === 200) {
      toast.success("Successfully Register!");
      navigate("/bmanager");
      // setTimeout(() => {
      //   navigate("/partnerstable", { replace: true });
      // }, 1000);
      setForms({
        ...forms,
        boothName: "",
        boothCode: "",
        boothType: "",
        clientName: "",
        department: "",
        productName: "",
        specialNote: "",
      });
      setClientName("")
      setImgSrc(null);
      setImgSrc2(null);
      setImgSrc3(null);
      imgInputRef.current.value = "";
      imgInputRef2.current.value = "";
      imgInputRef3.current.value = "";
    } else {
      toast.error("거래처 등록에 실패했습니다.!");
    }

  };
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>부스등록</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부스명
            </Form.Label>
            <Col sm='3'>
              <Form.Control
                name='boothName'
                value={forms.boothName || ""}
                onChange={onChange}
                type='text'
                size='sm'
                required
              />
            </Col>

            <Form.Label column sm='2' className="ps-3">
              부스종류
            </Form.Label>
            <Col sm='3'>
              <Form.Select
                aria-label='BoothType select'
                name='boothTypeId'
                size='sm'
                value={forms.boothTypeId || ""}
                required
                onChange={onChange}
              >
                <option value=''>부스종류</option>

                {boothType &&
                  boothType.data?.map((it) => (
                    <option key={it._id} value={it._id}>
                      {it.boothType}/{it.boothName}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              거래처명
            </Form.Label>
            <Col sm='3'>
              <Form.Select
                aria-label='Client select '
                onChange={handleChangeClient}
                name='clientID'
                size='sm'
                required
              >
                <option value=''>거래처명</option>
                {clients &&
                  clients.map((it) => (
                    <option key={it._id} value={it._id}>
                      {it.companyName}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Form.Label column sm='2' className="ps-3">
              부서명
            </Form.Label>
            <Col sm='3'>
              <Form.Select
                aria-label='Department Select'
                name='department'
                onChange={handleChangeDepartment}
                required
                size='sm'
              >
                <option value=''>부서명</option>
                {department &&
                  department.map((it, idx) => (
                    <option key={idx} value={it}>
                      {it}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품명
            </Form.Label>
            <Col sm='3'>
              <Form.Select
                aria-label='Product select'
                name='productName'
                size='sm'
                required
              >
                <option value=''>제품명</option>
                {filterList &&
                  filterList.map((it) => (
                    <option key={it._id} id={it._id} value={it.productName}>
                      {it.productName}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              특이사항
            </Form.Label>
            <Col sm='9'>
              <FloatingLabel controlId='floatingTextarea2' label='특이사항'>
                <Form.Control
                  as='textarea'
                  name='specialNote'
                  placeholder='Leave a comment here'
                  value={forms.specialNote || ""}
                  onChange={onChange}
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </Col>
          </Form.Group>
          {/* //ANCHOR - image */}
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='9'>
              <small className='mt-3 px-2 d-block text-danger text-start'>
              * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              부스이미지 1
            </Form.Label>
            <Col sm="9">
              <Row>
                {imgSrc && (
                  <Form.Group as={Row} className='mb-3 px-3 pe-0'>
                    <Col className="w-100 pe-0">
                      <img
                        src={imgSrc}
                        alt='img preview'
                        style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Row>
              <Row className="d-flex align-items-center">
                <Col>
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      ref={imgInputRef}
                      type='file'
                      size='sm'
                      name='img1'
                      required
                      onChange={handleChangeImage}
                      accept='image/*'
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {imgSrc && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img1'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              부스이미지 2
            </Form.Label>
            <Col sm="9">
              <Row>
                {imgSrc2 && (
                  <Form.Group as={Row} className='mb-3 px-3 pe-0'>
                    <Col className="w-100 pe-0" >
                      <img
                        src={imgSrc2}
                        alt='img preview'
                        style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Row>
              <Row className="d-flex align-items-center">
                <Col>
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      ref={imgInputRef2}
                      type='file'
                      size='sm'
                      name='img2'
                      disabled={!imgSrc}
                      onChange={handleChangeImage}
                      accept='image/*'
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {imgSrc2 && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img2'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}

          <Form.Group as={Row} className='mb-3 px-3 align-items-center'>
            <Form.Label column sm='2'>
              부스이미지 3
            </Form.Label>
            <Col sm="9">
              <Row>
                {imgSrc3 && (
                  <Form.Group as={Row} className='mb-3 pe-0'>
                    <Col className="pe-0">
                      <img
                        src={imgSrc3}
                        alt='img preview'
                        style={{ maxWidth: "100%", height: "auto", width: "100%" }}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Row>
              <Row className="d-flex align-items-center">
                <Col>
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      ref={imgInputRef3}
                      type='file'
                      size='sm'
                      name='img3'
                      disabled={!imgSrc2}
                      onChange={handleChangeImage}
                      accept='image/*'
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {imgSrc3 && (
                    <span
                      onClick={handleDeleteImage}
                      id='del_img3'
                      className=' px-3 py-1 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
                    >
                      삭제
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Form.Group>
          <Row>
            {/* <Col className='text-start m-5 '>
              <Button variant='outline-secondary' className='px-4 mx-3'>
                삭제
              </Button>{" "}
            </Col> */}
            <Col className='text-end m-5 '>
              <Button
                type="button"
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/bmanager", {state: {backPage: true}})}
              >
                목록
              </Button>{" "}
              <Button as='input' type='submit' value='저 장' className='px-4' />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(BoothRegister);
