import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useState, useRef, useEffect, memo } from "react";
import JoditEditor from "jodit-react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { updateDNotice, deleteDNotice } from "../../api";
import {isContentEmpty} from "../../utils"

const DepartmentsBoardView = () => {
  // state 취득
  const { id } = useParams();
  const navigate = useNavigate();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [forms, setForms] = useState({});
  const [edit, setEdit] = useState(false);
  const fileInput = useRef();

  const [allClients, setAllClients] = useState([]);
  const [department, setDepartment] = useState([]);
  const [fileName, setfileName] = useState("");
  const [client, setClient] = useState({});

  // 등록업체 가져오기
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/client/")
      .then((res) => {
        setAllClients(res.data?.data);
      })
      .catch((err) => console.log(err));
    setTimeout(() => {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + "/dnotice/" + id)
        .then((res) => {
          const { attachFile } = res.data;
          let attachFileName = "";
          if (attachFile) {
            attachFileName = decodeURI(
              attachFile.split("/")[attachFile.split("/").length - 1]
            );
            attachFileName = attachFileName.split("-").pop();
            setfileName(attachFileName);
          }
          setForms({
            ...forms,
            clientName: res.data.clientName,
            department: res.data.department,
            title: res.data.title,
            attachFile: res.data.attachFile || "",
          });
          setContent(res.data.content);
        })
        .catch((err) => console.log(err));
    }, 150);
  }, []);

  const jodiconfig = {
    disabled: !edit,

    // readonly: true,
  };

  const handleChangeClient = (e) => {
    const clientName = e.target.value;
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (clientName === "default") {
      setDepartment([]);
      return;
    }
    const selectCompany = allClients.find(
      (client) => client.companyName === clientName
    );
    setClient(selectCompany)
    setDepartment(selectCompany?.department)
  };

  const onChange = (e) => {

    if (e.target.name === "attachFile") {
      const selectedFile = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (selectedFile?.size > maxSize) {
        toast.error('5MB 이하의 파일을 업로드해주세요.');
        fileInput.current.value = "";
        return;
      }
    }
  
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!edit) {
      setEdit(true);

      const selectCompany = allClients.find(
        (client) => client.companyName === forms.clientName
      );
      setClient(selectCompany)
      setDepartment(selectCompany?.department)

      return;
    }

    if (isContentEmpty(content)) {
      toast.error("내용 비워두지 마세요.!");
      return;
    }

    if (forms.title === "" || forms.clientName === "default" || forms.department === "default") {
      toast.error("회사명, 직위, 부서 등을 비워두지 마세요.!");
      return;
    }

    const formData = new FormData(e.target);
    formData.append("content", content);
    formData.append("clientId", client._id);

    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await updateDNotice(id, formData, config);
    if (response.status === 200) {
      toast.success("Successfully Register!");
      navigate("/board", { replace: true });
    } else {
      toast.error("행사요청 업데이트에 실패했습니다.!");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      const response = await deleteDNotice(id);
      if (response.status === 200) {
        toast.success("Successfully Delete!");
        navigate("/board", { replace: true });
      } else {
        toast.error("행사요청 업데이트에 실패했습니다.!");
      }
    }
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          {edit ? "부서별 게시판 수정" : "부서별 게시판 뷰"}
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              거래처명
            </Form.Label>
            <Col sm='3' className='flex items-center'>
              {edit ? (
                <Form.Select
                  aria-label='Client select'
                  onChange={handleChangeClient}
                  name='clientName'
                  value={forms.clientName || ""}
                  size='sm'
                  required
                >
                  <option value='' disabled>거래처명</option>
                  {allClients.length > 0 &&
                    allClients.map((it) => (
                      <option key={it._id} id={it._id} value={it.companyName}>
                        {it.companyName}
                      </option>
                    ))}
                </Form.Select>
              ) : (
                forms.clientName
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              부서명
            </Form.Label>
            <Col sm='3' className=' self-center'>
              {edit ? (
                <Form.Select
                  aria-label='Department Select'
                  name='department'
                  onChange={onChange}
                  value={forms.department || ""}
                  size='sm'
                  required
                >
                  <option value='' disabled>부서명</option>
                  {department.length > 0 &&
                    department.map((it, idx) => (
                      <option key={idx} value={it}>
                        {it}
                      </option>
                    ))}
                </Form.Select>
              ) : (
                forms.department
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제목
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='text'
                name='title'
                onChange={onChange}
                value={forms.title || ""}
                disabled={!edit}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              내용
            </Form.Label>
            <Col sm='12'>
              <JoditEditor
                ref={editor}
                value={content}
                config={!edit ? jodiconfig : ""}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}
              />
            </Col>
          </Form.Group>

          {edit &&  
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              첨부파일
            </Form.Label>
            <Col sm='10' className='items-center'>
              {edit ? (
                <>
                  <Form.Group controlId='formFile'>
                    <Form.Control
                      type='file'
                      size='sm'
                      name='attachFile'
                      ref={fileInput}
                      onChange={onChange}
                    />
                  </Form.Group>
                  <br />
                  <a href={forms.attachFile} target='_blink'>
                    {fileName}
                  </a>
                </>
              ) : forms.attachFile ? (
                <a href={forms.attachFile} target='_blink'>
                  {fileName}
                </a>
              ) : (
                "첨부파일이 없습니다."
              )}
            </Col>
          </Form.Group>

          <Row className='mt-5'>
            <Col className='text-start '>
              <Button
                variant='outline-danger'
                className='px-4 mx-3'
                onClick={handleDelete}
              >
                삭제
              </Button>{" "}
            </Col>
            <Col className='text-end '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/board")}
              >
                목 록
              </Button>{" "}
              <Button
                as='input'
                type='submit'
                value={edit ? "저장" : "수정"}
                className='px-4'
              />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(DepartmentsBoardView);
