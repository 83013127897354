import "bootstrap/dist/css/bootstrap.min.css";
import CompanyTable from "../../components/CompanyTable";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { Table, Col, Row } from "react-bootstrap";
import { getAlldeliveryGoodsOrderFunc } from "../../api";
import { toast } from "react-toastify";
import SendHistoryHead from "./SendHistoryHead";
import Pagenation from "../../components/Pagenation";
import * as XLSX from 'xlsx';
import {BASE_URL} from "../../api/helper";
import useStoreLocal from "../../hook/useStoreLocal.hook";
import { KEY_ORDERHISTORY } from "../../utils/constant";

const SendHistory = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const [searchStartDay, setSearchStartDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    발송: true,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [searchQuery, setSearchQuery, backPage, setValueBackPage] = useStoreLocal(KEY_ORDERHISTORY, {});

  // const changePageHandle =()=>{
  //   navigate("/disRecevingForm",{state:{title:"물류 발송내역 상세"}})
  // }
  const headColumn = ["번호", "주문일자", "배송유형", "거래처명", "부서명", "제품명", "신청인", "수취인", "수취주소", "발송일"]

  // 전체 물품 리스트
  const searchData = { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, trueStates: "발송", page, sendStartDay, sendEndDay }


  const alldeliveryGoodsOrderGet = async (searchData) => {
    const response = await getAlldeliveryGoodsOrderFunc(searchData);

    if (response.status === 200) {
      setData(() => response?.data?.groupedOrders);
      setPageCount(() =>
        response?.data?.Pagination.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  };
  useEffect(() => {
    if (!backPage) {
      alldeliveryGoodsOrderGet(searchData)
    }
    alldeliveryGoodsOrderGet({ ...searchData, ...searchQuery, page: page })
  }, [page]);

  useEffect(() => {
    if (backPage) {
      const {
        searchStartDay,
        searchEndDay,
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        sendStartDay,
        sendEndDay,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      searchStartDay && setSearchStartDay(searchStartDay);
      searchEndDay && setSearchEndDay(searchEndDay);
      search && setSearch(search);
      sendStartDay && setSendStartDay(sendStartDay);
      sendEndDay && setSendEndDay(sendEndDay);
      page && setPage(page);

      alldeliveryGoodsOrderGet({ ...searchData, ...searchQuery });
      setValueBackPage(false);
    }
  }, [searchQuery, backPage]);

  const handleSearchFnc = async (e) => {
    // 버튼이 눌리면 주문기간,구분,상태,검색을 api에 던져서 나온값을 data에 넣어준다.
    e.preventDefault();
    handleSearchChange();
    alldeliveryGoodsOrderGet(searchData)
    setPage(1);
  }

  const handleSearchChange = () => {
    setSearchQuery(searchData);
  };

  const exportToExcel = () => {
    const {searchStartDay,searchEndDay,selectClient,selectDepartment,selectProduct,search,trueStates:searchState,sendStartDay,sendEndDay,page} = searchData
    const url = `${BASE_URL}/deliveryorder/excel2?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(searchState)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`;
    const outputFilename = '물류발송내역.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // const data_export = data.others.map((it, idx) => {
    //   return {
    //     '번호': totlaCount?.total - (page - 1) * totlaCount?.pageItem - idx,
    //     '주문일자': it?.createdAt ? it.createdAt.slice(0, 10) : null,
    //     '배송유형': it.combined ? "합배송" : "개별배송",
    //     '거래처명': it?.companyName ? it.companyName : null,
    //     '부서명': it?.department ? it.department : null,
    //     '제품명': it?.productName ? it.productName : null,
    //     '신청인': it?.staffName ? it.staffName : null,
    //     '수취인': it?.recipientName ?it.recipientName: null,
    //     '수취주소': it?.addr1 ? it.addr1: null + it?.addr2 ? it.addr2: null,
    //     '발송일': it?.updatedAt ? it.updatedAt.slice(0, 10) : null
    //   };
    // })
    //
    // // 데이터를 워크시트로 변환
    // const worksheet = XLSX.utils.json_to_sheet(data_export);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    //
    // // 파일 이름 설정
    // let fileExtension = '.xlsx';
    //
    // // Excel 파일 생성 및 저장
    // XLSX.writeFile(workbook,   '물류발송내역' + fileExtension);
  };
  
  return (
    <>
      <Container className="align-items-center justify-content-center p-5 w-100 mw-100">
        <SendHistoryHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          sendStartDay={sendStartDay}
          sendEndDay={sendEndDay}
          setSendStartDay={setSendStartDay}
          setSendEndDay={setSendEndDay}
          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
          search={search} />

        <Table bordered striped hover>
          <thead>
            <tr>
              {headColumn.map((v, idx) => (<th className='text-center' key={idx}>{v}</th>))}

            </tr>
          </thead>
          <tbody>
            {data?.others?.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/disrecevingform/" + item._id)}

              >
                <td> {totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td>{item.createdAt.slice(0, 10)}</td>
                <td>{item.combined ? "합배송" : "개별배송"}</td>
                <td>{item.companyName}</td>
                <td>{item.department}</td>
                <td>{item.productName}</td>
                <td>{item.staffName}</td>
                <td>{item.recipientName}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[400px]">{item.addr1} {item.addr2}</td>
                <td>{item.shippingDate.slice(0, 10)}  </td>


              </tr>
            ))}
          </tbody>
        </Table>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />

        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <button className='px-4 mx-2 btn btn-outline-secondary' onClick={exportToExcel}>엑셀다운로드</button>
          </Col>
        </Row>
      </Container>
    </>

  );
};

export default memo(SendHistory);
