import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState, useRef } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useNavigate } from "react-router-dom";
import { clientRegisterFunc ,clientUCheckDuplicateIdFunc} from "../../../api";
import { toast } from "react-toastify";
import { cleanXSS } from "../../../utils";

// TODO: form 검증

const PartnerRegister = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [zipcode, setZipcode] = useState("");
  /* preview image*/
  const [imgSrc, setImgSrc] = useState("");
  const [preview, setPreview] = useState("");
  const [forms, setForms] = useState([]);

  const fileInputRef = useRef(null);

  // FIXME: 우편번호

  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

  const open = useDaumPostcodePopup(scriptUrl);
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let zoneCode = data.zonecode;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(fullAddress);
    setZipcode(zoneCode);
  };

  // 우편번호 찾기 트리거 해줄 함수
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    let val
    if (value.trim().length === 0) {
      val = value.trim();
    } else {
      val = cleanXSS(value);
    }
    if (name == "telNumber" || name == "faxNumber") {
      const inputNumber = value;
      const digits = inputNumber.replace(/\D/g, '');
      let formattedNumber = "";

      if (digits.length > 3 && digits.length < 7) {
        formattedNumber += digits.slice(0, 3) + '-' + digits.slice(3, 7);
      } else if (digits.length > 7 && digits.length < 12) {
        formattedNumber += digits.slice(0, 3) + '-' + digits.slice(3, 7) + '-' + digits.slice(7, 11);
      } else if (digits.length > 12) {
        formattedNumber += digits.slice(0, 3) + '-' + digits.slice(3, 7) + '-' + digits.slice(7, 100);
      } else {
        formattedNumber += value;
      }
      setForms((prev) => ({
        ...prev,
        [name]: formattedNumber,
      }));
    } else {
      setForms((prev) => ({
        ...prev,
        [name]: val,
      }));
    }
  };

  const handleChangeImage = (e) => {
    // encodeFileToBase64(e.target.files[0]);

    const selectedFile = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('5MB 이하의 파일을 업로드해주세요.');
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the file input
        setImgSrc("");
        setPreview("");
      }
    } else {
      setImgSrc(e.target.files[0]);
    }
  };

  // 이미지 뷰어
  useEffect(() => {
    if (imgSrc) {
      setPreview(URL.createObjectURL(imgSrc));
    }
  }, [imgSrc]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // console.log(Object.fromEntries(formData.entries()));
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await clientRegisterFunc(formData, config);
    if (response.status === 200) {
      toast.success("Successfully Register!");
      setTimeout(() => {
        navigate("/partnerstable", { replace: true });
      }, 1000);
    } else {
      toast.error("거래처 등록에 실패했습니다.!");
    }
  };

  const checkId = async () => {
    const response = await clientUCheckDuplicateIdFunc({daepyoId : forms.daepyoId});
    console.log(response);
    if (response.status === 200) {
      toast.success("사용 가능한 ID입니다.");
    } else {
      toast.error("ID가 중복되어 사용할수 없으니 다른 아이디를 선택하십시요.");
    }
  }

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>거래처 등록</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업체명
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='companyName'
                size='sm'
                value={forms.companyName || ""}
                onChange={onChange}
              />
              {/* {errors.companyName && (
                <p className='mt-2 text-danger mb-0'>
                  {errors.companyName.message}
                </p>
              )} */}
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              회사코드
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='companyCode'
                size='sm'
                value={forms.companyCode || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              대표ID
            </Form.Label>
            <Col sm='7'>
              <Form.Control
                type='text'
                name='daepyoId'
                size='sm'
                value={forms.daepyoId || ""}
                onChange={onChange}
              />
            </Col>
            <Col sm='3'>
              <Button type='button' variant='primary' onClick={checkId} size='sm'>
                중복검사
              </Button>{" "}
            </Col>
          </Form.Group>
          {/*  */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              비밀번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='password'
                size='sm'
                value={forms.password || ""}
                onChange={onChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              비밀번호확인
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='cPassword'
                value={forms.cPassword || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              대표자명
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='ceoName'
                value={forms.ceoName || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              사업자번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='companyNumber'
                value={forms.companyNumber || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              법인번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='corporationNumber'
                value={forms.corporationNumber || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업종
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='sectors'
                value={forms.sectors || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업태
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='business'
                value={forms.business || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              대표전화
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='telNumber'
                value={forms.telNumber || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              팩스번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='faxNumber'
                value={forms.faxNumber || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              주소
            </Form.Label>
            <Col sm='6'>
              <Form.Control type='text' value={value} name='addr1' size='sm' readOnly />
            </Col>
            <Col sm='3'>
              <Button
                variant='primary'
                size='sm'
                type='button'
                onClick={handleClick}
                className=''
              >
                우편번호찾기
              </Button>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'></Form.Label>
            <Col sm='6'>
              <Form.Control type='text' name='addr2' size='sm' placeholder="상세주소" />
            </Col>
            <Col sm='3'>
              <Form.Control
                type='text'
                name='zipCode'
                value={zipcode || ""}
                size='sm'
                readOnly
              />
            </Col>
          </Form.Group>
          {/* 로고 셀렉트  */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              로고
            </Form.Label>
            <Col sm='9'>
              <Form.Group>
                <Form.Control
                  type='file'
                  size='sm'
                  name='logoImage'
                  onChange={handleChangeImage}
                  accept='image/*'
                  ref={fileInputRef}
                />
              </Form.Group>
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col sm='9'>
              {preview && (
                <img
                  src={preview}
                  alt='img preview'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              )}
            </Col>
          </Form.Group>

          {/* 홈페이지 필드 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              홈페이지
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='homePage'
                value={forms.homePage || ""}
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>
          <Row>
            <Col className='text-center '>
              <Button
                variant='outline-secondary'
                onClick={() => navigate("/partnerstable")}
                className='px-4 mx-3'
              >
                취 소
              </Button>{" "}
              <Button as='input' type='submit' value='저 장' className='px-4' />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(PartnerRegister);
