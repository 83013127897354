import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState } from "react";
import { tokens } from "../../theme";


import { Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getIncomingOrderItemFunc, updateIncomingOrderItemFunc } from "../../api";
import { toast } from "react-toastify";

const IncomingOrderView = () => {
  const {id}= useParams()
  const navigate = useNavigate();
  const [data,setData]= useState({})
  console.log("🚀 ~ file: IncomingOrderView.jsx:20 ~ IncomingOrderView ~ data:", data)
  const [state,setState]=useState("")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [forms, setForms] = useState({
    incomingOrderCode: "",
    carNumber: "",
    driverPhone: "",
    incomingMethod: "",
    scheduledDate: "",
    scheduledTime: "",
    goodsType: "",
    goodsQuantity: "",
    remarks: "",
    specialMemo: "",
    createdAt:"",
  });

  const handleChangeState = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    setForms({
      ...forms,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // 전체 물품 리스트
    const IncomingOrderItemGet = async (id) => {
     const response = await getIncomingOrderItemFunc(id);
     if (response.status === 200) {

       setData(response.data)

       setForms({...forms, 
       incomingOrderCode: response.data.incomingOrderCode,
       carNumber: response.data.carNumber,
       driverPhone:response.data.driverPhone,
       incomingMethod: response.data.incomingMethod,
       scheduledDate: response.data.scheduledDate,
       scheduledTime: response.data.scheduledTime,
       goodsType: response.data.goodsType,
       goodsQuantity: response.data.goodsQuantity,
       remarks: response.data.remarks,
       createdAt:response.data.createdAt.slice(0,10),
       specialMemo: response.data.specialMemo })
       setState(response.data.state)
     
     } else {
       toast.error("행사요청을 가져오는데 실패했습니다.");
     }
   };
   IncomingOrderItemGet(id)
  
 
}, [id]);

 const handleSubmit = async(e) => {
   e.preventDefault();
   const formData = new FormData(e.target);
   // formData.append("eventState", state);
   // formData.append("boothName", boothName);
   // formData.append("boothContent", content);

   console.log(Object.fromEntries(formData.entries()));
   const config = {
     "Content-Type": "multipart/form-data",
   };

   const response = await updateIncomingOrderItemFunc(id,formData);
   if (response.status === 200) {
     toast.success("Successfully Updated!");
     setTimeout(() => {
       navigate("/incomingorderlist", { replace: true });
     }, 1000);
   } else {
     toast.error("입고신청 업데이트에 실패했습니다.!");
   }
 };

  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">입고신청관리</Row>
        <Form onSubmit={handleSubmit}>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1. 주문자정보
          </Typography>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              코드
            </Form.Label>
            <Col sm="3">
              <Form.Control
                name="incomingOrderCode"
                disabled
                value={forms.incomingOrderCode}
                onChange={handleChangeState}
                type="text"
                size="sm"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              신청일자
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
              {forms?.createdAt}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              입차예정일
            </Form.Label>

            <Col sm="3">
              <Form.Control
                type="date"
                name="scheduledDate"
                onChange={handleChangeState}
                value={forms.scheduledDate}
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              입차예정시간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="scheduledTime"
                onChange={handleChangeState}
                value={forms.scheduledTime}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              용달차번호
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="carNumber"
                onChange={handleChangeState}
                value={forms.carNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              기사전화번호
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="driverPhone"
                onChange={handleChangeState}
                value={forms.driverPhone}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              비고
            </Form.Label>
            <Col sm="9">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control as="textarea" rows={3} 
                 name="remarks"
                 onChange={handleChangeState}
                 value={forms.remarks}
                />
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9">
             
               {["신규","접수","입고","취소"].map((item,idx) => (
                  <Form.Check
                    key={idx}
                    inline
                    label={item}
                    value={item}
                    checked={state === item}
                    onChange={(e) => setState(e.target.value)}
                    name="state"
                    type="radio"
                  />
              ))}
            </Col>
          </Form.Group>

          <hr />

          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            2. 신청자정보
          </Typography>

          {/* 거래처명거래처명 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              거래처명
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {data.staff?.client?.companyName}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 거래처명거래처명 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주문부서
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" >
                {data?.staff?.department}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 거래처명거래처명 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              신청인
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" >
               {data?.staff?.staffName}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 거래처명거래처명 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" >
              {data?.staff?.phone}
              </Form.Label>
            </Col>
          </Form.Group>
          {/* 거래처명거래처명 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.specialMemo}
              </Form.Label>
            </Col>
          </Form.Group>
         
          <hr />
          <Row className="mt-5">
            {/* <Col className="text-start ">
              <Button variant="primary" className="px-4 mx-3">
                엑셀다운로드
              </Button>{" "}
              <Button variant="outline-secondary" className="px-4 mx-3">
                삭제
              </Button>{" "}
            </Col> */}
            <Col className="text-end ">
              <Button variant="outline-secondary"  type="button" className="px-4 mx-3" onClick={()=>navigate("/incomingorderlist")}>
                목 록
              </Button>{" "}
             {data.state ==="취소" || data.state ==="입고" ? "": (<Button as="input" type="submit" disabled={data.state ==="취소" || data.state ==="입고"} value="저장" />)}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

IncomingOrderView.defaultProps = {
  title: "",
};

export default memo(IncomingOrderView);
