import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useState ,useRef, memo } from "react";
import {  useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import axios from "axios";
import { toast } from "react-toastify";
import {isContentEmpty} from "../../utils"

const ContentInfoRegister = () => {
  // state 취득
  const fileInput=useRef();

  const editor = useRef(null)
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
    /* preview image*/
  const [imgSrc, setImgSrc] = useState(null);
  const [content,setContent] = useState("")

  const onChange = (e) => {

    if (e.target.name === "attachFile") {
      const selectedFile = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (selectedFile?.size > maxSize) {
        toast.error('5MB 이하의 파일을 업로드해주세요.');
        setForms({ ...forms, [e.target.name]: "" });
        return;
      }
    }

    setForms({ ...forms, [e.target.name]: e.target.value });
  };

    // 이미지 뷰어
    const encodeFileToBase64 = (fileBlob) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileBlob);
      return new Promise((resolve) => {
        reader.onload = () => {
          setImgSrc(reader.result);
          resolve();
        };
      });
    };
  
    const handleDeleteImage = (e) => {
      if (e.target.id === "del_img1") {
        setImgSrc(null);
        fileInput.current.value="";
      }
    
    };
    const handleChangeImage = (e) => {
      const selectedFile = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (selectedFile?.size > maxSize) {
        toast.error('5MB 이하의 파일을 업로드해주세요.');
        setImgSrc(null);
        fileInput.current.value = "";
        return;
      }
      encodeFileToBase64(e.target.files[0]);
    };

  // const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isContentEmpty(content)) {
      toast.error("내용 비워두지 마세요.!");
      return;
    }
    const formData = new FormData(e.target);
    formData.append("content", content);
    // formData.append("boothName", boothName);
    // formData.append("boothContent", content);

    // console.log(Object.fromEntries(formData.entries()));
    await axios
      .post(process.env.REACT_APP_API_BASE_URL+"/event/register-hallinfo", formData)
      .then((res) => {
        console.log(res.data);
        setContent("");
        setForms({ ...forms, title: "",attachFile:""})
        setImgSrc(null)
        fileInput.current.value=""
        toast.success("Successfully Register!");
        navigate("/contentinfo", { replace: true });
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">행사장 정보등록</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="1">
              제목
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                name="title"
                value={forms.title ||""}
                onChange={onChange}
                size="sm"
                required
              />
            </Col>
          </Form.Group>

          {/* 특이사항 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              내용
            </Form.Label>
            <Col sm="12">
              <JoditEditor 
                ref = {editor} 
                value={content} 
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}  
                required/>
            </Col>
          </Form.Group>

          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              첨부파일
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile">
                <Form.Control type="file" size="sm" name="attachFile" onChange={onChange} value={forms.attachFile}/>
              </Form.Group>
            </Col>
          </Form.Group>
          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              썸네일
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile">
                <Form.Control type="file" size="sm" name="thumnail" onChange={handleChangeImage} ref={fileInput} accept='image/*'/>
              </Form.Group>
            </Col>
            <Col className="self-center">
            {imgSrc &&( <span
                onClick={handleDeleteImage}
                id='del_img1'
                className=' px-3 py-2 bg-sky-600 rounded-md text-white font-bold cursor-pointer'
              >
                삭제
              </span>)}
            </Col>
          </Form.Group>
            {/* 이미지 프리뷰 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col sm='9'>
              {imgSrc && (
                <img
                  src={imgSrc}
                  alt='img preview'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              )}
            </Col>
          </Form.Group>
          <Row className="mt-5">
           
            <Col className="text-end ">
              <Button variant="outline-secondary" className="px-4 mx-3" onClick={()=>navigate("/contentinfo",{replace:true})}>
                취 소
              </Button>
              <Button as="input" type="submit" value="저 장" disabled={forms.title ===''} className="px-4" />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};





export default memo(ContentInfoRegister);
