import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Form from "react-bootstrap/Form";

import { useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAllStaffsFunc, getProductFunc, productDeleteFunc, productUpdateFunc } from "../../../api";
import { toast } from "react-toastify";
import defaultImage from "../../../assets/default_image.png"

const ProductView = () => {
  const { did, id } = useParams();
  const fileInput = useRef();
  const navigate = useNavigate();
  const client = useSelector((state) => state.client);
  const staffs = useSelector((state) => state.staffs);
  const { department } = client;
  const [selectStaff, setSelectStaff] = useState([]);
  const [forms, setForms] = useState([]);
  const [checkedInputs, setCheckedInputs] = useState([]);
  /* preview image*/
  const [imgSrc, setImgSrc] = useState(null);
  const [edit, setEdit] = useState(false);
  //
  const getProduct = async (id) => {
    const res = await getProductFunc(id)
    if (res.status === 200 || res.status === 201) {
      setForms({
        ...forms,
        productCode: res.data.productCode,
        productName: res.data.productName,
        productImage: res.data.productImage,
        department: res.data.department,
        staffData: res.data.staff,
        staff: res.data.staff?._id,
      });
    } else {
      toast.error("제품정보 가져오기 실패!!");
    }
  }

  useEffect(() => {
    getProduct(id)

  }, [id]);
  //
  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };
  //
  const changeHandler = (checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      // 체크 해제
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
    }
  };
  //
  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  const handleChangeImage = (e) => {
    encodeFileToBase64(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit === false) {
      setEdit(true);
      setImgSrc(forms?.productImage)
      return;
    }
    const formData = new FormData(e.target);
    // formData.append("staff", checkedInputs);
    if (imgSrc === null && forms.productImage != undefined) {
      formData.append("productImage", forms.productImage);
    }

    // console.log(Object.fromEntries(formData.entries()));
    // const newFormData = Object.fromEntries(formData.entries());
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await productUpdateFunc(id, formData, config);
    if (response.status === 200) {
      // console.log(response.data)
      setTimeout(() => {
        toast.success("Successfully Update!");

      }, 1000);
      setForms({
        ...forms,
        productCode: "",
        productName: "",
        productImage: "",
        salesArea: "",
      });
      setCheckedInputs([]);
      setImgSrc(null);
      fileInput.current.value = "";

      navigate("/departmentInfo/" + did, { 
        replace: true, 
        state: { selectSection: false }
      });
    } else {
      if(response?.response?.status === 400) {
        toast.error(response?.response?.data?.message);
      } else {
        toast.error("제품정보 변경실패!!");
      }
    }

  };

  const [listStaff, setListStaff] = useState([])

  const staffsGet = async (id) => {
    const response = await getAllStaffsFunc(id);
    if (response.status === 200) {
      setListStaff(response.data)
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  useEffect(() => {
    staffsGet(did)
  }, [did]);
  useEffect(() => {
    if (!forms?.department) {
      return
    }
    const getStaff = listStaff.filter(
      (staff) => staff.department === forms?.department && staff.position !== "영업" && staff?.isWork
    );
    console.log('getStaff', getStaff);
    setSelectStaff(getStaff);
  }, [forms?.department, listStaff]);

  const handleDelete = async () => {
    const response = await productDeleteFunc(id);
    if (response.status === 200) {
      setTimeout(() => {
        toast.success("Successfully Delete!");
        navigate("/departmentInfo/" + did, { replace: true });
      }, 1000);
    } else {
      toast.error("제품정보 삭제실패!");
    }
  };
  console.log('forms', forms);
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          {edit ? "거래처 제품 수정" : "거래처 제품뷰"}
        </Row>

        <Row className=' m-3'>
          <Col className='text-start mx-0'>
            <Row className='fs-6 fw-bold'>{client.companyName}</Row>
            <Row>
              {forms?.department ? forms?.department : "팀원정보"} | 제품정보
            </Row>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품명
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                size='sm'
                name='productName'
                disabled={!edit}
                onChange={onChange}
                value={forms.productName || ""}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품코드
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                size='sm'
                name='productCode'
                disabled={!edit}
                value={forms.productCode || ""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품이미지
            </Form.Label>
            {edit ? (
              <Col sm='9'>
                <Form.Group controlId='formFile'>
                  <Form.Control
                    type='file'
                    size='sm'
                    name='productImage'
                    ref={fileInput}
                    onChange={handleChangeImage}
                    accept='image/*'
                  />
                </Form.Group>
                <br />
                <span className="text-danger fst-italic" style={{ fontSize: '13px' }}>
                  제품 이미지 업로드는 필수가 아닙니다<br />
                  최적이미지 사이즈 : 800픽셀 * 600픽셀
                </span>
              </Col>
            ) : (
              <Col sm='9'>
                {forms && (
                  <img
                    src={typeof forms?.productImage != "undefined" ? forms?.productImage : defaultImage}
                    alt='img preview'
                    style={{ maxWidth: "200px", height: "auto" }}
                  />
                )}
              </Col>
            )}
          </Form.Group>
          {/* 이미지 프리뷰 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col sm='9'>
              {edit && imgSrc && (
                <img
                  src={imgSrc}
                  alt='img preview'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              담당자/직급
            </Form.Label>
            <Col sm='3' className='flex items-center'>
              {!edit ? (
                forms.staffData ?
                  <span>{forms.staffData?.staffName} ({forms.staffData?.position})</span>
                  : ''
              ) : (
                selectStaff.length > 0
                  ?
                  (
                    <Form.Select size='sm' aria-label="Default select example" value={forms.staff} name="staff" onChange={onChange}>
                      {selectStaff.map((staff) => (
                        <option key={staff?._id} value={staff?._id}>{`${staff.staffName}(${staff.position})`}</option>
                      ))}
                    </Form.Select>
                  )
                  : "팀원정보가 없습니다."
              )}
            </Col>
          </Form.Group>

          <Row className=' mt-5'>
            <Col className='text-start '>
              {
                <Button
                  variant='danger'
                  onClick={handleDelete}
                  className='px-4 '
                >
                  삭제
                </Button>
              }
            </Col>
            <Col className='text-end '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => {
                  navigate("/departmentInfo/" + did);
                }}
              >
                목 록
              </Button>{" "}
              {edit ? (
                <Button variant='primary' type='submit' className='px-4'>
                  저장
                </Button>
              ) : (
                <Button variant='primary' type='submit' className='px-4'>
                  수정
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(ProductView);
