import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bannerRegisterFunc } from "../../api";
import { toast } from "react-toastify";

const BannerRegister = () => {
  const fileInput=useRef();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(null);
  const [forms, setForms] = useState([]);

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };
 // 이미지 뷰어
 const encodeFileToBase64 = (fileBlob) => {
  const reader = new FileReader();
  reader.readAsDataURL(fileBlob);
  return new Promise((resolve) => {
    reader.onload = () => {
      setImgSrc(reader.result);
      resolve();
    };
  });
};

const handleChangeImage = (e) => {
  const selectedFile = e.target.files[0];
  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

  if (selectedFile?.size > maxSize) {
    toast.error('5MB 이하의 파일을 업로드해주세요.');
    if (fileInput.current) {
      fileInput.current.value = ''; // Reset the file input
      setImgSrc("");
    }
  } else {
    encodeFileToBase64(selectedFile);
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  // formData.append("staff", checkedInputs);
  // formData.append("client", client._id);
  // formData.append("department", department[part - 1]);

  // console.log(Object.fromEntries(formData.entries()));
  // const newFormData = Object.fromEntries(formData.entries());
  const config = {
    "Content-Type": "multipart/form-data",
  };

  const response = await bannerRegisterFunc(formData, config);
  
  if (response.status === 200 ||response.status === 201) {
    toast.success("Successfully Register!");
    navigate('/banner');
    setForms({
      ...forms,
      memo: "",
      startDay: "",
      expireDay: "",
      link: "",
    });
    setImgSrc(null);
    fileInput.current.value = "";
  } else {
    toast.error("배너 등록에 실패했습니다.!");
  }

  // await axios
  //   .post(process.env.REACT_APP_API_BASE_URL+"/banner/", formData)
  //   .then((res) => {
  //     setForms({
  //       ...forms,
  //       memo: "",
  //       startDay: "",
  //       expireDay: "",
  //       link: "",
  //     });
  //     setImgSrc(null);
  //     fileInput.current.value = "";
  //   })
  //   .catch((err) => console.log(err));
};
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">배너등록</Row>
        <Form onSubmit={handleSubmit}>
          {/* 게시위치 start */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              게시위치
            </Form.Label>
            <Col sm="9" className="align-items-center">
              {["radio"].map((type) => (
                <div key={`inline-${type}`} value={forms.position} onChange={onChange}>
                  <Form.Check
                    inline
                    label="상단"
                    defaultChecked
                    name="position"
                    type={type}
                    value={'true'}
                  />
                  <Form.Check
                    inline
                    label="하단"
                    name="position"
                    value={'false'}
                    type={type}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger text-start'>
              * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>

          {/* 게시위치 end */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className=" d-flex flex-column">
              배너 이미지
            </Form.Label>
            <Col sm="6">
              <Form.Group controlId="formFile">
                <Form.Control
                  type="file"
                  size="sm"
                  name="bannerImage"
                  ref={fileInput}
                  onChange={handleChangeImage}
                  accept="image/*"
                />
              </Form.Group>
              <small className="mt-2 d-block text-danger text-end">* 최적 사이즈 (375 X 375)</small> 
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}
          {imgSrc && (
            <Form.Group as={Row} className="mb-3 px-3">
              <Form.Label column sm="2">
              </Form.Label>
              <Col sm="9">
                
                  <img
                    src={imgSrc}
                    alt="banner_image"
                    style={{ maxWidth: "200px", height: "auto" }}
                  />
                
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              배너메모
            </Form.Label>
            <Col sm="6">
              <Form.Control
                name="memo"
                onChange={onChange}
                value={forms.memo||""}
                type="text"
                size="sm"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              노출기간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                size="sm"
                name="startDay"
                onChange={onChange}
                value={forms.startDay||""}
                required
              />
            </Col>
            <Col sm="3">
              <Form.Control
                type="date"
                size="sm"
                name="expireDay"
                onChange={onChange}
                value={forms.expireDay||""}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              링크
            </Form.Label>
            <Col sm="6">
              <Form.Control
                name="link"
                onChange={onChange}
                value={forms.link||""}
                type="text"
                size="sm"
                required
              />
            </Col>
          </Form.Group>

          {/* 게시상태 start */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              게시상태
            </Form.Label>
            <Col sm="9" className="align-items-center">
              {["radio"].map((type) => (
                <div key={`inline-${type}`} value={forms.state}onChange={onChange}>
                  <Form.Check
                    inline
                    label="사용"
                    name="state"
                    value={'true'}
                    defaultChecked
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="중지"
                    name="state"
                    value={'false'}

                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          {/* 게시상태 end */}

          <Row>
            {/* <Col className="text-start m-5 ">
              <Button variant="outline-secondary" className="px-4 mx-3">
                삭제
              </Button>{" "}
            </Col> */}
            <Col className="text-end m-5 ">
              <Button variant="outline-secondary" className="px-4 mx-3" onClick={()=>navigate("/banner",{replace:true})}>
                취소
              </Button>{" "}
              <Button as="input" type="submit" value="저장" className="px-4" />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(BannerRegister);
