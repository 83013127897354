import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useState } from "react";
import { tokens } from "../../theme";
import Table from "react-bootstrap/Table";
import { Typography, useTheme } from "@mui/material";

const EventFormDetail = () => {
 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState({
    companyName: "",
    companyCode: "",
    daepyoID: "",
    passwor: "",
    ceoNam: "",
    companyNumbe: "",
    corporationNumbe: "",
    Sector: "",
    busines: "",
    phoneNumbe: "",
    faxNumbe: "",
    zipCod: "",
    addres: "",
    addressDetai: "",
    homePage: "",
  });

  const handleChangeState = (e) => {
    console.log(`${e.target.name}:${e.target.value}`);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {};
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">
         행사완료 등록
        </Row>
        <Form>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.부스 및 행사정보
          </Typography>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              코드
            </Form.Label>
            <Col sm="3">
              <Form.Control
                name="companyName"
                value={state.companyName}
                onChange={handleChangeState}
                type="text"
                size="sm"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"DM(LC)"}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사명칭
            </Form.Label>

            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부스
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"BID" }
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사장소
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              설치/해제시간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사일자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
            <Col sm="3">
              <Form.Control
                type="date"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사시간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                name="companyNumber"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              부스배치도
            </Form.Label>
            <Col sm="6">
            <Form.Group controlId="formFile" >
        <Form.Control type="file" size="sm"/>
      </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9">
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="신규"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="접수"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />
                  <Form.Check
                    inline
                    label="발송"
                    name="group1"
                    type={type}
                    id={`inline-${type}-3`}
                  />
                  <Form.Check
                    inline
                    // disabled
                    label="취소 "
                    name="group1"
                    type={type}
                    id={`inline-${type}-4`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            2.추가신청정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              방명록
            </Form.Label>

            <Col sm="9">
              <Form.Control
                type="text"
                name="Sectors"
                value={state.Sectors}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
          {/* 전화번호 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="9">
            <Form.Label column sm="3">
              010-1234-1234
            </Form.Label>
            </Col>
          </Form.Group>
          {/* 배송방식 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="9">
            <Form.Label column sm="3">
             특이사항 없음
            </Form.Label>
            </Col>
          </Form.Group>
          {/* 주소코드 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              물품 픽업 일시
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
            <Col sm="3">
              <Form.Control
                type="date"
                onChange={handleChangeState}
                value={state.companyNumber}
                size="sm"
              />
            </Col>
          </Form.Group>

        
            {/* 특이사항 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              지시사항
            </Form.Label>
            <Col sm="9">
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
       
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
    
            </Col>
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            3.행사신청품목
          </Typography>

          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">물품명</th>
                <th className="text-center">품목</th>
                <th className="text-center">구분</th>
                <th className="text-center">주문수량</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td>3</td>
                <td>물품명</td>
                <td>DM(LC)</td>
                <td>판촉물</td>
                <td>100(EA)</td>
              </tr>
              <tr className="text-center">
                <td>2</td>
                <td>물품명</td>
                <td>DM(LC)</td>
                <td>판촉물</td>
                <td>100(EA)</td>
              </tr>
              <tr className="text-center">
                <td>1</td>
                <td>물품명</td>
                <td>DM(LC)</td>
                <td>판촉물</td>
                <td>100(EA)</td>
              </tr>
            </tbody>
          </Table>

          {/* 주문자정보 */}
          <hr/>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            4.주문자정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              거래처명
            </Form.Label>
            <Col sm="9">
            <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"(주)아반소프트" }
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"DM(LC)"}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              주문부서
            </Form.Label>

            <Col sm="9">
            <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"마케팅" }
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              신청인
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"BID" }
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              전화번호
            </Form.Label>
            <Col sm="3">
            <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"010-0000-0000" }
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              특이사항
            </Form.Label>
            <Col sm="3">
            <Form.Label column sm="9" style={{ fontSize: 16 }}>
                {"특이사항 없음" }
              </Form.Label>
            </Col>
          </Form.Group>
         
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            5.관리자정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              행사설치사진
            </Form.Label>
            <Col sm="3">
            <Form.Group controlId="formFile" >
        <Form.Control type="file" size="sm"/>
      </Form.Group>
            </Col>
          </Form.Group>
          {/* 박스 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              설치자
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="text"
                // placeholder="대"
                value={state.homePage}
                onChange={handleChangeState}
                size="sm"
              />
           
            </Col>
          </Form.Group>
          {/* 택배 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              관리자
            </Form.Label>
            <Col sm="3">
            <Form.Control
                type="text"
                // placeholder="대"
                value={state.homePage}
                onChange={handleChangeState}
                size="sm"
              />
            </Col>
          </Form.Group>
       
          <hr/>
          <Row className="mt-5">
            <Col className="text-start ">
              <Button variant="primary" className="px-4 mx-3">
               엑셀다운로드
              </Button>{" "}
              <Button variant="outline-secondary" className="px-4 mx-3">
                삭제
              </Button>{" "}
              
            </Col>
            <Col className="text-end ">
              <Button variant="outline-secondary" className="px-4 mx-3">
                목 록
              </Button>{" "}
              <Button as="input" type="submit" value="저장"className="px-4 " />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};


EventFormDetail.defaultProps = {
  title: "",
  };

export default memo(EventFormDetail);
