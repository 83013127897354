export function cleanXSS(value) {
  let returnVal = value;
  // returnVal = returnVal.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  // returnVal = returnVal.replace(/\(/g, "&#40;").replace(/\)/g, "&#41;");
  // returnVal = returnVal.replace(/'/g, "&#39;");
  returnVal = returnVal.replace(/eval\((.*)\)/g, "");
  returnVal = returnVal.replace(/["'][\s]*javascript:(.*)["']/gi, '""');
  returnVal = returnVal.replace(/script/gi, "");
  returnVal = returnVal.replace(/iframe/gi, "");
  returnVal = returnVal.replace(/embed/gi, "");
  return returnVal;
}

export function formatDateToYYYYMMDD(dateString) {
  const date = new Date(dateString);

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // getMonth()는 0부터 시작하므로 1을 더해줍니다.
  const dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

export const truncateString = (str) => {
  if (str.length < 15) {
    return str;
  }
  return str.substring(0, 15) + "...";
};

export function formatDateToYYYYMMDDHHMM(dateFormat) {
  const date = new Date(dateFormat);
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const dd = date.getDate().toString().padStart(2, '0');

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${yyyy}-${mm}-${dd} ${hours}:${minutes}`;
}

export const isContentEmpty = (htmlContent) => {
  const div = document.createElement('div');
  div.innerHTML = htmlContent;

  const cleanedContent = div.textContent || div.innerText || '';
  
  return cleanedContent.trim() === '';
};