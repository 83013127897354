import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Form from "react-bootstrap/Form";

import { useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { getAllStaffsFunc, getClientFunc, productRegisterFunc } from "../../../api";
import { toast } from "react-toastify";

const ProductRegister = () => {
  const fileInput = useRef();
  const navigate = useNavigate();
  // const client = useSelector((state) => state.client);
  // const staffs = useSelector((state) => state.staffs);
  const [staffs, setStaffs] = useState([])
  const { did } = useParams()
  const [client, setClient] = useState({})
  const [part, setPart] = useState(1);
  const [selectStaff, setSelectStaff] = useState([]);
  const [forms, setForms] = useState([]);
  const [checkedInputs, setCheckedInputs] = useState([]);
  /* preview image*/
  const [imgSrc, setImgSrc] = useState(null);
  // const { department } = client;
  const [department, setDepartment] = useState()

  //
  // 회사정보

  const clientGet = async (id) => {
    const response = await getClientFunc(id);
    if (response.status === 200) {
      // dispatch(getClient(response.data));
      setClient(response.data)
      setDepartment(response.data.department)
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  // 직원정보 
  const staffsGet = async (id) => {
    const response = await getAllStaffsFunc(id);
    if (response.status === 200) {
      setStaffs(response.data)
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  useEffect(() => {
    clientGet(did)
    staffsGet(did)
  }, [did]);
  useEffect(() => {
    if (!department) {
      return
    }
    const getStaff = staffs.filter(
      (staff) => staff.department === department[part - 1]
    );
    setSelectStaff(getStaff);
    // console.log("getStaff", getStaff);
  }, [department, staffs, part]);
  //
  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };
  //
  // const changeHandler = (checked, id) => {
  //   if (checked) {
  //     setCheckedInputs([...checkedInputs, id]);
  //   } else {
  //     // 체크 해제
  //     setCheckedInputs(checkedInputs.filter((el) => el !== id));
  //   }
  // };
  //
  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  const handleChangeImage = (e) => {
    encodeFileToBase64(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // formData.append("staff", checkedInputs);
    formData.append("client", client._id);
    formData.append("department", department[part - 1]);

    // console.log(Object.fromEntries(formData.entries()));
    // const newFormData = Object.fromEntries(formData.entries());
    const config = {
      "Content-Type": "multipart/form-data",
    };
    const res = await productRegisterFunc(formData, config)

    if (res.status === 201 || res.status === 200) {
      toast.success("Successfully Register!");
      setForms({
        ...forms,
        productCode: "",
        productName: "",
        productImage: "",
        // salesArea: "",
      });

      // setCheckedInputs([]);
      setImgSrc(null);
      fileInput.current.value = "";
      navigate("/departmentInfo/" + did);
    } else {
      console.log(res)
      if(res?.response?.status === 400) {
        toast.error(res?.response?.data?.error);
      } else {
        toast.error("제품등록에 실패했습니다.!");
      }
    }
    // await axios
    //   .post(process.env.REACT_APP_API_BASE_URL+"/product/register-product/", formData)
    //   .then((res) => {
    //     setForms({
    //       ...forms,
    //       productCode: "",
    //       productName: "",
    //       productImage: "",
    //       salesArea: "",
    //     });
    //     setCheckedInputs([]);
    //     setImgSrc(null);
    //     fileInput.current.value = "";
    //   })
    //   .catch((err) => console.log(err));
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          {client.companyName} 제품등록
        </Row>

        {/* <Row className=' my-5'>
          <Col className='text-start '>
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/partnerView");
              }}
              className='px-4 mx-2'
            >
              거래처정보
            </Button>{" "}
            <Button variant='outline-secondary' className='px-4 mx-2'>
              배송지정보
            </Button>{" "}
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/departmentInfo");
              }}
              className='px-4 mx-2'
            >
              부서정보
            </Button>{" "}
          </Col>
        </Row> */}

        <Row className=' my-5 mx-1'>
          <Col className='text-satrt ' sm='12'>
            {department?.map((it, idx) => (
              <Button
                key={idx}
                variant='light'
                name={it}
                active={part === idx + 1 ? true : false}
                className='px-4 mx-2 mb-2 border'
                onClick={() => {
                  setPart(idx + 1);
                }}
              >
                {it}
              </Button>
            ))}
          </Col>
        </Row>

        <Row className=' m-3'>
          <Col className='text-start mx-0'>
            {/* <Row className='fs-6 fw-bold'>{client.companyName}</Row> */}
            {/* <Row>{part ? department[part - 1] : "팀원정보"} | 제품정보</Row> */}
          </Col>
        </Row>

        <Form onSubmit={handleSubmit}>
          {/* <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              업체명
            </Form.Label>
            <Col sm='9'>
              <Form.Select aria-label='Default select example' size='sm' name="client">
                <option value='1'>(주)아반제약</option>
                <option value='2'>아주제약</option>
                <option value='3'>Three</option>
              </Form.Select>
              <Form.Control type='text' size='sm' name="client" />
            </Col>
          </Form.Group> */}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품명
            </Form.Label>
            <Col sm='9'>
              {/* <Form.Select aria-label='Default select example' size='sm'>
                <option value='1'>아나리카</option>
                <option value='2'>양치키트</option>
                <option value='3'>Three</option>
              </Form.Select> */}
              <Form.Control
                type='text'
                size='sm'
                name='productName'
                onChange={onChange}
                value={forms.productName || ""}
                required
              />
            </Col>
          </Form.Group>

          {/* <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품코드
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                size='sm'
                name='productCode'
                value={forms.productCode ||""}
                onChange={onChange}
                required
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              제품이미지
            </Form.Label>
            <Col sm='9'>
              <Form.Group controlId='formFile'>
                <Form.Control
                  type='file'
                  size='sm'
                  name='productImage'
                  ref={fileInput}
                  onChange={handleChangeImage}
                  accept='image/*'
                // required
                />
                <br />
                <span className="text-danger fst-italic" style={{ fontSize: '13px' }}>
                  제품 이미지 업로드는 필수가 아닙니다<br />
                  최적이미지 사이즈 : 800픽셀 * 600픽셀
                </span>
              </Form.Group>
            </Col>
          </Form.Group>
          {/* 이미지 프리뷰 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col sm='9'>
              {imgSrc && (
                <img
                  src={imgSrc}
                  alt='img preview'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              담당자/직급
            </Form.Label>
            <Col sm='3' className='flex items-center w-full'>
              {selectStaff.length > 0 ?
                (<Form.Select aria-label="Default select example" size='sm' name="staff" onChange={onChange}>
                  {selectStaff?.filter((staff) => (staff.position !== "영업")).map((staff) => (
                    <option key={staff._id} value={staff._id}>{`${staff.staffName}(${staff.position})`}</option>
                  ))}
                </Form.Select>)
                : "팀원정보가 없습니다."}

            </Col>
            {/*  <Col sm='9' className='flex items-center'>
              
              {selectStaff.length > 0
                ? selectStaff?.map((staff) => (
                    <div key={staff._id}>
                      <Form.Check
                        inline
                        label={`${staff.staffName}(${staff.position})`}
                        type={"checkbox"}
                        id={staff._id}
                        onChange={(e) => {
                          changeHandler(e.target.checked, staff._id);
                        }}
                        checked={
                          checkedInputs.includes(staff._id) ? true : false
                        }
                      />
                    </div>
                  ))
                : "팀원정보가 없습니다."}
            </Col> */}
          </Form.Group>

          <Row className=' mt-5'>

            <Col className='text-end '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => {
                  navigate("/departmentInfo/" + did);
                }}
              >
                취 소
              </Button>{" "}
              {<Button variant='primary' type='submit' className='px-4 ' disabled={selectStaff.length === 0}>
                저 장
              </Button>}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(ProductRegister);
