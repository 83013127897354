import React, {memo, useEffect, useState} from 'react'
import DashboardCard from '../../components/DashboardCard'
import { Typography } from "@mui/material";
import { Container, Row, Col } from 'react-bootstrap'
import '../../components/dashboard-card.css'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {getAllProductFunc,getDashboard} from "../../api";
import {toast} from "react-toastify";

const Dashboarlist = () => {
  const [data, setData] = useState([
    {
      img: HistoryEduOutlinedIcon,
      title: '행사신청',
      number: '0'
    },
    {
      img: ModeEditOutlineOutlinedIcon,
      title: '행사수정',
      number: '0'
    },
    {
      img: EventNoteOutlinedIcon,
      title: '행사취소',
      number: '0'
    },
    {
      img: Inventory2OutlinedIcon,
      title: '물품신청',
      number: '0'
    },
    {
      img: EventNoteOutlinedIcon,
      title: '물품신청취소',
      number: '0'
    },
    // {
    //   img: EventNoteOutlinedIcon,
    //   title: '입고신청',
    //   number: '0'
    // },
  ]);

  const dashboard = async (id) => {
    const response = await getDashboard(id);
    if (response.status === 200) {
      setData([
        {
          img: HistoryEduOutlinedIcon,
          title: '행사신청',
          number: response.data.count1
        },
        {
          img: ModeEditOutlineOutlinedIcon,
          title: '행사수정',
          number: response.data.count2
        },
        {
          img: EventNoteOutlinedIcon,
          title: '행사취소',
          number: response.data.count3
        },
        {
          img: Inventory2OutlinedIcon,
          title: '물품신청',
          number: response.data.count4
        },
        {
          img: EventNoteOutlinedIcon,
          title: '물품신청취소',
          number: response.data.count5
        },
        // {
        //   img: EventNoteOutlinedIcon,
        //   title: '입고신청',
        //   number: response.data.count4
        // },
      ]);
    }
  };
  useEffect(() => {
    dashboard()
  }, []);
  return (
    <>

      <Row className='py-3 mt-5  border dash__list'>

        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ padding: "5px 0px 10px 30px" }}
        >
          업무현황
        </Typography>
        <div className='dashboard-wrapper'>
          {data.map((it, idx) => (

            <DashboardCard key={idx} prop={it} />
          ))}

        </div>
      </Row>
    </>
  )
}

export default memo(Dashboarlist)
