import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { getAllEventRequestFunc } from "../../api";
import { toast } from "react-toastify";
import { Col, Row, Table } from "react-bootstrap";
import Pagenation from "../../components/Pagenation";
import EventFormHead from "./EventFormHead";
import * as XLSX from 'xlsx';
import { BASE_URL } from "../../api/helper";
import useStoreLocal from "../../hook/useStoreLocal.hook";
import { EVENT_STATUS, KEY_EVENTLIST } from "../../utils/constant";

const EventForm = () => {

  const navigate = useNavigate()
  const [data, setData] = useState([])

  const [searchStartDay, setSearchStartDay] = useState("")
  const [sendStartDay, setSendStartDay] = useState("")
  const [sendEndDay, setSendEndDay] = useState("")
  const [searchEndDay, setSearchEndDay] = useState("")
  const [selectClient, setSelectClient] = useState("All")
  const [selectDepartment, setSelectDepartment] = useState("All")
  const [selectProduct, setSelectProduct] = useState("All")
  const [search, setSearch] = useState("")
  const [searchState, setSearchState] = useState({
    신규: false,
    접수: false,
    완료: false,
    취소: false,
  })
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [searchQuery, setSearchQuery, backPage, setValueBackPage] = useStoreLocal(KEY_EVENTLIST, {});

  let trueStates = Object.keys(searchState).filter(key => searchState[key]);

  const searchData = { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, trueStates, sendStartDay, sendEndDay, page }

  const allEventRequestGet = async (searchData) => {
    const response = await getAllEventRequestFunc(searchData);
    if (response.status === 200) {

      setData(() => response?.data?.data);
      setPageCount(() =>
        response?.data?.Pagination?.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination?.count,
        pageItem: response?.data?.Pagination?.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    // 전체 물품 리스트
    if (!backPage) {
      allEventRequestGet(searchData);
    }
    setSearchQuery({ ...searchData, ...searchQuery, page: page })
  }, [page]);

  const handleSearchFnc = async (e) => {
    e.preventDefault();
    handleSearchChange();
    allEventRequestGet(searchData)
    setPage(1);
  }

  useEffect(() => {
    if (backPage) {
      const {
        searchStartDay,
        searchEndDay,
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        trueStates,
        sendStartDay,
        sendEndDay,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      searchStartDay && setSearchStartDay(searchStartDay);
      searchEndDay && setSearchEndDay(searchEndDay);
      search && setSearch(search);
      sendStartDay && setSendStartDay(sendStartDay);
      sendEndDay && setSendEndDay(sendEndDay);
      page && setPage(page);

      if (trueStates?.length) {
        const newStates = trueStates.reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})

        setSearchState(checkedState => ({ ...checkedState, ...newStates }));
      }

      allEventRequestGet({ ...searchData, ...searchQuery });
      setValueBackPage(false);
    }
  }, [searchQuery, backPage]);

  const handleSearchChange = () => {
    setSearchQuery(searchData);
  };

  const exportToExcel = () => {
    const { searchStartDay, searchEndDay, selectClient, selectDepartment, selectProduct, search, trueStates: searchState, sendStartDay, sendEndDay, page } = searchData
    const url = `${BASE_URL}/event/eventrequest/excel2?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&searchState=${encodeURIComponent(trueStates)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`;
    const outputFilename = '행사완료내역.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Container className='align-items-center justify-content-center p-5 w-100 mw-100'>

        <EventFormHead
          handleSearchFnc={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          sendStartDay={sendStartDay}
          sendEndDay={sendEndDay}
          setSendStartDay={setSendStartDay}
          setSendEndDay={setSendEndDay}
          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
          search={search} />

        <Table bordered striped hover>
          <thead>
            <tr>
              <th className='text-center'>상태</th>
              <th className='text-center'>행사일자</th>
              <th className='text-center'>행사지역</th>
              <th className='text-center'>행사장소</th>

              <th className='text-center'>거래처명</th>
              <th className='text-center'>부서</th>
              <th className='text-center'>제품명</th>
              <th className='text-center'>신청인</th>

              <th className='text-center'>번호</th>
              <th className='text-center'>접수일자</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/eventformdetail/" + item._id, {state: { isEvent: true }})}
              >
                <td> {item?.eventState && item?.eventState === "신규" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-green-800">
                  {item.eventState}
                </span>) : item?.eventState === "접수" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {item.eventState}
                </span>) : item?.eventState === "완료" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                  {item.eventState}
                </span>) : item?.eventState === "수정" ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-300 text-green-800">
                  {item.eventState}
                </span>) : item?.eventState === EVENT_STATUS.CANCEL ? (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-300 text-green-800">
                  {item.eventState}
                </span>) : (<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-400 text-slate-600">
                  {'취완'}
                </span>)}</td>
                <td>{new Date(item.eventStart).toLocaleDateString()} ~ {new Date(item.eventEnd).toLocaleDateString()}</td>
                <td>{item.eventCity}</td>
                <td>{item.eventLocation}</td>
                <td>{item.companyName}</td>
                <td>{item.department}</td>
                <td>{item?.productId?.productName}</td>
                <td>{item.staffName}</td>

                <td>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td>{item.createdAt.slice(0, 10)}</td>
              </tr>

            ))}
          </tbody>
        </Table>


        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          {/* <Col className='text-end '>
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/noticeRegister");
              }}
            >
              공지사항 등록
            </Button>{" "}
          </Col> */}
        </Row>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />

        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <button className='px-4 mx-2 btn btn-outline-secondary' onClick={exportToExcel}>엑셀다운로드</button>
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(EventForm);
