import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import { URL } from "../api";
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(window?.localStorage.getItem("user")) ?? {},
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout: (state) => {
      console.log("로그아웃")
      state.user = null;
      localStorage?.removeItem("user");
      
    },
  },
});

export const { login, logout} =
authSlice.actions;

export default authSlice.reducer;