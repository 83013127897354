import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { useState, useRef, useEffect, memo } from "react";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {isContentEmpty} from "../../utils"

const NoticeView = () => {
  // state 취득
  const { id } = useParams();
  const editor = useRef(null);
  const fileInput = useRef();
  const navigate = useNavigate();
  // const [imgSrc, setImgSrc] = useState(null);
  const [forms, setForms] = useState([]);
  const [fileName, setfileName] = useState("");
  const [edit, setEdit] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL+"/notice/" + id)
      .then((res) => {
        const { attachFile } = res.data;
        let attachFileName = "";
        if (attachFile) {
          attachFileName = decodeURI(
            attachFile.split("/")[attachFile.split("/").length - 1]
          );
          attachFileName = attachFileName.split("-").pop();
          setfileName(attachFileName);
        }
        setForms((prevForms) => ({
          ...prevForms,
          title: res.data.title,
          attachFile: res.data.attachFile || "",
        }));

        setContent(res.data.content);
      })
      .catch((err) => console.log(err));
  }, []);

  const jodiconfig = {
    disabled: !edit,

    // readonly: true,
  };
  const onChange = (e) => {
    if (e.target.name === "attachFile") {
      const selectedFile = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

      if (selectedFile?.size > maxSize) {
        toast.error('5MB 이하의 파일을 업로드해주세요.');
        fileInput.current.value = "";
        return;
      }
    }
    
    setForms({ ...forms, [e.target.name]: e.target.value });
  };
  // const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!edit) {
      setEdit(true);
      return;
    }
    if (edit && isContentEmpty(content)) {
      toast.error("내용 비워두지 마세요.!");
      return;
    }

    const formData = new FormData(e.target);
    formData.append("content", content);
    // formData.append("boothName", boothName);
    // formData.append("boothContent", content);

    // console.log(Object.fromEntries(formData.entries()));
    await axios
      .put(process.env.REACT_APP_API_BASE_URL+"/notice/"+id, formData)
      .then((res) => {
        // console.log(res.data);
        // setContent("");
        // setForms({ ...forms, title: "", attachFile: "" });
        toast.success('공지사항 수정 성공했습니다.')
        navigate("/notice",{replace:true})
        // fileInput.current.value=""
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = async () => {
    if(window.confirm('삭제하시겠습니까?')){
      await axios
          .delete(process.env.REACT_APP_API_BASE_URL+"/notice/" + id)
          .then((res) => {
            toast.success('공지사항 삭제 성공했습니다.')
            navigate("/notice")
          })
          .catch((err) => console.log("error:" + err));
    }

  };
  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          {edit ? "공지사항 수정" : "공지사항 뷰"}
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='1'>
              제목
            </Form.Label>
            <Col sm='10'>
              <Form.Control
                type='text'
                name='title'
                value={forms.title || ""}
                onChange={onChange}
                disabled={!edit}
                size='sm'
                required
              />
            </Col>
          </Form.Group>

          {/* 특이사항 */}
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              내용
            </Form.Label>
            <Col sm='12'>
              <JoditEditor
                ref={editor}
                value={content}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}
                config={!edit ? jodiconfig : ""}
                required
              />
            </Col>
          </Form.Group>

          {edit && fileName && (
            <Form.Group as={Row} className='mb-3 px-3'>
              <Form.Label column sm='2'>
                파 일
              </Form.Label>
              <Col sm='9' className='flex items-center text-slate-500'>
                {fileName}
              </Col>
            </Form.Group>
          )}

          { edit && <Row>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col lg='6'>
              <small className='mt-3 px-2 d-block text-danger mb-1 text-start'>
                * 5MB를 초과하는 파일은 등록할 수 없습니다.
              </small>
            </Col>
          </Row>}

          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              첨부파일
            </Form.Label>
            <Col sm='9' className='flex items-center'>
              {edit ? (
                <Form.Group controlId='formFile'>
                  <Form.Control
                    type='file'
                    size='sm'
                    name='attachFile'
                    ref={fileInput}
                    onChange={onChange}
                  />
                </Form.Group>
              ) : forms.attachFile ? (
                <a href={forms.attachFile} target='_blink'>
                  {fileName}
                </a>
              ) : (
                "첨부파일이 없습니다."
              )}
            </Col>
          </Form.Group>

          <Row className='mt-5'>
            <Col className='text-start'>
              <Button
                variant='outline-danger'
                className='px-4 mx-3'
                onClick={handleDelete}
              >
                삭제
              </Button>
            </Col>
            <Col className='text-end '>
              <Button
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/notice", { replace: true })}
              >
                취 소
              </Button>
              <Button
                as='input'
                type='submit'
                value={edit ? "저 장" : "수 정"}
                disabled={forms.title === ""}
                className='px-4'
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(NoticeView);
