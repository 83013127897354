import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clientUpdatePasswordFunc } from "../../../api";
import { toast } from "react-toastify";

// TODO: form 검증

const PartnerEditPassWord = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [forms, setForms] = useState([]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData(e.target);
    if(forms.password != forms.passwordConfirm){
        toast.error("새 비밀번호와 재입력 비밀번호가 일치하지 않습니다.");
    }else {
      const response = await clientUpdatePasswordFunc({id,...forms});
      if (response.status === 200) {
        toast.success("비밀번호 변경 성공했습니다");
        navigate("/partnerstable", { replace: true });
      } else {
        toast.error("비밀번호 변경 실패했습니다");
      }
    }
  };

  return (
    <>
      <Container maxWidth='md' className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>비밀번호 변경</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
            새 비밀번호
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='password'
                name='password'
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
            비밀번호 재입력
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='password'
                name='passwordConfirm'
                onChange={onChange}
                size='sm'
              />
            </Col>
          </Form.Group>
          <Row>
            <Col className='text-center '>
              <Button
                type='button'
                variant='outline-secondary'
                className='px-4 mx-3'
                onClick={() => navigate("/partnerView/" + id)}
              >
                취소
              </Button>
              <Button as='input' type='submit' value='확인' className='px-4' />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(PartnerEditPassWord);
