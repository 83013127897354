import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState } from "react";
import { tokens } from "../../theme";
import Table from "react-bootstrap/Table";
import { Typography, useTheme } from "@mui/material";
import { getEventRequestItemFunc, updateEventRequestItemFunc } from "../../api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const EventCompleteDetail = () => {
  const {id}= useParams()
  const theme = useTheme();
  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode);
  const [data,setData]= useState({})
  const [state,setState]=useState("신규")
  const [preview,setPreview]=useState("")
  const [imgSrc, setImgSrc] = useState("");
  const [installPreview, setInstallPreview] = useState("");
 
  const [forms, setForms] = useState({
    eventCode: "",
    productName: "",
    eventName: "",
    boothName: "",
    eventCity: "",
    disassembly: "",
    eventStart: "",
    eventEnd: "",
    eventTime: "",
    eventImage: "",
    guestBook: "",
    installer: "",
    manager:"",
    totalPrice:0,
    pickupDay: "",
    pickupTime: "",
  });

  const handleChangeImage = (e) => {
    // encodeFileToBase64(e.target.files[0]);
    setImgSrc(e.target.files[0]);
  };


  // 이미지 뷰어
  useEffect(() => {
    if (imgSrc) {
      setInstallPreview(URL.createObjectURL(imgSrc));
    }
  }, [imgSrc]);


  const handleChangeState = (e) => {
    // console.log(`${e.target.name}:${e.target.value}`);
    setForms({
      ...forms,
      [e.target.name]: e.target.value,
    });
  };

useEffect(() => {
     // 전체 물품 리스트
     const EventRequestItemGet = async (id) => {
      const response = await getEventRequestItemFunc(id);
      if (response.status === 200) {

        setData(response.data)

        setForms({...forms, eventCode: response.data.eventCode || "",
        productName: response.data.productId.productName,
        eventName: response.data.eventName,
        boothName: response.data.boothId.boothName,
        eventCity: response.data.eventCity,
        disassembly:response.data.disassembly,
        eventStart: response.data.eventStart,
        eventEnd: response.data.eventEnd,
        eventTime: response.data.eventTime,
        eventState:response.data.eventState,
        eventImage: "",
        guestBook: response.data.guestBook,
        installer: response.data.installer||"",
        manager: response.data.manager||"",
        totalPrice: response.data.totalPrice||0,
        pickupDay: response.data.pickupDay,
        pickupTime: response.data.pickupTime,
        directions:response.data.directions,
        memo:result.memo,})
        setPreview(response.data.eventImage)
        setInstallPreview(response.data.InstallationPhoto)
      } else {
        toast.error("행사요청을 가져오는데 실패했습니다.");
      }
    };
    const result =  EventRequestItemGet(id)
   
  
}, [id]);

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // formData.append("eventState", state);
    // formData.append("boothName", boothName);
    // formData.append("boothContent", content);

    // console.log(Object.fromEntries(formData.entries()));
    const config = {
      "Content-Type": "multipart/form-data",
    };

    const response = await updateEventRequestItemFunc(id,formData, config);
    if (response.status === 200) {
      toast.success("Successfully Register!");
      // setTimeout(() => {
      //   navigate("/partnerstable", { replace: true });
      // }, 1000);
    } else {
      toast.error("행사요청 업데이트에 실패했습니다.!");
    }
  };
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">
         행사 완료 내역서
        </Row>
        <Form onSubmit={handleSubmit}>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            1.부스 및 행사정보
          </Typography>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              코드
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9">
                {forms.eventCode}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.productName}
              </Form.Label>
           
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              행사명칭
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9">
                {forms.eventName}
              </Form.Label>
            </Col>
           
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              부스
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.boothName }
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              행사장소
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.eventCity }
              </Form.Label>
            </Col>
           
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              설치/해제시간
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.disassembly }
              </Form.Label>
            </Col>
           
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3 flex items-center">
            <Form.Label column sm="2" className="font-bold">
              행사일자
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.eventStart } ~ {forms.eventEnd }
              </Form.Label>
            </Col>
          
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              행사시간
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.eventTime}
              </Form.Label>
            </Col>
          
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              부스배치도
            </Form.Label>
            <Col sm="6">
            <Form.Group controlId="formFile" >
        {preview &&( <div className="flex items-center"><img src={preview} width={"300px"} alt=""/></div>)}
      </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              상태
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.eventState}
              </Form.Label>
            </Col>
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            2.추가신청정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3 flex items-center">
            <Form.Label column sm="2" className="font-bold">
              방명록
            </Form.Label>

            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.guestBook} 개
              </Form.Label>
            </Col>
          
          </Form.Group>
          {/* 전화번호 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              전화번호
            </Form.Label>
            <Col sm="9">
            <Form.Label column sm="3">
            {data ? data?.staff?.phone : ""}
            </Form.Label>
            </Col>
          </Form.Group>
          {/* 배송방식 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              특이사항
            </Form.Label>
            <Col sm="9">
            <Form.Label column sm="3">
             {data ? data.memo : "특이사항 없음"}
            </Form.Label>
            </Col>
          </Form.Group>
          {/* 주소코드 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              물품 픽업 일시
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.pickupDay} / {forms.pickupTime}
              </Form.Label>
            </Col>
           
          </Form.Group>

        
            {/* 특이사항 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              지시사항
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.directions}
              </Form.Label>
            </Col>
          
          </Form.Group>
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "20px 0 15px 0" }}
          >
            3.행사신청품목
          </Typography>

          <Table striped bordered hover >
            <thead>
              <tr>
                <th className="text-center">번호</th>
                <th className="text-center">물품명</th>
                <th className="text-center">품목</th>
                <th className="text-center">구분</th>
                <th className="text-center">주문수량</th>
              </tr>
            </thead>
            <tbody>
              {data && data?.goods?.map((item,idx)=>( <tr key= {item._id}className="text-center">
                <td>{data?.goods?.length - idx}</td>
                <td>{item?.goodsId?.goodsName}</td>
                <td>{item?.goodsId?.productName}</td>
                <td>{item?.goodsId?.category}</td>
                <td>{item?.amount} ({item?.goodsId?.unit})</td>
              </tr>))}
         
            </tbody>
          </Table>

          {/* 주문자정보 */}
          <hr/>
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            4.주문자정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2"  className="font-bold">
              거래처명
            </Form.Label>
            <Col sm="9">
            <Form.Label column sm="9" >
               {data ? data?.staff?.client?.companyName : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2"  className="font-bold">
              품목
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
              {forms?.productName}
              </Form.Label>
              {/* <Form.Control type="text" name="companyCode" value={state.companyCode} onChange={handleChangeState} size="sm" required /> */}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              주문부서
            </Form.Label>

            <Col sm="9">
            <Form.Label column sm="9" >
                {data ? data?.staff?.department : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              신청인
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
              {data ? data?.staff?.staffName : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              전화번호
            </Form.Label>
            <Col sm="3">
            <Form.Label column sm="9" >
            {data ? data?.staff?.phone : ""}
              </Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              특이사항
            </Form.Label>
            <Col sm="3">
            <Form.Label column sm="9" >
            {data ? data?.memo : ""}
              </Form.Label>
            </Col>
          </Form.Group>
         
          <hr />
          <Typography
            variant="h4"
            color={colors.grey[400]}
            sx={{ m: "15px 0 15px 0" }}
          >
            5.관리자정보
          </Typography>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              행사설치사진
            </Form.Label>
          
          </Form.Group>
             {/* 이미지 프리뷰 */}
             {installPreview && (
             <Form.Group as={Row} className='mb-3 px-3'>
            <Form.Label column sm='2'>
              {""}
            </Form.Label>
            <Col sm='9'>
              
                <img
                  src={installPreview}
                  alt='img preview'
                  style={{ maxWidth: "200px", height: "auto" }}
                />
             
            </Col>
          </Form.Group>
           )}
          {/* 박스 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              설치자
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.installer}
              </Form.Label>
            </Col>
           
          </Form.Group>
          {/* 택배 */}
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              관리자
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {forms.manager}
              </Form.Label>
            </Col>
           
          </Form.Group>
        
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2" className="font-bold">
              총금액
            </Form.Label>
            <Col sm="9">
              <Form.Label column sm="9" >
                {Number(forms.totalPrice).toLocaleString()} 원
              </Form.Label>
            </Col>
          
          </Form.Group>
          <hr/>
          <Row className="mt-5">
            <Col className="text-start ">
              {/* <Button variant="primary" className="px-4 mx-3">
               엑셀다운로드
              </Button>{" "} */}
              {/* <Button variant="outline-danger" type="button" className="px-4 mx-3">
                삭제
              </Button>{" "}
               */}
            </Col>
            <Col className="text-end ">
              <Button variant="outline-secondary" type="button" className="px-4 mx-3" onClick={()=>navigate("/eventReport")}>
                목 록
              </Button>{" "}
              {/* <Button as="input" type="submit" value="저장"className="px-4 " />{" "} */}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};


EventCompleteDetail.defaultProps = {
  title: "",
  };

export default memo(EventCompleteDetail);
