import { combineReducers } from "@reduxjs/toolkit";
import clientSlice from "./clientSlice";
import authSlice from "./authSlice";

const rootReducer = combineReducers({
  client: clientSlice,
  user: authSlice,
});

export { rootReducer };
