import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import { memo, useCallback, useEffect, useState } from "react";
import {
  deleteAddressAccordingClient,
  getAddressAccordingClient,
} from "../../../api";
import { toast } from "react-toastify";
import Pagenation from "../../../components/Pagenation";
import ConfirmModal from "../../../components/ConfirmModal";

const PartnerShippingInfo = () => {
  const navigate = useNavigate()
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });

  const [update, setUpdate] = useState(false)
  const render = useCallback(() => {
    setUpdate(!update)
  })

  const [address, setAddress] = useState([]);

  const addressAccordingClient = async (id) => {
    const response = await getAddressAccordingClient(id);
    if (response.status === 200) {
      setAddress(response.data?.data);
      setPageCount(() =>
        response?.data?.Pagination?.pageCount
      );
      setTotalCount((totlaCount) => ({
        ...totlaCount,
        total: response?.data?.Pagination.count,
        pageItem: response?.data?.Pagination.pageItem,
      }));
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };

  //NOTE - 팀원의 정보중 client 검색
  useEffect(() => {
    addressAccordingClient(id);
  }, [id, update]);

  // --------------
  const [showModal, setShowModal] = useState(false);
  const [addressIdDelete, setAddressIdDelete] = useState(null);

  const handleShowModal = (addressId) => {
    setShowModal(true);
    setAddressIdDelete(addressId)
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmAction = async () => {
    handleCloseModal();
    console.log('addressIdDelete', addressIdDelete);
    const response = await deleteAddressAccordingClient(addressIdDelete)
    if (response.status === 200) toast.success(response.data.message)
    else toast.error("Error")
    render()
  };

  return (
    <>
      <Container className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">거래처 배송지 정보</Row>

        <Row className=" my-5">
          <Col className="text-start ">
            <Button variant="outline-secondary" onClick={() => { navigate("/partnerView/" + id) }} className="px-4 mx-2">
              거래처정보
            </Button>{" "}
            <Button variant="outline-secondary" active className="px-4 mx-2">
              배송지정보
            </Button>{" "}
            <Button variant="outline-secondary" onClick={() => { navigate("/departmentInfo/" + id) }} className="px-4 mx-2">
              부서 / 제품 정보
            </Button>{" "}

          </Col>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="text-center">번호</th>
              <th className="text-center">주소</th>
              <th className="text-center">주소별칭</th>
              <th className='text-center'>담당자명/직급</th>
              <th className="text-center">관리</th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:  */}
            {address.map((item, idx) => (<tr key={idx} className="text-center " style={{ verticalAlign: "middle" }}>
              <td>{totlaCount?.total - (page - 1) * totlaCount?.pageItem - idx}</td>
              <td>
                {item.addr1}
                {item.addr2}
              </td>
              <td>{item.addrName}</td>
              <td>
                {item?.staffId && (<span>{item?.staffId?.staffName}{" "}
                  <small>({item?.staffId?.position})</small></span>)}
              </td>
              <td>
                {" "}
                <Button variant="primary" className="px-3 mx-3" onClick={() => { navigate("/shippingInfomodify/" + item._id) }} size="sm">
                  수정
                </Button>{" "}
                <Button variant="secondary" className="px-3" size="sm" onClick={() => handleShowModal(item._id)}>
                  삭제
                </Button>{" "}
              </td>
            </tr>))}

          </tbody>
        </Table>

        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />

        <ConfirmModal
          show={showModal}
          onHide={handleCloseModal}
          onConfirm={handleConfirmAction}
          className="confirm-modal"
          title="Confirm Action"
          message="Are you sure you want to proceed with this action?"
        />

        {/* <Row className=" mt-5">
          <Col className="text-start ">
            <Button variant="outline-secondary" className="px-4 mx-3">
              삭제
            </Button>{" "}
          </Col>
          <Col className="text-end ">
            <Button variant="outline-secondary" className="px-4 mx-3">
              목 록
            </Button>{" "}
            <Button as="input" type="submit" value="저장" className="px-4" />{" "}
          </Col>
        </Row> */}
      </Container>


    </>
  );
};

export default memo(PartnerShippingInfo);
