import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { getAllEventRequestFunc } from "../../api";
import { toast } from "react-toastify";
import { Col, Row, Table } from "react-bootstrap";
import Pagenation from "../../components/Pagenation";
import EventReportHead from "./EventReportHead";
import * as XLSX from 'xlsx';
import {BASE_URL} from "../../api/helper";
import { KEY_EVENTREPORT } from "../../utils/constant";
import useStoreLocal from "../../hook/useStoreLocal.hook";

const EventReport = () => {

  const navigate = useNavigate()
  const [data,setData]=useState([])
  console.log("data",data)
  const [searchStartDay,setSearchStartDay] = useState("")
  const [sendStartDay,setSendStartDay] = useState("")
  const [sendEndDay,setSendEndDay] = useState("")
  const [searchEndDay,setSearchEndDay] = useState("")
  const [selectClient,setSelectClient] = useState("All")
  const [selectDepartment,setSelectDepartment] = useState("All")
  const [selectProduct,setSelectProduct] = useState("All")
  const [search,setSearch] = useState("")
  const [searchState,setSearchState] = useState({신규: false,
    접수: false,
    완료: true,
    취소: false,})
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });
  const [searchQuery, setSearchQuery, backPage, setValueBackPage] = useStoreLocal(KEY_EVENTREPORT, {});

  // 전체 물품 리스트
   const searchData = {searchStartDay ,searchEndDay ,selectClient,selectDepartment,selectProduct,search,trueStates:"완료",page,sendStartDay,sendEndDay}

  const allEventRequestGet = async (searchData) => {
    const response = await getAllEventRequestFunc(searchData);
    if (response.status === 200) {

       setData(()=>response?.data?.data);
       setPageCount(()=>
        response?.data?.Pagination?.pageCount
      );
       setTotalCount((totlaCount)=>({
        ...totlaCount,
        total: response?.data?.Pagination?.count,
        pageItem: response?.data?.Pagination?.pageItem,
      }));
    } else {
      toast.error("전체물품을 가져오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    if (!backPage) {
      allEventRequestGet(searchData)
    }
    allEventRequestGet({ ...searchData, ...searchQuery, page: page })
  }, [page]);

  useEffect(() => {
    if (backPage) {
      const {
        searchStartDay,
        searchEndDay,
        selectClient,
        selectDepartment,
        selectProduct,
        search,
        sendStartDay,
        sendEndDay,
        page
      } = searchQuery;

      selectClient && setSelectClient(selectClient);
      selectDepartment && setSelectDepartment(selectDepartment);
      selectProduct && setSelectProduct(selectProduct);
      searchStartDay && setSearchStartDay(searchStartDay);
      searchEndDay && setSearchEndDay(searchEndDay);
      search && setSearch(search);
      sendStartDay && setSendStartDay(sendStartDay);
      sendEndDay && setSendEndDay(sendEndDay);
      page && setPage(page);

      allEventRequestGet({ ...searchData, ...searchQuery });
      setValueBackPage(false);
    }
  }, [searchQuery, backPage]);


  const handleSearchFnc = async(e)=>{
    // 버튼이 눌리면 주문기간,구분,상태,검색을 api에 던져서 나온값을 data에 넣어준다.
    e.preventDefault();
    handleSearchChange();
    allEventRequestGet(searchData)
  }

  const handleSearchChange = () => {
    setSearchQuery(searchData);
  };

  const exportToExcel = () => {

    const url = `${BASE_URL}/event/eventrequest/excel?search=${encodeURIComponent(search)}&searchStartDay=${encodeURIComponent(searchStartDay)}&searchEndDay=${encodeURIComponent(searchEndDay)}&selectClient=${encodeURIComponent(selectClient)}&selectDepartment=${encodeURIComponent(selectDepartment)}&selectProduct=${encodeURIComponent(selectProduct)}&page=${encodeURIComponent(page)}&sendStartDay=${encodeURIComponent(sendStartDay)}&sendEndDay=${encodeURIComponent(sendEndDay)}`;
    const outputFilename = '행사완료내역.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();


  };

  return (
    <>
      <Container className='align-items-center justify-content-center p-5 w-100 mw-100'>

        <EventReportHead
        handleSearchFnc ={handleSearchFnc}
          setSelectClient={setSelectClient}
          selectClient={selectClient}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          sendStartDay={sendStartDay}
          sendEndDay={sendEndDay}
          setSendStartDay={setSendStartDay}
          setSendEndDay={setSendEndDay}
          setSearchStartDay={setSearchStartDay}
          searchStartDay={searchStartDay}
          setSearchEndDay={setSearchEndDay}
          searchEndDay={searchEndDay}
          setSearchState={setSearchState}
          searchState={searchState}

          setSearch={setSearch}
        search={search}/>

        <Table bordered striped hover className="max-w-full">
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>접수일자</th>
              <th className='text-center'>거래처명</th>
              <th className='text-center'>부서</th>
              <th className='text-center'>제품명</th>
              <th className='text-center'>신청인</th>
              <th className='text-center'>부스명</th>
              <th className='text-center'>행사명칭</th>
              <th className='text-center'>행사지역</th>
              <th className='text-center'>행사기간</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => navigate("/eventformdetail/" + item._id)}
              >
                <td>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis">{item.createdAt.slice(0,10)}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">{item.companyName}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">{item.department}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">{item?.productId?.productName}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">{item.staffName}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">
                  {
                    item?.booths?.map((it, idx) => (
                      <>
                        <span key={idx}>{it?.boothId?.boothName}</span>
                        <br/>
                      </>
                    ))
                  }
                </td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[350px]">{item?.eventName}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">{item?.eventCity}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">{new Date(item.eventStart).toLocaleDateString()} ~ {new Date(item.eventEnd).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>


        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          {/* <Col className='text-end '>
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/noticeRegister");
              }}
            >
              공지사항 등록
            </Button>{" "}
          </Col> */}
        </Row>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />

        <Row className='mt-3 mb-3 text-end'>
          <Col>
            <button className='px-4 mx-2 btn btn-outline-secondary' onClick={exportToExcel}>엑셀다운로드</button>
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default memo(EventReport);
