export const KEY_EVENTLIST = 'KEY_EVENTLIST'
export const KEY_ORDERLIST = 'KEY_ORDERLIST'
export const KEY_BOOTHLIST = 'KEY_BOOTHLIST'
export const KEY_ORDERHISTORY = 'KEY_ORDERHISTORY'
export const KEY_EVENTREPORT= 'KEY_EVENTREPORT'

export const ORDER_STATUS = {
  NEW: "신규",
  RECEIPT: "접수",
  COMPLETE: "발송",
  CANCEL: "취소",
  CANCEL_COMPLETE: "취소완료"
}

export const EVENT_STATUS = {
  NEW: "신규",
  RECEIPT: "접수",
  MODIFY: "수정",
  COMPLETE: "완료",
  CANCEL: "취소",
  CANCEL_COMPLETE: "취소완료"
}