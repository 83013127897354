import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { popupRegisterFunc } from "../../api";

const PopupRegister = () => {
  const fileInput = useRef();
  const fileInput2 = useRef();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(null);
  const [forms, setForms] = useState({ type: '1' });
  const [imgSrc2, setImgSrc2] = useState(null);

  const onChange = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    const name = e.target.name;
    const value = e.target.value;
  };
  // 이미지 뷰어
  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  // 이미지 뷰어
  const encodeFileToBase64MB = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc2(reader.result);
        resolve();
      };
    });
  };

  const handleChangeImage = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('10MB 이하의 파일을 업로드해주세요.');
      if (fileInput.current) {
        fileInput.current.value = ''; // Reset the file input
        setImgSrc("");
      }
    } else {
      encodeFileToBase64(selectedFile);
    }
  };

  const handleChangeImage2 = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

    if (selectedFile?.size > maxSize) {
      toast.error('10MB 이하의 파일을 업로드해주세요.');
      if (fileInput2.current) {
        fileInput2.current.value = ''; // Reset the file input
        setImgSrc2("");
      }
    } else {
      encodeFileToBase64MB(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const config = {
      "Content-Type": "multipart/form-data",
    };

    if (forms.expireDay && forms.startDay) {
      const startDay = new Date(forms.startDay);
      const expireDay = new Date(forms.expireDay);
      if (expireDay < startDay) {
        toast.error('만료일은 시작일보다 크거나 같아야 합니다.')
        return
      }
    }

    const response = await popupRegisterFunc(formData, config);

    if (response.status === 200 || response.status === 201) {
      toast.success("Successfully Register!");
      navigate('/popup');
      setForms({
        ...forms,
        startDay: "",
        expireDay: "",
        link: "",
      });
      setImgSrc(null);
      setImgSrc2(null);
      fileInput.current.value = "";
    } else {
      if (response?.response?.data?.error) {
        toast.error(response?.response?.data?.error);

      } else {
        toast.error("배너 등록에 실패했습니다.!");
      }
    }

  };
  return (
    <>
      <Container maxWidth="md" className="py-3">
        <Row className="fs-1 fw-bold p-2 border-bottom mb-5">팝업등록</Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              유형
            </Form.Label>
            <Col sm="9" className="align-items-center">
              {["radio"].map((type) => (
                <div key={`inline-${type}`} value={forms.type} onChange={onChange}>
                  <Form.Check
                    inline
                    label="이미지"
                    name="type"
                    value={'1'}
                    defaultChecked
                    type={type}
                    id={`use-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="제목 + 내용"
                    name="type"
                    value={'2'}
                    type={type}
                    id={`use-${type}-2`}
                  />
                  <Form.Check
                    inline
                    label="이미지 + 제목"
                    name="type"
                    value={'3'}
                    type={type}
                    id={`use-${type}-3`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          {forms?.type !== '1' &&
            <>
              <Form.Group as={Row} className="mb-3 px-3">
                <Form.Label column sm="2">
                  제목
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="title"
                    onChange={onChange}
                    value={forms.title || ""}
                    type="text"
                    size="sm"
                  />
                </Col>
              </Form.Group>

            </>
          }
          {forms?.type === '2' && <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              내용
            </Form.Label>
            <Col sm="6">
              <Form.Control
                name="content"
                as="textarea"
                onChange={onChange}
                value={forms.content || ""}
                type="text"
                size="sm"
                rows={4}
              />
            </Col>
          </Form.Group>}

          {/* 게시위치 end */}
          {
            forms?.type !== '2' &&
            <>
              <Row>
                <Form.Label column sm='2'>
                  {""}
                </Form.Label>
                <Col lg='6'>
                  <small className='mt-3 px-2 d-block text-danger text-start'>
                    * 10MB를 초과하는 파일은 등록할 수 없습니다.
                  </small>
                </Col>
              </Row>
              <Form.Group as={Row} className="mb-3 px-3">
                <Form.Label column sm="2" className=" d-flex flex-column">
                  이미지 PC
                </Form.Label>
                <Col sm="6">
                  <Form.Group controlId="formFile">
                    <Form.Control
                      type="file"
                      size="sm"
                      name="imagePC"
                      ref={fileInput}
                      onChange={handleChangeImage}
                      accept="image/*"
                    />
                  </Form.Group>
                  <small className="mt-2 d-block text-danger text-end">* 최적 사이즈 ({forms?.type === '1' ? 400 : 360} X 400)</small>
                </Col>
              </Form.Group>
            </>
          }
          {/* 이미지 프리뷰 */}
          {imgSrc && (
            <Form.Group as={Row} className="mb-3 px-3">
              <Form.Label column sm="2">
              </Form.Label>
              <Col sm="9">

                <img
                  src={imgSrc}
                  alt="banner_image"
                  style={{ maxWidth: "200px", height: "auto" }}
                />

              </Col>
            </Form.Group>
          )}

          {/* 게시위치 end */}
          {
            forms?.type !== '2' &&
            <>
              <Row>
                <Form.Label column sm='2'>
                  {""}
                </Form.Label>
                <Col lg='6'>
                  <small className='mt-3 px-2 d-block text-danger text-start'>
                    * 10MB를 초과하는 파일은 등록할 수 없습니다.
                  </small>
                </Col>
              </Row>
              <Form.Group as={Row} className="mb-3 px-3">
                <Form.Label column sm="2" className=" d-flex flex-column">
                  이미지 모바일
                </Form.Label>
                <Col sm="6">
                  <Form.Group controlId="formFile">
                    <Form.Control
                      type="file"
                      size="sm"
                      name="imageMB"
                      ref={fileInput2}
                      onChange={handleChangeImage2}
                      accept="image/*"
                    />
                  </Form.Group>
                  {/* <small className="mt-2 d-block text-danger text-end">* 최적 사이즈 (375 X 375)</small> */}
                </Col>
              </Form.Group>
            </>
          }
          {/* 이미지 프리뷰 */}
          {imgSrc2 && (
            <Form.Group as={Row} className="mb-3 px-3">
              <Form.Label column sm="2">
              </Form.Label>
              <Col sm="9">

                <img
                  src={imgSrc2}
                  alt="banner_image"
                  style={{ maxWidth: "200px", height: "auto" }}
                />

              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              노출기간
            </Form.Label>
            <Col sm="3">
              <Form.Control
                type="date"
                size="sm"
                name="startDay"
                onChange={onChange}
                value={forms.startDay || ""}
                required
              />
            </Col>
            <Col sm="3">
              <Form.Control
                type="date"
                size="sm"
                name="expireDay"
                onChange={onChange}
                value={forms.expireDay || ""}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              링크
            </Form.Label>
            <Col sm="6">
              <Form.Control
                name="link"
                onChange={onChange}
                value={forms.link || ""}
                type="text"
                size="sm"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 px-3">
            <Form.Label column sm="2">
              상태
            </Form.Label>
            <Col sm="9" className="align-items-center">
              {["radio"].map((type) => (
                <div key={`inline-${type}`} value={forms.state} onChange={onChange}>
                  <Form.Check
                    inline
                    label="활성"
                    name="state"
                    value={'true'}
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label="비활성"
                    name="state"
                    value={'false'}
                    defaultChecked
                    type={type}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>

          <Row>
            <Col className="text-end m-5 ">
              <Button variant="outline-secondary" className="px-4 mx-3" onClick={() => navigate("/popup", { replace: true })}>
                취소
              </Button>{" "}
              <Button as="input" type="submit" value="저장" className="px-4" />{" "}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default memo(PopupRegister);
