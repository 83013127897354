import React, { useEffect, useState, useRef } from "react";
import { getDeliveryOrderItemFunc } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEventRequestItemFunc } from "../api";
const PrintEvent = React.forwardRef((props, ref) => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	useEffect(() => {
		const eventRequestItemGet = async (id) => {
			const response = await getEventRequestItemFunc(id);
			if (response.status === 200) {
				response.data.updatedAt = response.data.updatedAt.slice(0, 10);
				setData(response.data);
				const { attachFile } = response.data;
				let attachFileName = "";
				// if (attachFile) {
				// 	attachFileName = decodeURI(
				// 		attachFile.split("/")[attachFile.split("/").length - 1]
				// 	);
				// 	attachFileName = attachFileName.split("-").pop();
				// 	setFileName(attachFileName);
				// }
			} else {
				console.log("error");
			}
		};
		eventRequestItemGet(id);
	}, [id]);
	console.log(data);
	return (
		<div ref={ref}>
			<div key={1}  className="print_wapper">
				<div className="paper">
					<div className="content">
						<div className="header">
							<h1>행사 신청서</h1>
						</div>
						<div className="row" style={{
							gridTemplateColumns: "repeat(4, minmax(0,1fr) max-content)",
							maxWidth: "102%",
							boxSizing: "border-box"
						}}>
							<p className="title">신청번호</p>
							<p className="half">{data.eventCode}</p>
							<p className="title">신청일자</p>
							<p>{data.updatedAt}</p>
							<hr/>
							<p className="title">회사명</p>
							<p className="half">{data.companyName}</p>
							<p className="title">부서명</p>
							<p>{data.department}</p>
							<hr/>
							<p className="title">참가제품</p>
							<p className="half">{data.productName}</p>
							<p className="title">신청자</p>
							<p>{data.staffName}</p>
							<hr/>
							<p className="title">전화번호</p>
							<p>{data.staffPhone}</p>
							<hr/>
							<p className="title">행사장 주소</p>
							<p className="half">{data.eventLocation}</p>
							<p className="title">행사시간</p>
							<p className="half">{data.eventTime}</p>
							<hr/>
							<p className="title">행사 일자</p>
							<p className="half">
								{data?.eventStart?.slice(0, 10)} ~ {data?.eventEnd?.slice(0, 10)}
							</p>
							<p className="title">철거시간</p>
							<p className="half"></p>
							<hr/>
							<p className="title">행사명칭</p>
							<p className="full">{data.eventName}</p>
							<hr/>
							<p className="title">행사 설치 시간</p>
							<p className="half">{data.disassembly}</p>
							<p className="title">방명록 개수</p>
							<p className="half">{data?.guestBook}</p>

							<hr/>
							<p className="title">신청부스</p>
							<p className="half">    {data?.booths?.map((it, idx) => (<>{it.boothName} <br/> </>))}</p>
							<p className="title">부스 타입</p>
							<p className="half">{data?.booths?.map((it, idx) => (<>{it.acronyms} / {it.boothTypeName}
								<br/> </>))}</p>
							<hr/>
							<p className="title">지시사항</p>
							<p className="full">{data.directions}</p>
							<hr/>
							<p className="title">특이사항</p>
							<p className="full">{data.memo}</p>
							<hr/>
							<p className="title">DTT 박스</p>
							<ul>
								<li>
									<p>대</p>
									<p className="countBox">
										개
									</p>
								</li>
								<li>
									<p>중</p>
									<p className="countBox">
										개
									</p>
								</li>
								<li>
									<p>소</p>
									<p className="countBox">
										개
									</p>
								</li>
								<li>
									<em>비고</em>
									<span></span>
								</li>
							</ul>
						</div>

						<div className="table">
							<p className="title">순번</p>
							<p className="title">제품명</p>
							<p className="title">분류</p>
							<p className="title">행사물품</p>
							<p className="title">이미지</p>
							<p className="title">주문수량</p>
							<p className="title">단위</p>
							<p className="title">적재위치</p>
							<p className="title">비고</p>
							{data?.goods?.map((it, idx) => (
								<>
									<p>{data?.goods?.length - idx}</p>
									<p>{it.goodsId?.productName}</p>
									<p>{it.goodsId?.category}</p>
									<p>{it?.goodsId?.goodsName}</p>
									<p><img
										src={it?.goodsId?.goodsImage[0]}
										alt=''
									/></p>
									<p>{it?.amount} </p>
									<p>{it?.goodsId?.unit}</p>
									<p>{it?.goodsId?.loadingLocation}</p>
									<p></p>
								</>

							))}
						</div>
					</div>

				</div>

			</div>
			{(data?.InstallationPhoto || data?.InstallationPhoto2)? <div key={2}  className="print_wapper">
				<div className="paper">
				  {data?.InstallationPhoto ? <div className="content">
						<div className="header">
							<h1>행사설치사진1</h1>
						</div>
						<img src={data?.InstallationPhoto} alt='부스배치도' style={{margin: "auto"}}/>
					</div> : null}
					{data?.InstallationPhoto2 ? <div className="content" style={{marginTop: "10px"}}>
						<div className="header">
							<h1>행사설치사진2</h1>
						</div>
						<img src={data?.InstallationPhoto2} alt='부스배치도' style={{margin: "auto"}}/>
					</div> : null}
				</div>
			</div> : null}
		</div>
	);
});

export default PrintEvent;
