import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import HeardSearch from "../../components/HeardSearch";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { getAllBannerFunc } from "../../api";
import { toast } from "react-toastify";
import Pagenation from "../../components/Pagenation";

const Banner = () => {
  const navigate = useNavigate();
 
  const [banner, setBanner] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totlaCount, setTotalCount] = useState({
    total: 0,
    pageItem: 0,
  });

  useEffect(() => {
    // 전체회사 리스트
const allBannerGet = async () => {
  const response = await getAllBannerFunc({page});
  if (response.status === 200) {
    setBanner(response?.data?.data);
    setPageCount(() =>
      response?.data?.Pagination.pageCount
    );
    setTotalCount((totlaCount) => ({
      ...totlaCount,
      total: response?.data?.Pagination.count,
      pageItem: response?.data?.Pagination.pageItem,
    }));
  } else {
    toast.error("부스종류를 가져오는데 실패했습니다.");
  }
};
allBannerGet();
}, [page]);



  const searchForm = {
    title: "배너관리",
    period: {
      exist: true,
      exposure:1,
      name: "노출 날짜 검색",
    },
    section: { exist: false },
    position: {
      exist: true,
      titleName: "게시위치",
      type: "radio",
      name: ["All", "상단",'하단'],
    },
    state: {
      exist: true,
      titleName: "게시상태",
      type: "radio",
      name: ["사용", "중지"],
    },
    search: { exist: true,title:'메모검색' },
  };

  return (
    <>
      <Container className="align-items-center justify-content-center p-5">
        <HeardSearch searchForm={searchForm} />
        <Table bordered hover>
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>구분</th>
              <th className='text-center'>배너이미지</th>
              <th className='text-center'>배너메모</th>
              <th className='text-center'>링크</th>
              <th className='text-center'>노출기간</th>
              <th className='text-center'>게시상태</th>
            </tr>
          </thead>
          <tbody>
            {banner.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle" ,cursor:"pointer" }}
                onClick={()=>navigate("/bannerview/"+item._id)}
              >
                <td>{totlaCount?.total -
                  (page - 1) * totlaCount?.pageItem -
                  idx}</td>
                <td>{item.position ? "상단":"하단" }</td>
                <td
                  className='flex justify-center'
                  style={{ backgroundColor: "#f1efef" }}
                >
                  <img src={item?.bannerImage} width={"100px"} alt='' />{" "}
                </td>
                <td>{item.memo}</td>
                <td>{item.link}</td>
                <td>{item.startDay} ~ {item.expireDay}</td>
                <td>{item.state? "사용":"중지"}</td>

                
              </tr>
            ))}
           
          </tbody>
        </Table>

        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          <Col className='text-end '>
         
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/bannerRegister");
              }}
            >
              배너등록
            </Button>{" "}
          </Col>
        </Row>
        <Pagenation
          pageCount={pageCount}
          page={page}
          setPage={setPage}
        />
      
      </Container>
    </>
  );
};

export default memo(Banner);
