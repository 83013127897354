import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllProductFunc } from "../../../api";

const ProductInfo = () => {
  const navigate = useNavigate();
  const { did } = useParams();
  const client = useSelector((state) => state.client);
  const { department } = client;
  const [part, setPart] = useState(0);
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState("all");
 
  const allProductGet = async (id) => {
    const response = await getAllProductFunc(id);
    if (response.status === 200) {
      setProducts(response.data);
      console.log(response.data)
    } else {
      toast.error("Client 정보를 가져오지 못했습니다.");
    }
  };
  useEffect(() => {
    allProductGet(did)
  
  }, [did]);
  // console.log(products);
  // console.log(part);

  // console.log(products)
  // console.log(client.department[part-1])
  return (
    <>
      <Container  className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-5'>
          거래처 제품정보
        </Row>

        <Row className=' my-5'>
          <Col className='text-start '>
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/partnerView/" + did);
              }}
              className='px-4 mx-2'
            >
              거래처정보
            </Button>{" "}
            <Button variant='outline-secondary'
            onClick={() => {
              navigate("/departmentInfo/" + did);
            }}
            className='px-4 mx-2'>
              배송지정보
            </Button>{" "}
            <Button
              variant='outline-secondary'
              onClick={() => {
                navigate("/departmentInfo/" + did);
              }}
              className='px-4 mx-2'
            >
              부서정보
            </Button>{" "}
            <Button 
              active
              variant='outline-secondary'
              onClick={() => {
                navigate("/productInfo/" + did);
              }}
              className='px-4 mx-2'
            >
              제품정보
            </Button>{" "}
          </Col>
        </Row>

        <Row className=' my-5 mx-1'>
          <Col className='text-satrt ' sm='12'>
            <Button
              variant='light'
              name={"all"}
              active={part === 0 ? true : false}
              className='px-4 mx-2'
              onClick={() => {
                setPart(0);
              }}
            >
              All
            </Button>
            {department?.map((it, idx) => (
              <Button
                key={idx}
                variant='light'
                name={it}
                active={part === idx + 1 ? true : false}
                className='px-4 mx-2'
                onClick={() => {
                  setPart(idx + 1);
                }}
              >
                {it}
              </Button>
            ))}
          </Col>
        </Row>

        <Row className=' m-3'>
          <Col className='text-start mx-0'>
            <Row className='fs-6 fw-bold'>{client.companyName}</Row>
            <Row>팀원정보 | 제품정보</Row>
          </Col>
        </Row>

        <Table bordered hover>
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>제품명</th>
              <th className='text-center'>제품코드</th>
              <th className='text-center'>제품이미지</th>
              <th className='text-center'>담당자명/직급</th>
            </tr>
          </thead>
          <tbody>
            {products.map((item, idx) => (
              <tr
                key={item._id}
                className='text-center'
                style={{ verticalAlign: "middle" ,cursor:"pointer" }}
                onClick={()=>navigate("/productview/"+ did+"/"+item._id)}
              >
                <td>{products.length - idx}</td>
                <td>{item.productName}</td>
                <td>{item.productCode}</td>
                <td
                  className='flex justify-center'
                  style={{ backgroundColor: "#f1efef" }}
                >
                  <img src={item?.productImage} width={"100px"} alt='' />{" "}
                </td>
                <td>
                  
                  {/* {item?.staff &&
                    item?.staff?.map((it) => (
                      <div key={it._id}>
                        {it.staffName}({it.position})
                      </div>
                    ))} */}
                </td>
              </tr>
            ))}
            {/* {products.map((it, idx) => (
              <tr
                key={it._id}
                className='text-center'
                onClick={() => {
                  navigate("");
                }}
              >
                <td>{it}</td>
                <td>DM(LC)</td>
                <td>12345</td>
                <td>이미지</td>
                <td>홍길동/대리</td>
              </tr>
            ))} */}
          </tbody>
        </Table>

        <Row className=' m-3'>
          <Col className='text-start '>
            {/* <Button variant="outline-secondary" className="px-4 mx-3">
              삭 제
            </Button>{" "}
           */}
          </Col>
          <Col className='text-end '>
            <Button
              variant='outline-secondary'
              className='px-4 mx-3'
              onClick={() => {
                navigate("/partnerstable/");
              }}
            >
              목 록
            </Button>{" "}
            <Button
              variant='primary'
              className='px-4 '
              onClick={() => {
                navigate("/productRegister/" + did);
              }}
            >
              제품추가
            </Button>{" "}
          </Col>
        </Row>
        <Pagination className='justify-content-center'>
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Ellipsis />

          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item>
          <Pagination.Item active>{12}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Item disabled>{14}</Pagination.Item>

          <Pagination.Ellipsis />
          <Pagination.Item>{20}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </Container>
    </>
  );
};

export default memo(ProductInfo);
