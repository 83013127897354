import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import { memo, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { getGoodsFunc, getlogFunc, goodsDeleteFunc, goodsUpdateFunc, goodsUpdateLogFunc } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDateToYYYYMMDDHHMM } from "../../utils";
// stylesheet
import "@easonlin0716/js-modal/dist/js-modal.min.css";
// ES module
import modal from "@easonlin0716/js-modal";

const goodUnit = {
  'ea': 'EA',
  'box': 'Box',
  'set': 'SET'
}

const ManageMentDetail = () => {

  const { id } = useParams();   // 물품id
  const [data, setData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [forms, setForms] = useState([]);
  const [update, setUpdate] = useState(0)
  const title = "물품관리 상세";
  const navigate = useNavigate();

  const goodsGet = async (id) => {
    const res = await getGoodsFunc(id);
    if (res.status === 200 || res.status === 201) {
      setData(res.data);
      // console.log(res.data)
    } else {
      toast.error("물품정보를 가져오지 못했습니다.");
    }
  };
  const logGet = async (id) => {
    const res = await getlogFunc(id);
    if (res.status === 200 || res.status === 201) {
      setLogData(res.data);
      // console.log(res.data)
    } else {
      toast.error("log정보를 가져오지 못했습니다.");
    }
  };

  useEffect(() => {
    goodsGet(id);
  }, [id]);

  useEffect(() => {
    logGet(id);
  }, [update, id]);

  const handleDelete = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      const res = await goodsDeleteFunc(id);
      if (res.status === 200) {
        setTimeout(() => {
          toast.success("Successfully Delete!");
          navigate("/disManagement", { replace: true });
        }, 1000);
      } else {
        toast.error("물품관리 삭제실패!");
      }
    }
  };

  function expirationDate(params) {
    // 유통기한 날짜를 변수에 할당 (예시로 현재 날짜로 설정)
    const expirationDate = new Date(params);
    const sixMonthsLater = new Date();
    sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

    // 유통기한이 6개월보다 작은지 여부를 확인하고 적절한 스타일을 적용하여 날짜를 표시
    if (expirationDate > sixMonthsLater) {
      return true;
    } else {
      return false;
    }
  }
  const onChange = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(e.target)
    if (forms.inOut === "출고") {
      console.log(parseInt(logData[0]?.currentAmount),parseInt(forms.amountCount))
      if (parseInt(logData[0]?.currentAmount) - parseInt(forms.amountCount) < 0) {
        toast.error("출고수량이 재고보다 작습니다.")
        return

      }
    }
    const formData = new FormData(e.target);
    // console.log(Object.fromEntries(formData.entries()))
    // const newFormData = Object.fromEntries(formData.entries());
    const config = {
      "Content-Type": "multipart/form-data",
      // "Content-Type":"application/json",
    };
    const response = await goodsUpdateLogFunc(id, formData, config);
    if (response.status === 200) {
      toast.success("Successfully Staff Register!");
      // setTimeout(() => {
      //   navigate("/partnerstable", { replace: true });
      // }, 1000);
      setUpdate((prev) => prev + 1)
      setForms({
        ...forms,
        logDate: "",
        logMemo: "",
        amountCount: "",
      });
    } else {
      toast.error("재고수정에 실패했습니다.!");
    }

  };

  const openImg = (idx) => {
    const modalE = '#img-modal-' + idx;
    const myModal = document.querySelector(modalE);
    modal.open(myModal, { allowDrag: true, showClose: true, clickClose: true })
  }

  return (
    <>
      <Container className='py-3'>
        <Row className='fs-1 fw-bold p-2 border-bottom mb-3'>{title}</Row>

        <Row className=' mx-1 mb-3 text-xl text-slate-600'>1.물품정보</Row>
        <Row>
          <Col
            lg='5'
            className=' d-flex flex-column gap-3 justify-start items-center'
          >
            {data?.goodsImage &&
              data.goodsImage.map((it_img, idx) => (
                <div
                  key={idx}
                  className='p-4 shadow-sm bg-slate-50 rounded w-full flex justify-center items-center'
                >
                  <img
                    className=' rounded-3'
                    src={it_img}
                    alt='inventoryImage'
                    width={200}
                    onClick={() => openImg(idx)}
                  />
                  <div id={`img-modal-${idx}`} className="modal" style={{height: "fit-content"}}>
                    <img
                      width={'100%'}
                      src={it_img}
                      alt='it_img'
                    ></img>
                  </div>
                </div>
              ))}
          </Col>
          <Col lg='7'>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    거래처명
                  </td>
                  <td className='px-3 py-3 text-center '>{data.clientName}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    부서명
                  </td>
                  <td className='px-3 py-3 text-center '>{data.department}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    물품명
                  </td>
                  <td className='px-3 py-3 text-center '>{data.goodsName}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    물품코드
                  </td>
                  <td className='px-3 py-3 text-center '>{data.goodsCode}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    제품명
                  </td>
                  <td className='px-3 py-3 text-center '>{data.productName}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    종 류
                  </td>
                  <td className='px-3 py-3 text-center '>{data.category}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    단위
                  </td>
                  <td className='px-3 py-3 text-center '>{data.unit ? goodUnit[data.unit] : ''}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    비고
                  </td>
                  <td className='px-3 py-3 text-center '>{data.memo}</td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    등록일자
                  </td>
                  <td className='px-3 py-3 text-center '>
                    {data?.createdAt && data?.createdAt.slice(0, 10)}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    제조일자
                  </td>
                  <td className='px-3 py-3 text-center '>
                    {data.manufactureData}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    유통기한
                  </td>
                  <td className='px-3 py-3 text-center '>
                    {" "}
                    {expirationDate(data.expirationData) ? (
                      <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-semibold text-green-700 ring-1 ring-inset ring-green-600/10'>
                        {data.expirationData}
                      </span>
                    ) : (
                      <div className='flex gap-2 w-full justify-center'>
                        <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs  text-red-700 ring-1 ring-inset ring-red-600/20 font-semibold'>
                          {data.expirationData}
                        </span>
                        <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs  text-red-700 ring-1 ring-inset ring-red-600/20 font-semibold'>
                          유통기한 임박
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    적재위치
                  </td>
                  <td className='px-3 py-3 text-center '>
                    {data.loadingLocation}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "100px" }}
                    className='px-3 py-3 text-center border-0 font-bold'
                  >
                    담당자
                  </td>
                  <td className='px-3 py-3 text-center '>{data.staffName}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <hr />

        <Row className=' mx-1 mb-3 text-xl text-slate-600'>1.물품수량 정보</Row>
        <Form onSubmit={handleSubmit}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className='text-center'>날짜</th>
                <th className='text-center'>내용</th>
                <th className='text-center'>구분</th>
                <th className='text-center'>수량</th>
                <th className='text-center'>저장</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center align-items-center'>
                <td style={{ width: 40 }}>
                  {" "}
                  <Form.Control type='datetime-local' name='logDate' size='sm' required />
                </td>
                <td style={{ width: 550 }}>
                  {" "}
                  <Form.Control type='text' name='logMemo' value={forms?.logMemo || ""} onChange={onChange} size='sm' required />
                </td>
                <td >
                  {" "}
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} onChange={onChange}>
                      <Form.Check
                        inline
                        label='입고'
                        name='inOut'
                        defaultChecked
                        defaultValue='입고'
                        type={type}
                        id={`inline-${type}-1`}
                      />
                      <Form.Check
                        inline
                        label='출고'
                        defaultValue='출고'
                        name='inOut'
                        type={type}
                        id={`inline-${type}-2`}
                      />{" "}
                    </div>
                  ))}
                </td>
                <td style={{ width: 100 }}>
                  {" "}
                  <Form.Control type='number' size='sm' name="amountCount" value={forms?.amountCount || ""} onChange={onChange} required />
                </td>
                <td>
                  {" "}
                  <Button
                    variant='primary'
                    type='submit'
                    className='px-4 mx-3'
                    size='sm'
                  >
                    저장
                  </Button>{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>
        <hr />
        <Row className=' mx-1 mb-3 text-[16px] text-red-600'>
          + 해당주문 데이터는 단순 로그데이터로 최종으로 확인후 정확하게
          입력해주세요.
        </Row>
        {/* inventory */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className='text-center'>번호</th>
              <th className='text-center'>날짜</th>
              <th className='text-center flex-1'>내용</th>
              <th className='text-center'>구분</th>
              <th className='text-center'>수량</th>
              <th className='text-center'>재고</th>
            </tr>
          </thead>
          <tbody>
            {logData.length !== 0 && logData?.map((it, idx) => (
              <tr className='text-center' key={idx}>
                <td>{logData.length - idx}</td>
                <td>{it.editDate ? formatDateToYYYYMMDDHHMM(it.editDate) : ""}</td>
                <td className='flex-1'>{it.memo}</td>
                <td>{
                  it.inOut === "입고" ? (<span className='inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10'>
                    {it.inOut}
                  </span>) : (<span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10'>
                    {it.inOut}
                  </span>)
                }
                </td>
                <td>{it.inOut === "입고" ? it.amountCount : "-" + it.amountCount}</td>
                <td>{it.currentAmount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <hr />

        <Row className='mt-2 mb-5'>
          <Col className='text-start '>
            {/* <Button variant="primary" className="px-4 mx-3">
                엑셀다운로드
              </Button>{" "} */}
            <Button
              variant='outline-danger'
              className='px-4 mx-3'
              onClick={handleDelete}
            >
              삭제
            </Button>{" "}
          </Col>
          <Col className='text-end '>
            <Button
              variant='outline-secondary'
              className='px-4 mx-3'
              onClick={() => navigate("/disManagement", { replace: true, state: {backPage: true} })}
            >
              목 록
            </Button>{" "}
            <Button
              variant='primary'
              className='px-4 mx-3'
              onClick={() => navigate("/management-edit/" + id, { replace: true })}
            >
              수정
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(ManageMentDetail);
