import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import { URL } from "../api";
const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: JSON.parse(window?.localStorage.getItem("client")) ?? {},
    allClients: JSON.parse(window?.localStorage.getItem("allClients")) ?? {},
    staffs: JSON.parse(window?.localStorage.getItem("staffs")) ?? [],
    products: JSON.parse(window?.localStorage.getItem("products")) ??[],
  },
  reducers: {
    getClient: (state, action) => {
      // console.log(action.payload);
      state.client = action.payload;
      localStorage.setItem("client", JSON.stringify(action.payload));
    },
    allClients: (state, action) => {
      // console.log(action.payload);
      state.allClients = action.payload;
      localStorage.setItem("allClients", JSON.stringify(action.payload));
    },
    getStaffs: (state, action) => {
      // console.log(action.payload);
      state.staffs = action.payload;
      localStorage.setItem("staffs", JSON.stringify(action.payload));
    },
    getProducts: (state, action) => {
      // console.log(action.payload);
      state.products = action.payload;
      localStorage.setItem("products", JSON.stringify(action.payload));

    },
  },
});

export const { getClient, getStaffs, getProducts,allClients } = clientSlice.actions;

export default clientSlice.reducer;
